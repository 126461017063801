.share-social{
    display: none;
    @media screen and (max-width: 767px) {
        display: block;
    }
    &__wrapper{
        padding: 40px 0;
        margin: 40px 0 0;
        border-top: 1px solid #E5E7EB;
    }

    &__title{
        color: $black101;
        margin: 0 0 16px !important;
        font-weight: 700;
    }

    &__btn{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: #475467;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;

        svg{
            width: 20px;
            height: 18px;
        }

        img{
            margin: 0 !important;
        }
    }

    &__list{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    &__explore{
        border: 1px solid #E5E7EB;
        border-radius: 12px;
        padding: 16px;

        &-title{
            font-size: 16px;
            font-weight: 700;
            margin-top: 16px;
            text-align: center;
        }

        &-btn{
            background-color: $green00A;
            padding: 10px 12px;
            margin: 0 auto;
            border-radius: 8px;
            border: none;
            outline: none;
            font-size: 14px;
            color: #fff !important;
        }

        &-bottom{
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}