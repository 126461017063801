.gallery {
  height: 448px;
  &.listing-gallery {
    height: 104px;
  }
  &.grid-items {
    display: grid;
    grid-template-columns: repeat(3, minmax(calc(33.33% - 8px), 1fr));
    grid-template-areas:
      'item1 item1 item2'
      'item1 item1 item3';
    gap: 8px;
  }
  &.flex-items {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .image {
      border-radius: 16px;
    }
    &.listing-gallery {
      .image {
        width: 104px;
        height: 104px;
        border-radius: 12px !important;
      }
      gap: 8px;
    }
  }
  .image {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    &.listing {
      width: 104px;
      height: 104px;
      border-radius: 12px !important;
      span {
        width: 100% !important;
        height: 100% !important;
      }
    }
    img {
      width: 100%;
      height: 100%;
    }
    &:first-child {
      border-radius: 16px;
    }
    &.image-1 {
      grid-area: item1;
    }
    &.image-2 {
      grid-area: item2;
    }
    &.image-3 {
      grid-area: item3;
    }
  }
}
.mobile-gallery {
  height: 250px;
  position: relative;
  .mobile-carousel {
    width: 100%;
    height: 100%;
    .rec-pagination {
      display: none;
    }
    .rec-slider-container {
      margin: 0;
    }
    .rec-swipable {
      align-items: stretch;
    }
    .rec-carousel {
      position: relative;
      .arrow-btn {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;

        border-radius: 50%;
        border: 0;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

        background-color: $whiteFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        svg {
          width: 16px;
          height: 16px;
          color: $black000;
        }
        &.hidden {
          display: none;
        }
        &.show {
          display: flex;
        }
        &.arrow-right-btn {
          right: -26px;
        }
      }
    }
    .image {
      width: 100%;
      height: 250px;
      position: relative;
      img {
        border-radius: 16px;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.view-gallery {
  position: absolute;
  bottom: 16px;
  right: 16px;

  z-index: 9;

  display: flex;
  align-items: center;
  column-gap: 6px;
  background: rgba(0, 0, 0, 0.38);

  padding: 4px 12px;
  border-radius: 8px;
  overflow: hidden;
  color: $whiteFFF;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 18px;
      height: 18px;
    }
  }
  .title {
    font-size: convert-rem(14);
    line-height: 20px;
    &.listing {
      font-size: 25px;
    }
  }
  &.listing {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    
  }
}


.gallery .video-player-wrapper,
.mobile-gallery .video-player-wrapper {
  position: relative;
  height: 100%;

  .video-player {
    position: absolute;
    top: 0;
    left: 0;

    border-radius: 16px;
    overflow: hidden;

    video {
      object-fit: fill;
    }
  }
}
