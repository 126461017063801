.newsletter {
    background-color: $green7AD;
    padding: 60px 0;

    @media screen and (max-width: 768px) {
        padding: 40px 0;
    }
    
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__group {
        &:first-child {
            flex: 0 0 60%;

            @media screen and (max-width: 768px) {
                flex: 0 0 100%;
            }
        }
        &:last-child {
            flex: 0 0 35%;

            @media screen and (max-width: 768px) {
                flex: 0 0 100%;
            }
        }
        &-title {
            color: #fff;
            font-size: 36px;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 0;

            @media screen and (max-width: 1024px) {
                font-size: 30px;
            }

            @media screen and (max-width: 768px) {
                font-size: 24px;
                margin-bottom: 40px;
            }
        }
    }

    &__form {
        display: flex;
        background-color: #fff;
        height: 44px;
        border-radius: 12px;
        padding: 4px 4px 4px 16px;
        border: 1px solid #e5e7eb;
        margin-bottom: 18px;
        border: 1px solid transparent;

        &.error{
            border-color: red;
        }

        &-group{
            flex: 1;  
        }

        &-err-message{
            color: red;
            font-size: 13px;
            margin: 8px 0 0 -16px;
            line-height: 16px;
        }

        &-input {
            flex: 1;
            font-size: 14px;
            border: none;
            outline: none;
            height: 100%;
            width: 100%;

            &::placeholder {
                font-size: 14px;
                color: #9ca3af;
                letter-spacing: 0.028px;
            }
        }
        &-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 16px;
            outline: none;
            border-radius: 8px;
            border: 1px solid $green7AD;
            background: $green7AD;
            font-size: 14px;
            color: #fff;
            transition: all ease 0.25s;

            &:hover {
                opacity: 0.85;
            }
        }
    }

    &__description {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.028px;

        a {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}
