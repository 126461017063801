.Toastify {
  &__toast {
    min-height: 56px;
    &-icon {
      width: 18px;
    }
    div:not(.Toastify__toast-icon) {
      font-size: convert-rem(14);
      color: $black333;
      font-weight: 500;
    }

    &--success {
      .Toastify__toast-icon {
        fill: $cyan00CB;
      }
    }
    &--error {
      .Toastify__toast-icon {
        fill: $redFF1;
      }
      div:not(.Toastify__toast-icon) {
        color: $redFF1;
      }
    }
  }
}