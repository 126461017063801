.article {
  position: relative;
  height: 440px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  overflow: hidden;
  cursor: pointer;

  top: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;

  &:hover {
    top: -8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  }
  &-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &-thumb {
    width: 100%;
    height: 230px;

    flex-shrink: 0;
    position: relative;
    overflow: hidden;
  }
  &-detail {
    height: calc(100% - 230px);
    padding: 16px 16px 24px;
    display: flex;
    flex-direction: column;
  }
  &-category {
    width: fit-content;
    .category {
      color: $cyan0CC;
      font-weight: 700;
      font-size: convert-rem(14);
      line-height: 22px;
      margin-bottom: 8px;
    }
  }
  &-name .name {
    color: $black333;

    font-size: convert-rem(18);
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 8px;
  }
  &-author {
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin-top: auto;
    &__avatar {
      width: 24px;
      height: 24px;
      position: relative;
      img {
        border-radius: 50%;
      }
    }
    &__name {
      color: $black333;
      font-size: convert-rem(14);
      font-weight: normal;
      line-height: 20px;

      margin-bottom: 0;
      &:hover {
        color: $cyan0CC;
      }
    }
  }
  &-favourite {
    position: absolute;
    top: 16px;
    right: 16px;

    .favourite-btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 0;
      background-color: $whiteFFF;

      i.fa-heart-o {
        margin-top: 4px;
        font-size: 20px;
        color: $pinkFF0;
      }
      &:hover i.fa-heart-o:before {
        content: "\f004";
      }
    }
  }

  @media screen and (max-width: 991px) {
    height: 368px;
    &-thumb {
      height: 180px;
    }
    &-detail {
      height: calc(100% - 180px);
      padding: 12px 12px 16px;
    }
    &-category .category {
      font-size: convert-rem(12);
      line-height: 18px;
    }
    &-name .name {
      font-size: convert-rem(16);
      line-height: 24px;
    }
    &-author {
      &__name {
        font-size: convert-rem(12);
        line-height: 18px;
      }
    }
    &-favourite {
      position: absolute;
      top: 12px;
      right: 12px;

      .favourite-btn {
        width: 36px;
        height: 36px;
        i.fa-heart-o {
          font-size: 18px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    height: 336px;
    &-thumb {
      height: 160px;
    }
    &-detail {
      height: calc(100% - 160px);
    }
    &-name {
      font-size: convert-rem(14);
      line-height: 20px;
    }
    &-favourite {
      .favourite-btn {
        width: 32px;
        height: 32px;
        i.fa-heart-o {
          font-size: 16px;
        }
      }
    }
  }
}

.article-big {
  position: relative;
  width: 100%;
  height: 440px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0, rgba(0, 0, 0, 0.76) 1%, transparent 66%, transparent);
  border-radius: 8px;

  overflow: hidden;
  cursor: pointer;

  top: 0;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;

  &:hover {
    top: -8px;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.76) 0, rgba(0, 0, 0, 0.76) 1%, transparent 66%, transparent);
    z-index: 1;
  }
  &-thumb {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-detail {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    padding: 24px;
    z-index: 2;
  }
  &-category {
    width: fit-content;
    .category {
      padding: 8px 16px;
      color: $whiteFFF;
      background: rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      font-size: convert-rem(18);
      line-height: 26px;
      transition: all 0.2s linear;
      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }
    }
  }
  &-name .article-name {
    color: $whiteFFF;
    font-size: convert-rem(24);
    line-height: 32px;
    margin-bottom: 0;
    padding-right: 32px;
  }
  &-favourite {
    position: absolute;
    z-index: 3;
    top: 24px;
    right: 24px;

    .favourite-btn {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      border: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $whiteFFF;

      i.fa-heart-o {
        margin-top: 2px;
        font-size: 20px;
        color: $pinkFF0;
      }
      &:hover i.fa-heart-o:before {
        content: "\f004";
      }
    }
  }
  a:hover {
    color: unset;
    text-decoration: none;
  }
}

.article-destination .article-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  cursor: pointer;
  &:hover .article-thumb img {
    transform: scale(1.25);
  }
  .article-thumb {
    width: 100%;
    height: 171px;

    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    img {
      transition: all 0.6s ease;
    }
  }
  .article-detail {
    height: calc(100% - 171px - 12px);
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 0;

    .article-category {
      display: flex;
      align-items: center;
      column-gap: 6px;
      margin-bottom: auto;
      a {
        &:hover {
          text-decoration: none;
        }
        span {
          color: $green00A;
          font-size: convert-rem(14);
          font-weight: 700;
          line-height: 20px;
        }
      }
      .dot {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $gray98A;
      }
    }
    .article-name {
      color: $black101;
      font-size: convert-rem(16);
      line-height: 24px;
      font-weight: 700;

      margin-bottom: 0;
    }
  }
  .article-date-posted {
    color: $black667;
    font-size: convert-rem(14);
    font-weight: 400;
    line-height: 20px;
  }

  @media screen and (max-width: 992px) {
    .article-thumb {
      height: 156px;
    }
    .article-detail {
      height: calc(100% - 156px - 12px);
      .article-category a span {
        font-size: convert-rem(12);
        line-height: 18px;
      }
      .article-name {
        font-size: convert-rem(14);
        line-height: 20px;
      }
      .article-date-posted {
        font-size: convert-rem(12);
        line-height: 18px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .article-thumb {
      height: 128px;
    }
    .article-detail {
      height: calc(100% - 128px - 12px);
      .article-category a span {
        font-size: convert-rem(12);
        line-height: 18px;
      }
      .article-name {
        font-size: convert-rem(14);
        line-height: 20px;
      }
      .article-date-posted {
        font-size: convert-rem(12);
        line-height: 18px;
      }
    }
  }
}
