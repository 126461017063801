// Checkbox
.custom-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  display: block;
  position: relative;
  cursor: pointer;
  font-size: convert-rem(22);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .checkmark {
    background-color: $greenDCF;
  }
  &:hover input:checked ~ .checkmark:after {
    background-color: unset;
  }

  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    z-index: 9;

    cursor: pointer;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: 0;
  }
  input:checked ~ .checkmark {
    background-color: $green00A;
    border-color: $green00A;
  }
  input:checked ~ .checkmark:after {
    display: block;
    border-color: $whiteFFF;
    opacity: 1;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $whiteFFF;
    border-color: $grayD0D;
    &:after {
      content: '';
      display: none;

      position: absolute;
      left: 6px;
      top: 3px;
      width: 5px;
      height: 10px;

      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

// Input
.custom-input {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 8px 14px;

  border: 1px solid $grayD0D;
  border-radius: 8px;
  background-color: $whiteFFF;
  overflow: hidden;

  &:not(.disabled):hover,
  &:not(.disabled):focus-visible {
    border-color: $green00A;
  }
  &.custom-textarea {
    height: 230px;
  }
  &.disabled {
    background-color: $grayF9F;
    input {
      background-color: transparent;
    }
  }
  &.has-error {
    border-color: $redF04;
  }

  .label {
    font-size: convert-rem(14);
    line-height: 20px;
    font-weight: 600;
    color: $black344;

    margin-right: 8px;
  }
  .icon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    position: relative;
  }
  input {
    flex-grow: 1;
    width: 100%;

    padding: 0;
    margin: 0;

    border: 0;
    outline: none;

    color: $black101;
    font-size: convert-rem(16);
    line-height: 24px;

    &::placeholder {
      color: $black667;
    }
    &:focus-visible {
      outline: none;
    }
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  textarea {
    width: 100%;
    height: 100%;
    border: 0;

    resize: none;
    line-height: 24px;
    color: $black101;

    &::placeholder {
      color: $black667;
    }
    &:focus-visible {
      outline: none;
    }
  }

  @media screen and (max-width: 767px) {
    input::placeholder {
      font-size: convert-rem(14);
    }
  }
}

// Range picker
.custom-range-picker {
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDateDisplay {
    display: none;
  }
  .rdrMonthAndYearWrapper {
    padding: 0;
  }
  .rdrDay {
    color: $green009 !important;
    .rdrInRange,
    .rdrStartEdge,
    .rdrDayNumber,
    .rdrEndEdge,
    .rdrDayInPreview,
    .rdrDayStartPreview,
    .rdrDayEndPreview {
      color: $green009 !important;
      border-color: $green009;
    }
    &.rdrDayToday .rdrDayNumber span:after {
      background: $green009;
    }
  }
}

// Datepicker
.custom-datepicker {
  .react-datepicker-popper {
    padding-bottom: 90px;
  }
  width: 100%;
  .datepicker {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 8px;

    border-radius: 8px;
    border: 1px solid $grayD0D;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px 14px;

    cursor: pointer;
    position: relative;
    &:focus-within {
      border-color: $green00A;
    }
    &-icon {
      position: relative;
      width: 20px;
      height: 20px;
      flex-shrink: 0;
    }
    &-value {
      position: absolute;
      left: 14px;
    }
    &-placeholder {
      color: $black667;
      font-weight: 400;
    }
    &-invalid {
      border-color: $redF04;
    }
    .react-datepicker {
      border: 1px solid $grayD0D;
      font-family: 'Nunito Sans', sans-serif;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      &__wrapper {
        flex-grow: 1;
      }
      &-popper {
        transform: translate3d(0px, 44px, 0px) !important;
      }
      &__input-container input {
        width: 100%;
        border: 0;
        outline: none;
        color: transparent;
        background-color: transparent;
        &:focus {
          box-shadow: none;
        }
      }
      &__header {
        background-color: $grayF9F;
        border-color: $grayD0D;
        border-bottom: 1px solid $grayD0D;
      }
      &__current-month {
        font-size: convert-rem(14);
        line-height: 24px;
      }
      &__navigation {
        top: 8px;
      }
      &__navigation-icon:before {
        border-color: $black667;
        border-width: 2px 2px 0 0;
      }
      &__day {
        width: 28.5px;
        height: 28.5px;
        &--today,
        &--keyboard-selected {
          background-color: $greenDCF;
          color: $green009;
        }
        &--selected {
          background-color: $green009;
          color: $whiteFFF;
        }
        &:not(&--today, &--keyboard-selected, &--selected):hover {
          background-color: $greenDCF;
        }
      }
    }
  }
}

// Select
.custom-selection {
  &__control {
    border-radius: 8px !important;
    border: 1px solid $grayD0D !important;
    background-color: $whiteFFF !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;

    position: relative;
    cursor: pointer !important;
    outline: none;
    &--is-focused {
      border-color: $green00A !important;
    }
  }
  &__indicator {
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    svg {
      color: $black000;
      width: 20px;
      height: 20px;
    }
  }
  &__indicator-separator {
    display: none;
  }
  &__value-container {
    padding: 10px 42px 10px 14px !important;
    font-size: convert-rem(14);
    font-weight: 700;
    line-height: 20px;
    &--is-multi {
      gap: 6px;
    }
  }
  &__menu {
    width: max-content !important;
    border-radius: 8px !important;
    overflow: hidden;
    z-index: 99 !important;
  }
  &__menu-list {
    padding: 0;
  }
  &__option {
    cursor: pointer !important;
    font-size: convert-rem(14) !important;
    line-height: 20px;
    background-color: transparent;
    &--is-selected {
      background-color: $green00A !important;
    }
    &:hover:not(&--is-selected),
    &--is-focused:not(&--is-selected) {
      background-color: $greenDCF !important;
    }
  }
  &__placeholder {
    font-weight: normal;
  }
  &__multi-value {
    background-color: $greenDCF !important;
    margin: 0 !important;
    border-radius: 16px !important;
    padding: 4px 12px !important;
    &__label {
      color: $green009 !important;
      font-weight: 600;
      padding: 0 !important;
      font-size: convert-rem(14) !important;
      line-height: 20px;
    }
    &__remove {
      width: 20px;
      height: 20px;
      padding-right: 0 !important;
      flex-shrink: 0;
      &:hover {
        background-color: transparent !important;
      }
      svg {
        color: $green009;
        font-size: 20px;
      }
    }
  }
  &__invalid {
    .custom-selection__control {
      border-color: $redF04 !important;
    }
  }
  &.menu-full-width {
    .custom-selection__menu {
      width: 100% !important;
    }
  }
}
.menu-select {
  width: 300px;
  height: 50px;
}

.action-url {
  padding-top: 20px;
}

// Select
.select {
  &__wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
    color: $black344;
    gap: 8px;
    padding: 8px 14px;
    border-radius: 8px;
    border: 1px solid $grayEAE;
    position: relative;
    min-width: 36px;
    min-height: 36px;

    h3 {
      font-size: convert-rem(14);
      line-height: 20px;
      font-weight: 500;
      margin: 0;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);

      &.active {
        transform: rotate(0);
      }
    }

    &__dropdown {
      position: absolute;
      top: 120%;
      right: 0;
      min-width: 200px;
      background-color: $whiteFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 8px;
      border: 1px solid $grayEAE;
      z-index: 9999;

      &__item {
        padding: 12px;
        color: $black101;

        @media screen and (max-width: 767px) {
          font-size: convert-rem(14);
          line-height: 20px;
        }
      }
    }
  }
}

// Only mobile
.only-mobile {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: flex !important;
  }
}

// Only desktop
.only-desktop {
  display: flex !important;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
