.dashboard-page-edit {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;

  .dashboard-wrap {
    display: flex;
    min-height: 100vh;
    overflow: auto;
    background-color: $grayF9F;
  }
  &__sidebar {
    max-width: 320px;
    width: 100%;
    flex-shrink: 0;
    position: relative;
    &-collapsed {
      max-width: 82px;
    }
    .sidebar-head {
      display: flex;
      // flex-direction: column;
      justify-content: center;
      // row-gap: 24px;
      &__logo {
        position: relative;
        width: 92px;
        height: 92px;
      }
    }

    .ps-menu-root {
      height: calc(100vh - 92px - 65px);
      margin-right: -12px;
      overflow: auto;
      ul {
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 12px;
      }
    }

    .sidebar-bottom {
      margin-top: auto;
      position: relative;
      bottom: 0;

      padding-top: 24px;
      border-top: 1px solid $grayF2F;
      &__content {
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;
      }
      &__user-avatar {
        width: 40px;
        height: 40px;

        position: relative;
        border-radius: 50%;
        overflow: hidden;
      }
      &__user-info {
        font-size: convert-rem(14);
        line-height: 20px;
        .user-name {
          color: $black000;
          font-weight: 700;
        }
      }
      &__popover {
        width: 100%;

        position: absolute;
        top: -48px;
        left: 0;

        .popover-content {
          padding: 8px 0;
          background-color: $whiteFFF;
          box-shadow: 2px 1px 2px 1px rgba(16, 24, 40, 0.05);
          border-radius: 6px;
          position: relative;
          &:before {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $grayF2F;

            position: absolute;
            bottom: -5px;
            left: 16px;

            transform: rotateZ(45deg);
            z-index: -1;
          }
          .item {
            padding: 8px 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 8px;
            &-icon {
              flex-shrink: 0;
              svg {
                width: 18px;
                height: 18px;
              }
            }
            &:hover {
              background-color: $grayF2F;
            }
          }

          @media screen and (max-width: 1023px) {
            .item {
              &-name {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    padding: 40px 32px 24px;
    overflow: hidden;

    .dashboard-content {
      .head-content {
        margin-bottom: 32px;
        &__title {
          color: $black101;
          font-size: convert-rem(30);
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 6px;
        }
        &__sub-title {
          color: $black667;
          line-height: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-content {
    max-width: 500px;
  }
}
@media screen and (max-width: 1080px) {
  .dashboard-content {
    max-width: 700px;
  }
}

.listing-info-edit {
}
