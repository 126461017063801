.auth-page {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: convert-rem(16);
  line-height: 24px;
  .auth-skip-now {
    padding-top: 32px;
    color: #009294;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  .overlay {
    position: fixed; /* Thay đổi position thành absolute */
    top: 0;
    display: none;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(8px);
    &__loading-box {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      width: 280px;
      padding: 12px 16px;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    &__rotating-image {
      width: 50px; /* Điều chỉnh kích thước của hình ảnh */
      height: 50px;
      animation: spin 2s linear infinite; /* Thêm animation cho xoay tròn */
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  &__wrapper {
    min-height: 100vh;
    display: flex;
  }
  &__sidebar {
    width: 100%;
    max-width: 460px;
    flex-shrink: 0;
    background-color: $green00A;
    color: $whiteFFF;

    padding: 60px 60px 100px;
    display: flex;
    flex-direction: column;

    position: sticky;
    top: 0;
    left: 0;
    .welcome {
      margin-top: auto;
      &__img {
        width: 100%;
        height: 460px;
        position: relative;
        margin-bottom: 60px;
      }
      &__texts {
        text-align: center;
        .title {
          text-align: center;
          font-size: convert-rem(24);
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 12px;
        }
        .description {
          color: $greenDCF;
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    padding: 40px 24px 48px;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;

    .auth-logo {
      position: absolute;
      top: 40px;
      left: 24px;
      .logo {
        position: relative;
        width: 100px;
        height: 28px;
      }
    }
    .content-wrapper {
      width: 100%;
      height: 100%;
      margin: 0 auto;

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.content-create-account,
      &.content-login {
        max-width: 575px;
      }
      &.content-onboarding {
        max-width: 702px;
        justify-content: center;
        .auth-content {
          &__middle {
            padding: 40px;
            border: 1px solid $grayD0D;
            border-radius: 16px;
          }
        }
        @media screen and (max-width: 767px) {
          padding: 0;
          .auth-content {
            padding: 0;
            width: 100%;
            &__middle {
              padding: 20px;
            }
          }
        }
      }
      .auth-steps {
        width: 100%;
        margin-bottom: 40px;
        .steps {
          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;
          .item {
            display: flex;
            align-items: center;
            column-gap: 8px;
            color: $gray98A;
            &.line {
              flex-grow: 1;
              max-width: 86px;
              min-width: 20px;
              height: 1px;
              background-color: $grayD0D;
            }
            &.active {
              .step-number {
                background-color: $green00A;
                color: $whiteFFF;
                border-color: $green00A;
              }
              .step-name {
                color: $black101;
              }
            }
            &.passed {
              .step-number {
                background-color: $greenDCF;
                border-color: $green00A;
                svg {
                  width: 16px;
                  height: 16px;
                  color: $green00A;
                }
              }
              .step-name {
                color: $green00A;
              }
            }
            .step-number {
              width: 22px;
              height: 22px;
              flex-shrink: 0;

              border-radius: 50%;
              border: 1px solid $grayD0D;

              font-size: convert-rem(14);
              font-weight: 600;
              line-height: 20px;

              display: flex;
              align-items: center;
              justify-content: center;
            }

            .step-name {
              font-size: convert-rem(14);
              line-height: 20px;
            }
          }
        }
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      .auth-content {
        width: 100%;

        padding: 0 32px;
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > div {
          width: 100%;
        }
        .title {
          color: $black101;
          font-size: convert-rem(30);
          font-weight: 800;
          line-height: 38px;
          margin-bottom: 8px;
        }
        .sub-title {
          color: $black667;
          span {
            font-weight: 700;
            display: inline-block;
            &.green-color {
              color: $green00A;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &__top {
          margin-bottom: 32px;
          text-align: center;
          &.otp-verifying {
            margin-bottom: 60px;
          }
          .back-btn {
            @include primary-white-button();
            border: 0;
            font-size: convert-rem(18);
            line-height: 28px;
            box-shadow: none;
            padding: 0;
            &.has-icon .icon {
              width: 24px;
              height: 24px;
            }
          }
        }
        &__middle {
          display: flex;
          flex-direction: column;
          row-gap: 24px;
          .step {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 16px;

            padding-bottom: 24px;
            border-bottom: 1px solid $grayD0D;

            &-title {
              color: $black101;
              font-weight: 800;
            }
            &-no {
              font-size: convert-rem(12);
              font-weight: 600;
              line-height: 18px;
              color: $black667;
            }
          }
          .auth-method-selection {
            display: flex;
            align-items: center;
            .auth-method {
              flex: 1 0 34%;
              text-align: center;
              padding: 8px 10px;

              color: $black667;
              border-bottom: 1px solid $grayD0D;
              &:not(.active) {
                cursor: pointer;
              }
              &.active {
                color: $black344;
                border-bottom: 2px solid $green00A;
                margin-bottom: 1px;
              }
            }
          }
          .auth-form {
            .form-group {
              display: flex;
              align-items: center;
              gap: 16px 24px;
              margin-bottom: 0;
              .form-item {
                width: calc(50% - 12px);
                flex-shrink: 0;
                margin-bottom: 18px !important;
              }
            }
            .form-item {
              &:not(:last-child) {
                margin-bottom: 18px;
              }
              &__label {
                color: $black344;
                font-size: convert-rem(14);
                font-weight: 700;
                line-height: 20px;
                margin-bottom: 6px;
                &__required::after {
                  content: "*";
                  color: $redF04;
                  font-size: convert-rem(12);
                  line-height: 18px;
                  margin-left: 2px;
                }
              }
              &__field {
                position: relative;
                .group {
                  display: flex;
                  align-items: stretch;
                  &:focus-within {
                    border-color: $green00A;
                  }
                  .selection {
                    width: 110px;
                    flex-shrink: 0;
                    .custom-selection {
                      &__control {
                        border-radius: 8px 0 0 8px !important;
                        box-shadow: none !important;
                        background-color: $grayF9F !important;
                      }
                      &__value-container {
                        padding-right: 32px !important;
                      }
                      &__single-value {
                        margin: 0;
                        .area-code-option {
                          color: $black667;
                          .option-flag {
                            width: 22px;
                            height: 22px;
                            flex-shrink: 0;
                          }
                          .option-label {
                            display: none;
                          }
                        }
                      }
                      &__menu {
                        width: calc(575px - 32px * 2);
                      }
                      &__option {
                        &:not(&--is-selected) {
                          color: $black344;
                        }
                        .area-code-option {
                          .option-flag {
                            width: 24px;
                            height: 24px;
                          }
                        }
                      }
                      &__indicator svg {
                        color: $black667;
                      }
                    }
                    .area-code-option {
                      display: flex;
                      align-items: center;
                      column-gap: 4px;
                      font-weight: normal;

                      .option-flag {
                        position: relative;
                        flex-shrink: 0;
                      }
                    }
                  }
                  .inputting {
                    flex-grow: 1;
                    .custom-input {
                      border-left-color: $whiteFFF;
                      border-radius: 0px 8px 8px 0;
                      padding: 9px 14px;
                      &:not(.disabled):hover,
                      &:not(.disabled):focus {
                        border-left-color: $green00A;
                      }
                      &.has-error {
                        border-left-color: $redF04 !important;
                      }
                    }
                  }
                  .sub-label {
                    margin-left: 8px;
                    color: $black667;
                  }
                }
                .show-password-btn {
                  position: absolute;
                  top: 50%;
                  right: 14px;

                  transform: translate(0, -50%);
                  cursor: pointer;
                  svg {
                    width: 18px;
                    height: 18px;
                    color: $black667;
                  }
                }
              }
              &__err-message {
                color: $redF04;
                margin-top: 4px;
                font-size: convert-rem(14);
                line-height: 20px;
              }
              &__pass-validity {
                margin-top: 8px;
                .validations {
                  display: flex;
                  align-items: center;
                  column-gap: 8px;
                  .item {
                    width: 33.33%;
                    color: $black344;
                    font-size: 12px;
                    line-height: 18px;
                    position: relative;
                    .icon {
                      display: none;
                      svg {
                        width: 16px;
                        height: 16px;

                        color: $black667;
                      }
                    }
                    &:before {
                      content: "";
                      display: inline-block;
                      width: 100%;
                      height: 4px;

                      border-radius: 100px;
                      background-color: $grayD0D;
                    }
                    &__valid:before {
                      background-color: $green12B;
                    }
                  }
                  @media screen and (max-width: 991px) {
                    flex-direction: column;
                    align-items: flex-start;
                    row-gap: 4px;
                    .item {
                      display: flex;
                      align-items: center;
                      column-gap: 4px;
                      &:before {
                        content: none;
                      }
                      .icon {
                        display: block;
                      }
                      &__valid .icon svg {
                        color: $green12B;
                      }
                    }
                  }
                }
              }
              .signup-btn,
              .login-btn,
              .submit-btn {
                @include primary-green-button();
                width: 100%;
              }
            }
          }
          .auth-or {
            text-align: center;
            color: $gray98A;
            position: relative;
            &:before,
            &:after {
              content: "";
              width: calc(50% - 72px);
              height: 0.5px;

              background-color: $grayD0D;
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
            }
            &:before {
              left: 0;
              margin-right: 16px;
            }
            &:after {
              right: 0;
              margin-left: 16px;
            }
          }
          .auth-social {
            display: flex;
            align-items: center;
            column-gap: 12px;

            & > span {
              display: flex;
              flex: 1;
            }
            .auth-btn {
              @include primary-white-button();
              flex: 1;
              padding: 10px;
              &.has-icon .icon {
                width: 24px;
                height: 24px;
              }
            }
          }
          .auth-policy {
            text-align: center;
            color: $black667;
            margin-top: 16px;
            a {
              display: inline-block;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          .auth-verify {
            .label {
              color: $black344;
              font-weight: 700;
              margin-bottom: 16px;
            }
            .field {
              &.invalid input {
                border-color: $redF04;
              }
              > div {
                width: 100%;
                column-gap: 12px;
              }
              input {
                width: 60px !important;
                height: 68px;
                border-radius: 8px;
                border: 1px solid $grayD0D;

                color: $black344;
                text-align: center;
                font-size: convert-rem(30);
                font-weight: 600;
                line-height: 38px;

                padding: 16px 20px;
                outline: none;
                &:focus {
                  border-color: $green00A;
                  box-shadow: none;
                }
                &::placeholder {
                  color: $grayD0D;
                  font-weight: normal;
                }
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                  -moz-appearance: textfield;
                  appearance: textfield;
                }
              }
            }
            .err-message {
              color: $redF04;
              margin-top: 8px;
            }
            .resend-otp {
              margin-top: 16px;
              color: $black667;
              span {
                font-weight: 700;
              }
              .resend-btn {
                width: fit-content;
                color: $green00A;
                font-weight: 700;
                cursor: pointer;

                display: flex;
                align-items: center;
                column-gap: 8px;
                svg {
                  transition: all ease-in-out 600ms;
                  width: 18px;
                  height: 18px;
                }

                &:hover {
                  text-decoration: underline;
                  svg {
                    transform: translateX(8px);
                  }
                }
              }
            }
          }
        }
        &__bottom {
          margin-top: 40px;
          text-align: center;
          color: $black101;
        }

        @media screen and (max-width: 992px) {
          padding: 0 24px;
          .title {
            font-size: convert-rem(24);
            line-height: 32px;
          }
          &__middle {
            .auth-verify {
              .err-message {
                font-size: convert-rem(14);
                line-height: 20px;
              }
              .field {
                input {
                  padding: 8px 14px;
                  width: 46px !important;
                  height: 50px;

                  font-size: convert-rem(24);
                  line-height: 32px;
                }
              }
            }
            .auth-form .form-item {
              &__field .group .sub-label {
                font-size: convert-rem(14);
                line-height: 20px;
              }
            }
            .auth-policy {
              font-size: convert-rem(14);
              line-height: 20px;
            }
          }
        }
        @media screen and (max-width: 767px) {
          padding: 0;
          .sub-title {
            font-size: convert-rem(14);
            line-height: 20px;
          }
          &__top {
            text-align: left;
            .back-btn.has-icon .icon {
              width: 18px;
              height: 18px;
            }
          }
          &__middle {
            &.onboarding-mid {
              row-gap: 42px;
              .auth-verify {
                .label {
                  font-size: convert-rem(14);
                  line-height: 20px;
                  margin-bottom: 8px;
                }
                .field {
                  > div {
                    column-gap: 8px;
                  }
                  input {
                    padding: 8px 14px;
                    width: 46px !important;
                    height: 50px;

                    font-size: convert-rem(24);
                    line-height: 32px;
                  }
                }
                .err-message {
                  font-size: convert-rem(12);
                  line-height: 18px;
                }
                .resend-otp {
                  font-size: convert-rem(12);
                  line-height: 18px;
                  .resend-btn svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              .auth-form .form-item {
                &__err-message {
                  font-size: convert-rem(12);
                  line-height: 18px;
                }
              }
            }
            .auth-form .form-group {
              flex-direction: column;
              row-gap: 0;
              .form-item {
                width: 100%;
                &__field {
                  margin-top: 0 !important;
                }
              }
            }
          }
          &__bottom {
            margin-top: 32px;
            text-align: left;
            font-size: convert-rem(14);
            line-height: 20px;
          }
        }
      }
      .auth-check-inbox {
        @media screen and (max-width: 767px) {
          width: 312px !important;
        }
        display: flex;
        width: 420px !important;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        flex-shrink: 0;
        .green-color {
          color: $green00A;
          cursor: pointer;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          &:hover {
            text-decoration: underline;
          }
        }
        .auth-check-inbox__top {
          width: 100%;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .check-inbox {
            width: 80px;
            height: 80px;
            padding: 14px;
            float: left;
            background-color: #dcf7f5;
            border-radius: 50%;
            .img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .auth-check-inbox__middle {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 32px;
          align-self: stretch;
          .divider {
            height: 1px;
            align-self: stretch;
            background-color: #eaecf0;
          }
        }
      }
    }
  }

  .sidebar-logo {
    display: flex;
    align-items: center;
    column-gap: 32px;

    .logo {
      width: 112px;
      height: 36px;
      flex-shrink: 0;

      position: relative;
    }
    .tag {
      background-color: $cyan00B;
      border-radius: 6px;
      border: 1px solid $whiteFFF;
      overflow: hidden;

      padding: 4px 8px;
      span {
        font-size: convert-rem(14);
        font-weight: 600;
        line-height: 20px;
      }
      &--green {
        border-color: $green00A;
        background-color: $whiteFFF;
        color: $green00A;
      }
    }

    @media screen and (max-width: 767px) {
      column-gap: 16px;
      .logo {
        width: 90px;
        height: 28px;
      }
      .tag {
        padding: 2px 8px;
      }
    }
  }

  @media screen and (max-width: 991px) {
    &__sidebar {
      max-width: 420px;
      padding: 48px 48px 62px;
    }
  }

  @media screen and (max-width: 860px) {
    max-height: 100%;
    &__wrapper {
      flex-direction: column-reverse;
    }
    &__sidebar {
      max-width: 100%;
      padding: 40px 24px 60px;

      .sidebar-logo {
        display: none;
      }
      .welcome {
        max-width: 340px;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        &__img {
          width: 280px;
          height: 268px;
          margin-bottom: 24px;
        }
      }
    }
    &__mobile-header {
      padding: 40px 24px;
    }
    &__content {
      padding-top: 0;
      padding-bottom: 80px;
      .auth-content {
        margin-top: 0 !important;
        &__top .back-block {
          display: none;
        }
      }
      .auth-steps,
      .auth-logo {
        display: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__content {
      .content-wrapper {
        max-width: 100% !important;
        display: block;
      }
    }
  }

  a {
    color: $green00A !important;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }

  .questionnaires {
    position: relative;
    overflow: hidden;
    .questionnaire {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      &-name {
        flex-shrink: 0;
        font-size: convert-rem(14);
        font-weight: 700;
        line-height: 20px;
        color: $black101;
      }
      &-content {
        flex-grow: 1;
        overflow-x: hidden;
        overflow-y: auto;
        height: 280px;
        padding-right: 8px;
        .options-grid {
          margin-top: 1px;
          display: grid;
          grid-template-columns: repeat(2, minmax(calc(50% - 8px), 1fr));
          gap: 16px;

          .option {
            padding: 10px 12px;
            border: 1px solid $grayEAE;
            border-radius: 6px;
            overflow: hidden;
            &-inline {
              display: flex;
              align-items: center;
              column-gap: 12px;

              font-size: convert-rem(14);
              font-weight: 600;
              line-height: 20px;
              color: $black667;

              .icon {
                display: flex;
                align-items: center;
                column-gap: 8px;
                img {
                  flex-shrink: 0;
                }

                > span {
                  font-weight: 400;
                }
              }
            }
          }

          @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, minmax(100%, 1fr));
          }
        }
        .options-flex {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 16px;
          .option {
            flex-shrink: 0;
            border: 1px solid $grayEAE;
            border-radius: 6px;
            padding: 10px 16px;
            cursor: pointer;
            span {
              color: $black667;
              font-size: convert-rem(14);
              font-weight: 600;
              line-height: 20px;
            }
            &-active {
              border-color: $green00A;
              span {
                color: $green00A;
              }
            }
          }
        }
      }
      &-searching {
        position: sticky;
        top: 0;
        left: 0;

        background-color: $whiteFFF;
        z-index: 9;
        padding-bottom: 16px;
      }
      &-steps {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 6px;
        margin-top: 24px;
        .step-circle {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: $grayEAE;
          &.step-active {
            background-color: $green00A;
          }
        }
      }
      &-buttons {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 24px;
        .prev-btn {
          @include primary-white-button();
          padding: 10px 40px;
        }
        .next-btn {
          @include primary-green-button();
          padding: 10px 40px;
          &--full-width {
            width: 100% !important;
          }
        }

        @media screen and (max-width: 767px) {
          .prev-btn,
          .next-btn {
            width: calc(50% - 12px);
          }
        }
      }
    }
  }
}
