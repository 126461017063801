.category-page,
.tag-page {
  position: relative;
  overflow: hidden;
  .wrapper {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 32px 24px 48px;

    display: flex;
    flex-direction: column;
    .result {
      margin-bottom: 32px;
      &-name {
        color: $black333;
        font-size: convert-rem(30);
        font-weight: 700;
        line-height: 38px;
        margin-bottom: 2px;
      }
      &-display {
        margin-bottom: 0;
      }
    }
    .articles-block {
      display: flex;
      flex-direction: column;
      .title {
        color: $black333;
        font-size: convert-rem(30);
        font-weight: 700;
        line-height: 38px;
        margin: 32px 0 16px;
      }
      &:first-child {
        .title {
          margin-top: 0;
        }
      }
      @media screen and (max-width: 991px) {
        .title {
          font-size: convert-rem(24);
          line-height: 36px;
        }
      }
    }
    .articles {
      margin-left: -32px;
      margin-bottom: -48px;
      .list {
        width: 100%;
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
      }
      .article-item {
        width: 25%;
        padding-left: 32px;
        padding-bottom: 48px;
      }

      @media screen and (max-width: 991px) {
        margin-left: -24px;
        .article-item {
          width: 33.33%;
          padding-left: 24px;
        }
      }

      @media screen and (max-width: 767px) {
        margin-left: -16px;
        margin-bottom: -24px;
        .article-item {
          width: 50%;
          padding-left: 16px;
          padding-bottom: 24px;
        }
      }
    }
  }
}
