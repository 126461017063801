.venues-carousel {
  a:hover .venue-address {
    color: $black000;
  }
  .venue-item {
    display: block;
    width: 100%;
    max-width: 100%;
    height: 242px;
    padding: 38px;

    border: 1px solid $grayD0D;
    border-radius: 16px;
    background-color: $whiteFFF;
    overflow: hidden;
    color: $black000;

    display: flex;
    flex-direction: column;

    .venue-name {
      font-size: convert-rem(24);
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px;
    }
    .venue-address {
      margin-top: auto;
      font-size: convert-rem(18);
      font-weight: 600;
      line-height: 28px;
    }
    &:hover {
      color: unset;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    @media screen and (max-width: 767px) {
      padding: 24px;
      width: 100%;
      .venue-name {
        font-size: convert-rem(20);
        line-height: 30px;
      }
      .venue-address {
        font-size: convert-rem(16);
        line-height: 24px;
      }
    }
  }
}
.deals-carousel {
  padding-bottom: 16px;
  .deal {
    &-wrap {
      max-width: 100%;
      width: 626px;

      display: flex;
      align-items: stretch;
      background-color: $whiteFFF;
      border-radius: 16px;
      overflow: hidden;
      cursor: pointer;
      &:hover {
        .deal-image img {
          transform: scale(1.2);
        }
      }
    }
    &-image {
      width: 207px;
      height: 171px;
      flex-shrink: 0;
      position: relative;

      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transition: all ease 0.6s;
      }
    }
    &-info {
      flex-grow: 1;
      padding: 24px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      &__name {
        color: $black000;
        font-size: convert-rem(24);
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 6px;
      }
      &__description {
        color: $gray98A;
        line-height: 24px;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      &-image {
        width: 120px;
      }
      &-info {
        padding: 16px;
        &__name {
          font-size: convert-rem(18);
          line-height: 30px;
        }
      }
    }
  }
}
