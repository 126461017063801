.modal-customize {
  font-family: 'Nunito Sans', sans-serif;
  max-width: calc(100% - 24px);
  position: absolute;
  max-width: calc(100% - 24px);
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  background-color: $whiteFFF;
  padding: 24px;

  outline: none;
  border-radius: 12px;
  background: $whiteFFF;
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 16px;
    margin-bottom: 20px;

    border-bottom: 1px solid $grayD0D;
    .modal-title {
      color: $black101;
      font-size: convert-rem(18);
      font-weight: 700;
      line-height: 28px;
    }
    .modal-close {
      flex-shrink: 0;
      width: 24px;
      height: 24px;

      .close-btn {
        border: 0;
        background-color: $whiteFFF;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: $black000;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title {
      color: $black101;
      font-size: convert-rem(16);
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 32px;
      span {
        color: $redE60;
      }
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      column-gap: 8px;

      border-top: 1px solid $grayD0D;
      padding-top: 16px;

      button {
        font-size: convert-rem(14);
        line-height: 20px !important;
        padding: 10px 14px !important;
        &.btn-cancel {
          @include primary-white-button();
        }
        &.btn-delete {
          @include primary-red-button();
        }
      }
    }
    .sharing-content {
      .socials {
        display: flex;
        align-items: center;
        column-gap: 24px;
        margin-bottom: 32px;
        .social-media a {
          display: block;
          width: 37px;
          height: 37px;
          position: relative;
        }
      }
      .copy-link {
        .sub-title {
          color: $black344;
          font-size: convert-rem(14);
          font-weight: 600;
          line-height: 20px;

          margin-bottom: 6px;
        }
        .link-wrap {
          display: flex;
          align-items: center;
          column-gap: 8px;

          border-radius: 8px;
          border: 1px solid $grayD0D;
          background: $whiteFFF;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          padding: 12px 14px;
          position: relative;
          .link-icon {
            position: relative;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
          }
          input {
            flex-grow: 1;
            height: 100%;

            color: $black667;
            font-size: convert-rem(16);
            font-weight: 400;
            line-height: 24px;
            border: 0;
            padding: 0;
            margin: 0;
            outline: none;
            padding-right: 55px;

            &:hover {
              border: 0;
              box-shadow: none;
            }
          }
          .copy-btn {
            @include primary-green-button();
            position: absolute;
            right: 4px;
            top: 50%;

            transform: translate(0, -50%);

            font-size: convert-rem(14);
            font-weight: 700;
            line-height: 20px;

            padding: 8px 14px;
          }
        }
      }
    }
    .delete-content {
      .confirmation-field {
        margin-bottom: 32px;
        .custom-input {
          padding: 12px 14px;
          input {
            font-size: convert-rem(24);
            text-transform: uppercase;
            line-height: 36px;
            letter-spacing: 4px;
            font-weight: 600;
          }
        }
      }
    }
    .preview-content {
      display: flex;
      flex-direction: column;
      row-gap: 32px;
      padding-bottom: 32px;

      .datepicker-wrapper {
        width: 250px;
      }
      .prayer-timings {
        overflow: hidden;
        position: relative;
        .times {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          .prayer {
            width: calc(25% - 16px);
            border-radius: 16px;
            border: 1px solid $grayEAE;
            padding: 24px 14px 14px;

            position: relative;
            &-name {
              font-size: convert-rem(14);
              line-height: 22px;
              font-weight: 700;
              color: $green009;
              text-transform: capitalize;

              margin-bottom: 4px;
            }
            &-time {
              font-size: convert-rem(14);
              line-height: 20px;
              color: $black667;
              font-weight: 600;
            }
            &-upcoming {
              border-radius: 0 16px 0 16px;
              overflow: hidden;
              background-color: $green009;
              text-align: center;
              padding: 4px 8px;

              color: $whiteFFF;
              font-weight: 600;
              font-size: convert-rem(10);
              line-height: 18px;

              position: absolute;
              top: 0;
              right: 0;
            }
            &.active {
              background-color: $grayF2F;
            }
          }

          @media screen and (max-width: 767px) {
            gap: 14px;
            .prayer {
              width: calc(33.4% - 14px);
              &-upcoming {
                padding: 2px 6px;
              }
            }
          }
        }
      }
    }
    .view-gallery-content {
      margin: 0 -24px;
      .gallery-wrap {
        padding: 16px 48px 32px;
        height: 580px;

        display: flex;
        flex-direction: column;
        .swiper-carousel {
          height: calc(100% - 120px);
          .swiper-navigation {
            width: 40px;
            height: 40px;
            &:hover svg {
              color: $green009;
            }
            svg {
              width: 20px;
              height: 20px;
            }
          }
          &__next {
            right: -20px;
          }
          &__prev {
            left: -20px;
          }

          @media screen and (max-width: 991px) {
            .swiper-navigation {
              width: 36px;
              height: 36px;

              svg {
                width: 18px;
                height: 18px;
              }
            }
            &__left {
              left: -18px;
            }
            &__right {
              right: -18px;
            }
          }
          @media screen and (max-width: 767px) {
            .swiper-navigation {
              width: 32px;
              height: 32px;

              svg {
                width: 16px;
                height: 16px;
              }
            }
            &__prev {
              left: -16px;
            }
            &__next {
              right: -16px;
            }
          }
        }
        .view-photos-index {
          height: 60px;
          flex-shrink: 0;

          display: flex;
          align-items: center;
          justify-content: center;
          color: $black667;
          font-weight: 700;
          font-size: convert-rem(14);
          line-height: 20px;

          > span {
            color: $green009;
          }
        }
        .swiper-thumbs {
          width: 100%;
          height: 60px;
          flex-shrink: 0;

          .swiper-wrapper {
            justify-content: center;
          }
          .swiper-slide {
            width: 60px !important;
            cursor: pointer;
            &-thumb-active {
              .thumb-wrap {
                border: 2.5px solid $green009;
              }
            }
            .thumb-wrap {
              position: relative;
              width: 60px;
              height: 60px;
              border-radius: 4px;
              overflow: hidden;

              .video-thumb {
                width: 100%;
                height: 100%;
                background-color: $black101;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  color: $whiteFFF;
                }
              }
            }
          }
        }

        @media screen and (max-width: 991px) {
          padding: 16px 48px 24px;
          height: 440px;
        }
        @media screen and (max-width: 767px) {
          .swiper-carousel {
            height: calc(100% - 92px);
          }
          .view-photos-index {
            height: 46px;
          }
          .swiper-thumbs {
            height: 46px;
            .swiper-slide {
              width: 46px !important;
              cursor: pointer;
              &-thumb-active {
                .thumb-wrap {
                  border: 2px solid $green009;
                }
              }
              .thumb-wrap {
                position: relative;
                width: 46px;
                height: 46px;
              }
            }
          }
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  background: rgba(0, 0, 0, 0.35) !important;
  z-index: 110;
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Overlay:has(.modal-view-map) {
  z-index: 9999;
}

.modal-view-map {
  max-height: 90vh !important;
  overflow-y: scroll !important;
  max-width: 1135px;
  padding: 20px 0 20px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
    min-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    padding: 0;
    overflow: hidden !important;
  }
}

.view-as-map {
  @media screen and (max-width: 1024px) {
    padding-right: 0px !important;
  }
  .gm-style-iw {
    padding: 0 !important;
    box-shadow: none !important;

    .gm-style-iw-chr {
      display: none !important;
    }

    .gm-style-iw-d {
      background-color: $black101;
      color: #fff;
      padding: 12px 16px;
      border-radius: 8px;
      font-size: 14px;
      letter-spacing: 0.028px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        width: 0px;
        display: none;
      }
    }
  }

  .gm-style-iw-tc {
    filter: none !important;
  }

  .gm-style-iw-tc::after {
    transform: translate(3px, -45px) rotate(-180deg) !important;
    background-color: $black101 !important;
    box-shadow: none !important;
    filter: none !important;
  }

  .modal-dialog {
    max-width: 1135px !important;

    @media screen and (max-width: 1024px) {
      margin: 0 !important;
      height: 100vh !important;
    }
  }
  .modal-body {
    padding: 20px 0 20px !important;

    @media screen and (max-width: 1024px) {
      max-height: calc(100vh - 128px) !important;
      height: calc(100vh - 128px) !important;
      padding: 0 !important;
    }
  }
  .modal-content {
    border-radius: 16px !important;
    overflow-y: scroll !important;
    max-height: 90vh !important;

    @media screen and (max-width: 1024px) {
      border-radius: 0px !important;
      max-height: calc(100vh - 128px) !important;
      height: calc(100vh - 128px) !important;
      margin: -10px 0 10px;
    }

    &::-webkit-scrollbar {
      width: 0px !important;
      display: none !important;
    }
  }
  .view-map-article {
    .view-map-article__header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      padding: 12px 20px;
      z-index: 2;
      height: 60px;
      border-bottom: 1px solid $grayF2F;
      display: none;

      @media screen and (max-width: 1024px) {
        display: block;
      }

      .view-map-article__header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .view-map-article__header-back {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        border: 1px solid $grayD0D;
        background-color: #fff;
        height: 100%;
        padding: 8px 14px;
        height: 36px;
      }
    }
    .view-map-article__wrapper {
      display: flex;
      padding: 0 20px;
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        display: block;
        max-height: calc(100vh - 128px);
        overflow-y: scroll;
        padding: 0;
      }

      .view-map-article__map {
        position: relative;
        flex: 1;
        margin-right: 20px;
        border-radius: 16px;
        overflow: hidden;

        .view-map-article__map-back {
          position: absolute;
          top: 20px;
          left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          border: 1px solid $grayD0D;
          background-color: #fff;
          height: 40px;
          padding: 10px 16px;

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }

        @media screen and (max-width: 1024px) {
          margin-right: 0;
          margin-bottom: 20px;
          height: 500px;
          border-radius: 0;
        }
      }

      .view-map-article__blog {
        width: 380px;

        @media screen and (max-width: 1024px) {
          width: 100%;
          padding: 0 20px 20px;
        }

        .view-map-article__blog-image {
          img {
            max-height: 220px;
            width: 100%;
            object-fit: cover;
            border-radius: 6px;
          }

          @media screen and (max-width: 1024px) {
            display: none;
          }
        }
        .view-map-article__blog-head {
          margin: 20px 0 24px;
          display: flex;
          justify-content: space-between;

          .view-map-article__blog-head-close {
            display: none;
            border: none;
            outline: none;
            background-color: transparent;
            height: 20px;

            @media screen and (max-width: 1024px) {
              display: block;
            }
          }

          .view-map-article__blog-head-title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #101828;
            margin-bottom: 0;
          }
          .view-map-article__blog-head-rating {
            display: flex;
            align-items: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            gap: 4px;
            line-height: normal;
            letter-spacing: 0.028px;

            span {
              display: flex;
            }
          }
        }
        .view-map-article__blog-content {
          .view-map-article__blog-description {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 175%;
            letter-spacing: 0.032px;
          }

          .view-map-article__blog-contact {
            display: flex;
            flex-direction: column;
            margin-bottom: 37px;

            @media screen and (max-width: 1024px) {
              margin-bottom: 0;
            }

            li {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .view-map-article__blog-contact-item {
              display: flex;
              align-items: center;
              color: #475467;

              span {
                letter-spacing: 0.032px;
                text-decoration-line: underline;
                margin-left: 8px;
              }
            }
          }
        }
        .view-map-article__footer {
          @media screen and (max-width: 1024px) {
            position: fixed;
            background-color: #fff;
            bottom: 0;
            left: 0;
            right: 0;
            box-shadow: 0px -1px 3px 0px rgba(16, 24, 40, 0.1),
              0px -1px 2px 0px rgba(16, 24, 40, 0.06);
            padding: 12px 20px 20px;
            height: 78px;
          }

          .view-map-article__footer-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0 24px;
            border-bottom: 1px solid #eaecf0;

            @media screen and (max-width: 1024px) {
              padding: 0;
              border-bottom: none;
            }

            .view-map-article__footer-more {
              border-radius: 8px;
              border: 1px solid $green00A;
              background: $green00A;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              padding: 10px 18px;
              color: #fff;
              transition: all ease 0.25s;

              &.only-mobile {
                display: none;
                @media screen and (max-width: 1024px) {
                  display: block;
                }
              }

              &.only-desktop {
                @media screen and (max-width: 1024px) {
                  display: none;
                }
              }

              &:hover {
                opacity: 0.85;
              }
            }
            .view-map-article__footer-pagination {
              display: flex;
              align-items: center;

              @media screen and (max-width: 1024px) {
                justify-content: space-between;
                width: 100%;
              }
            }
            .view-map-article__footer-pagination-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              border: 1px solid $grayD0D;
              background-color: #fff;
              padding: 12px;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              transition: all ease 0.25s;

              &:disabled {
                opacity: 0.7;
                cursor: not-allowed;

                &:hover {
                  border-color: $grayD0D;
                }
              }

              &:hover {
                border-color: $green00A;
              }

              &:first-child {
                margin-right: 12px;
                @media screen and (max-width: 1024px) {
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .view-map-article__blogs {
      overflow: hidden;
      padding: 0 20px;
      @media screen and (max-width: 1024px) {
        display: none;
        padding: 0;
      }
      .view-map-article__blogs-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 24px 0 12px 0;

        span {
          font-weight: 700;
          color: $black101;
        }

        button {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 14px;
          letter-spacing: 0.028px;
          font-weight: 600;
          color: $green00A;
          transition: all ease 0.25s;

          &:hover {
            opacity: 0.85;
          }
        }
      }
      .view-map-article__blogs-article {
        display: flex;
        align-items: center;
        border-radius: 12px;
        border: 1px solid $grayEAE;
        background-color: #fff;
        padding: 12px;
        transition: all ease 0.25s;
        cursor: pointer;

        &:hover {
          border-color: $green00A;
        }

        &.active {
          border-color: $green00A;
        }

        .view-map-article__blogs-article-img {
          min-width: 60px;
          max-width: 60px;
          min-height: 60px;
          max-height: 60px;
          border-radius: 8px;
          margin-right: 12px;
          object-fit: cover;
        }
        .view-map-article__blogs-article-txt {
          font-weight: 700;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
