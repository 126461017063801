.dashboard-navigation-mobile {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 16px 32px;
  position: sticky;
  top: 0;
  z-index: 2000;
  background-color: white;
}

.dashboard-page {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  .dashboard-wrap {
    display: flex;
    min-height: 100vh;
    overflow: auto;
    background-color: $grayF9F;
  }
  &__sidebar {
    max-width: 320px;
    width: 100%;
    flex-shrink: 0;
    z-index: 999;
    position: relative;
    &-collapsed {
      max-width: 82px;
    }
    .sidebar-head {
      display: flex;
      // flex-direction: column;
      justify-content: center;
      // row-gap: 24px;
      &__logo {
        position: relative;
        width: 92px;
        height: 92px;
      }
      // &__search {
      //   .search-wrap {
      //     display: flex;
      //     align-items: center;
      //     column-gap: 12px;
      //     padding: 10px 14px;

      //     border: 1px solid $grayF2F;
      //     border-radius: 8px;
      //     overflow: hidden;

      //     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      //     .custom-input,
      //     .search-btn {
      //       border: 0;
      //       padding: 0;
      //       box-shadow: none;
      //     }
      //     &:focus-within {
      //       border-color: $green00A;
      //     }
      //   }
      //   .custom-input {
      //     flex-grow: 1;
      //   }
      //   .search-btn {
      //     flex-shrink: 0;
      //     @include primary-white-button();
      //     .icon {
      //       width: 20px !important;
      //       height: 20px !important;
      //     }
      //   }
      //   &.search-collapsed {
      //     .search-wrap {
      //       padding: 8px;
      //       .search-btn {
      //         width: 100%;
      //       }
      //       .custom-input {
      //         width: 0px;
      //       }
      //     }
      //   }
      // }
    }

    .ps-sidebar-root {
      position: fixed;
      height: 100%;
      left: 0;
      @media screen and (max-width: 1023px) {
        height: 95%;
      }
      &.ps-collapsed {
        .ps-sidebar-container {
          padding: 32px 16px;
          .sidebar-head {
            &__logo {
              width: 48px;
              height: 48px;
            }
          }
          .sidebar-menu {
            .ps-menu-button {
              padding: 12px;
              .ps-menu-icon {
                margin-right: 0;
                width: 24px;
                height: 24px;
                min-width: 24px;
              }
              .ps-menu-label {
                opacity: 0;
              }
            }
          }
          .sidebar-bottom {
            &__user-avatar {
              height: 40px;
              margin: 0 auto;
            }
            &__user-info {
              display: none;
            }
          }
        }
      }
    }
    .ps-sidebar-container {
      background-color: $whiteFFF;
      padding: 32px;

      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    .ps-menuitem-root {
      margin-bottom: 12px;
      .ps-menu-button {
        height: auto;
        padding: 16px 12px;
        border-radius: 6px;
        overflow: hidden;
        &.ps-active {
          background-color: $grayF2F;
        }
        &:hover {
          background-color: $grayF2F;
        }
      }
      .ps-menu-label {
        opacity: 1;
        .menu-item {
          color: $black475;
          font-weight: 600;
          &.has-icon {
            display: flex;
            align-items: center;
            column-gap: 12px;

            .menu-icon {
              width: 24px;
              height: 24px;
              flex-shrink: 0;
              position: relative;
            }
          }
        }
      }
    }
    .ps-menu-root {
      height: calc(100vh - 92px - 65px);
      margin-right: -12px;
      overflow: auto;
      ul {
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 12px;
      }
    }

    .sidebar-bottom {
      margin-top: auto;
      position: relative;
      bottom: 0;

      padding-top: 24px;
      border-top: 1px solid $grayF2F;
      &__content {
        display: flex;
        align-items: center;
        column-gap: 12px;
        cursor: pointer;
      }
      &__user-avatar {
        width: 40px;
        height: 40px;

        position: relative;
        border-radius: 50%;
        overflow: hidden;
      }
      &__user-info {
        font-size: convert-rem(14);
        line-height: 20px;
        .user-name {
          color: $black000;
          font-weight: 700;
        }
      }
      &__popover {
        width: 100%;

        position: absolute;
        top: -48px;
        left: 0;

        .popover-content {
          padding: 8px 0;
          background-color: $whiteFFF;
          box-shadow: 2px 1px 2px 1px rgba(16, 24, 40, 0.05);
          border-radius: 6px;
          position: relative;
          &:before {
            content: '';
            width: 10px;
            height: 10px;
            background-color: $grayF2F;

            position: absolute;
            bottom: -5px;
            left: 16px;

            transform: rotateZ(45deg);
            z-index: -1;
          }
          .item {
            padding: 8px 12px;
            cursor: pointer;
            display: flex;
            align-items: center;
            column-gap: 8px;
            &-icon {
              flex-shrink: 0;
              svg {
                width: 18px;
                height: 18px;
              }
            }
            &:hover {
              background-color: $grayF2F;
            }
          }

          @media screen and (max-width: 1023px) {
            .item {
              &-name {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &__content {
    flex-grow: 1;
    padding: 40px 32px 24px;
    overflow: hidden;

    .dashboard-content {
      .head-content {
        margin-bottom: 32px;
        &__title {
          color: $black101;
          font-size: convert-rem(30);
          font-weight: 600;
          line-height: 38px;
          margin-bottom: 6px;
        }
        &__sub-title {
          color: $black667;
          line-height: 24px;
        }
      }
      .main-content {
        color: $black101;
        .menu-content {
          position: relative;
          .menu-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            margin-bottom: 32px;
            &__title {
              font-size: convert-rem(24);
              font-weight: 600;
              line-height: 32px;
            }
            &__datepicker {
              position: relative;
              .date-range {
                border-radius: 8px;
                border: 1px solid $grayD0D;
                background: $whiteFFF;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

                display: flex;
                align-items: center;
                column-gap: 8px;

                padding: 10px 16px;
                cursor: pointer;

                &-active {
                  border-color: $green00A;
                }
              }
              .date-range-icon {
                width: 20px;
                height: 20px;
                flex-shrink: 0;

                position: relative;
              }
              .date-range-value {
                font-size: convert-rem(14);
                font-weight: 700;
                line-height: 20px;

                color: $black344;
              }
              .date-range-popover {
                height: 460px;
                background-color: $whiteFFF;
                position: absolute;
                right: 0;
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
                border-radius: 8px;
                overflow: hidden;

                display: flex;
                flex-direction: column;
                z-index: 99;
              }
              .date-range-actions {
                margin-top: auto;
                border-top: 1px solid $grayF2F;
                padding: 12px;

                display: flex;
                align-items: center;
                justify-content: flex-end;
                column-gap: 12px;

                button {
                  padding: 8px 12px !important;
                  font-size: convert-rem(14) !important;
                  line-height: 20px !important;
                  &.cancel-btn {
                    @include primary-white-button();
                  }
                  &.approve-btn {
                    @include primary-green-button();
                  }
                }
              }
              .date-range-overlay {
                position: fixed;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;

                background-color: transparent;
              }
            }
          }

          .head-saving {
            flex-shrink: 0;
            &--on-left {
              width: 100%;
              display: flex;
              justify-content: right;

              margin-top: 32px;
            }
            .save-btn {
              @include primary-white-button();
              font-size: convert-rem(14);
              line-height: 20px;
            }
          }
          .dashboard-form {
            padding: 16px 0;
            display: grid;
            grid-template-rows: 1fr;
            transition: grid-template-rows 500ms;
            &.collapsed {
              grid-template-rows: 0fr;
            }
            .form-content {
              &.over-hidden {
                overflow: hidden;
              }
              .form-group {
                display: flex;
                align-items: stretch;
                column-gap: 32px;
                &__label {
                  width: 280px;
                  flex-shrink: 0;
                  .label,
                  .sub-label {
                    color: $black344;
                    font-size: convert-rem(14);
                    font-weight: 600;
                    line-height: 20px;
                  }
                  .label {
                    position: relative;
                    &__required:after {
                      content: '*';
                      position: absolute;
                      top: 0;

                      font-size: convert-rem(16);
                      line-height: 22px;
                      margin-left: 4px;
                      color: $redE60;
                    }
                  }
                  .sub-label {
                    color: $black667;
                    font-weight: 400;
                  }
                }
                &__field {
                  flex-grow: 1;
                  max-width: 512px;
                  .custom-input {
                    padding: 10px 14px;
                    border-color: $grayEAE;
                    input,
                    textarea {
                      padding: 0;
                      font-size: convert-rem(14);
                      line-height: 20px;
                    }
                    &:focus-within {
                      border-color: $green009;
                    }
                  }
                  .upload-group {
                    display: flex;
                    align-items: stretch;
                    column-gap: 24px;
                    .upload-preview {
                      width: 64px;
                      height: 64px;
                      flex-shrink: 0;

                      position: relative;
                      border-radius: 50%;
                      cursor: pointer;
                      img {
                        border-radius: 50%;
                      }
                    }
                    .upload-remove {
                      position: absolute;
                      top: -6px;
                      right: -6px;
                      .remove-upload-btn {
                        @include primary-white-button();
                        width: 24px;
                        height: 24px;
                        padding: 0;
                        border-radius: 50%;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &.has-icon .icon {
                          width: 16px;
                          height: 16px;
                        }
                      }
                    }
                    .upload-field {
                      flex-grow: 1;
                      height: 126px;

                      border-radius: 8px;
                      border: 1px solid $grayEAE;
                      background-color: $whiteFFF;
                      overflow: hidden;
                      position: relative;
                      &:focus-within {
                        border-color: $green00A;
                      }

                      &__inputting {
                        width: 100%;
                        height: 100%;
                        color: transparent;
                        z-index: 9;
                        position: relative;

                        box-shadow: none;
                        border: 0;
                        outline: none;
                        cursor: pointer;
                        &::-webkit-file-upload-button {
                          display: none;
                        }
                        &:focus-visible {
                          border: 0;
                        }
                        &:disabled {
                          cursor: no-drop;
                        }
                      }
                      &__content {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;

                        z-index: 1;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 16px;
                        &.disabled {
                          opacity: 0.6;
                        }
                        .upload-icon {
                          width: 40px;
                          height: 40px;
                          flex-shrink: 0;

                          border-radius: 50%;
                          border: 6px solid $grayF9F;
                          background-color: $grayF2F;
                          position: relative;
                          margin-bottom: 12px;

                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                        .upload-action-text,
                        .upload-note {
                          color: $black667;
                          font-size: convert-rem(14);
                          line-height: 20px;
                        }
                        .upload-action-text {
                          margin-bottom: 4px;
                          .highlight {
                            color: $green009;
                            font-weight: 700;
                          }
                        }
                      }
                    }
                  }
                  .form-select {
                    .custom-selection {
                      &__control {
                        border-radius: 8px;
                        border-color: $grayEAE;
                      }
                      &__value-container {
                        padding: 10px 14px;
                      }
                      &__placeholder {
                        margin: 0;
                        color: $black667;
                      }
                      &__single-value {
                        margin: 0;
                        color: $black101;
                        font-weight: normal;
                      }
                      &__indicator {
                        right: 0;
                        svg {
                          color: $black667;
                        }
                      }
                    }
                  }
                  .upload-multi-preview {
                    margin-top: 16px;
                    .photos {
                      display: flex;
                      flex-direction: column;
                      row-gap: 16px;

                      .photo-preview {
                        background-color: $whiteFFF;
                        border-radius: 8px;
                        border: 1px solid $grayEAE;
                        padding: 16px;

                        display: flex;
                        align-items: stretch;
                        column-gap: 16px;
                        cursor: pointer;
                        position: relative;
                        .photo-icon {
                          flex-shrink: 0;
                        }
                        .photo-info {
                          flex-grow: 1;
                          font-size: convert-rem(14);
                          line-height: 20px;
                          &__name {
                            color: $black344;
                            font-weight: 600;
                          }
                          &__size {
                            color: $black667;
                          }
                        }
                        .photo-remove {
                          position: absolute;
                          top: -12px;
                          right: -12px;
                          .remove-btn {
                            @include primary-white-button();
                            &.has-icon {
                              width: 24px;
                              height: 24px;
                              border-radius: 50%;
                              padding: 0;
                              .icon {
                                width: 16px;
                                height: 16px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .note {
                    margin-top: 8px;
                    color: $black667;
                    font-size: convert-rem(14);
                    line-height: 20px;
                  }
                  .opening-hours-selection {
                    display: flex;
                    align-items: center;
                    column-gap: 16px;
                    position: relative;

                    .day {
                      flex-grow: 1;
                    }
                    .group-hours {
                      display: flex;
                      align-items: center;
                      column-gap: 16px;
                    }
                    .hour {
                      flex-shrink: 0;
                      display: flex;
                      align-self: center;
                      align-items: center;
                      column-gap: 12px;
                      .time {
                        display: flex;
                        align-items: center;
                        column-gap: 12px;
                        background-color: $whiteFFF;
                        padding: 10px 14px;
                        border-radius: 8px;
                        border: 1px solid $grayD0D;
                        &:focus-within {
                          border-color: $green009;
                        }

                        &-label {
                          color: $black344;
                          font-size: convert-rem(14);
                          font-weight: 600;
                          line-height: 20px;
                        }
                        &-input {
                          max-width: 72px;
                          width: 100%;

                          input {
                            width: 100%;
                            border: 0;
                            background-color: transparent;
                            font-size: convert-rem(14);
                            line-height: 20px;
                            color: $black101;
                            padding: 0;
                            outline: 0;
                            &:focus {
                              border: 0;
                              box-shadow: none;
                            }
                          }
                        }
                      }
                    }
                    .remove {
                      width: 24px;
                      height: 24px;
                      flex-shrink: 0;

                      position: absolute;
                      right: -40px;
                      top: 50%;
                      transform: translate(0, -50%);
                      .remove-btn {
                        @include secondary-red-button();
                        border-radius: 50%;
                        padding: 2px;
                        &.has-icon .icon {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          svg {
                            width: 16px;
                            height: 16px;
                          }
                        }
                      }
                    }
                  }
                }
                &__error {
                  font-size: convert-rem(14);
                  line-height: 22px;
                  color: $redE60;
                  margin-top: 6px;
                  margin-bottom: 0;
                }
                &__additional {
                  display: flex;
                  flex-direction: column;
                  row-gap: 24px;
                  max-width: 512px;
                  width: 100%;

                  .add-more {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .add-hour-btn {
                      @include secondary-green-button();
                      border-radius: 50%;
                      padding: 6px;

                      &.has-icon .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        svg {
                          width: 18px;
                          height: 18px;
                        }
                      }
                    }
                  }
                }

                @media screen and (max-width: 991px) {
                  flex-direction: column;
                  row-gap: 24px;
                  &__field {
                    .upload-images .upload-group {
                      flex-direction: column;
                      row-gap: 24px;
                    }
                    .opening-hours-selection {
                      flex-direction: column;
                      row-gap: 16px;
                      align-items: flex-start;

                      .day {
                        width: 100%;
                        &.adding-day {
                          width: calc(100% - 40px);
                        }
                      }
                      .group-hours {
                        width: 100%;
                      }
                      .hour {
                        flex: 1 0 25%;
                        .time-input {
                          min-width: 52px;
                        }
                      }
                      .remove {
                        top: 21.5px;
                        right: 0;
                      }
                    }
                  }
                }
              }
              .form-space-line {
                width: 100%;
                height: 1px;
                background-color: $grayEAE;
                margin: 24px 0;
              }
            }
          }
          .menu-company-venues {
            .company-details {
              &__head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 16px;
                margin-bottom: 8px;

                .head-title {
                  color: $black101;
                  font-size: convert-rem(20);
                  font-weight: 600;
                  line-height: 30px;
                }
              }
              &__venues {
                .venues-panel {
                  &__title {
                    margin: 24px 0;
                    padding: 16px 12px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    column-gap: 16px;
                    border-radius: 6px;
                    background: $grayF2F;
                    cursor: pointer;

                    position: relative;
                    &.collapsed {
                      border: 1px solid $grayEAE;
                    }
                    .venue-name {
                      display: flex;
                      align-items: center;
                      column-gap: 12px;
                    }
                    .venue-remove {
                      position: absolute;
                      top: -16px;
                      right: -12px;

                      .remove-btn {
                        @include secondary-red-button();
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;

                        &.has-icon {
                          padding: 0;
                          display: flex;
                          align-items: center;
                          justify-content: center;

                          svg {
                            width: 18px;
                            height: 18px;
                            vertical-align: unset;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &__more-venues {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .add-venue-btn {
                  @include primary-green-button();
                  font-size: convert-rem(14);
                  line-height: 22px;
                  .icon svg {
                    width: 18px;
                    height: 18px;
                  }
                  .title {
                    font-size: convert-rem(14);
                    line-height: 22px;
                  }
                }
              }
            }
          }
          .menu-overview {
            margin-top: 24px;
            .content {
              margin-bottom: 24px;
              .statistic-name {
                color: $black101;
                font-size: convert-rem(20);
                font-weight: 600;
                line-height: 30px;
                margin-bottom: 24px;
              }
              .statistic-list {
                overflow: auto;
                padding-bottom: 12px;
                .overview-table {
                  width: 100%;
                  &-thead {
                    width: 100%;
                    background-color: $whiteFFF;
                    border-bottom: 1px solid $grayEAE;
                    th {
                      &:first-child {
                        width: 512px;
                      }
                      .title {
                        padding: 12px 24px;
                        color: $black667;
                        white-space: nowrap;

                        &.has-sorter {
                          display: flex;
                          align-items: center;
                          cursor: pointer;
                        }

                        .sorter-btn {
                          border: 0;
                          background-color: $whiteFFF;
                          outline: none;

                          display: flex;
                          align-items: center;

                          &:hover {
                            box-shadow: none;
                          }
                          svg {
                            width: 20px;
                            height: 20px;
                            color: $black667;
                          }
                        }
                      }
                      &:last-child {
                        text-align: right;
                        .title.has-sorter {
                          justify-content: flex-end;
                        }
                      }
                    }
                  }
                  &-tbody {
                    border-bottom: 1px solid $grayEAE;
                    &:nth-child(odd) {
                      background-color: $whiteFFF;
                    }
                    td {
                      &:first-child {
                        .td-wrap {
                          width: 512px;
                        }
                      }
                      &:last-child {
                        .td-wrap {
                          text-align: right;
                        }
                      }
                      &:nth-child(2),
                      &:last-child {
                        .td-wrap {
                          white-space: nowrap;
                        }
                      }
                      .td-wrap {
                        padding: 16px 24px;
                        font-size: convert-rem(14);
                        line-height: 20px;
                        &.text-center {
                          text-align: center;
                        }
                        &.text-right {
                          text-align: right;
                        }
                        a:hover {
                          text-decoration: none;
                        }
                        .name {
                          color: $black101;
                          font-weight: 600;
                          &.venue-name:hover {
                            color: $green009;
                          }
                        }
                        .slug {
                          a {
                            color: $black667;
                            &:hover {
                              text-decoration: none;
                              color: $green009;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-content {
    max-width: 500px;
  }
}
@media screen and (max-width: 1080px) {
  .dashboard-content {
    max-width: 700px;
  }
}
