.listingDetail-sidebar__container {
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  min-width: 350px;
  box-sizing: border-box;
  border-radius: 15px;
  .listingDetail-about__content__truncated {
    max-height: 180px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    &__show-more {
      display: block;
      margin-top: 12px;
      color: #0cc;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &.about-truncated__disable {
        overflow: visible;
        text-overflow: clip;
        max-height: none;
        height: auto;
        display: block;
    }
  }
  .listingDetail-sidebar__content{
    margin-bottom: 5px;
  }
  .listingDetail-sidebar__title {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    h5 {
        margin: 0;
    }
  }
  .listingDetail-openingHours__container {
    .listingDetail-openingHours__data {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        margin-top: 12px;
        &__open {
            color: #039855;
            font-weight: 700;
        }
        &__close {
            color: #f00;
            font-weight: 700;
        }
        &__close-soon {
            color: #DC6803;
            font-weight: 700;
        }
    }
  }
  .listingDetail-followUs__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
}
