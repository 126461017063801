.dashboard-page__content {
  height: 100%;
}
.table_container {
  width: fit-content;
  &.data-container {
    padding: 12px 16px;
  }
  display: flex;
  gap: 5px;
  & > div {
    padding-left: 5px;
    span {
      display: block;
      font-weight: bolder;
    }
  }
  .analytic-listing-title {
    min-width: 470px;
    a {
      color: '#667085';
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      &:hover {
        text-decoration: underline;
        color: '#F2F4F7';
      }
    }
  }
  .analytic-listing-page-view {
    min-width: 140px;
  }
  .analytic-listing-website-click {
    min-width: 130px;
  }
  .analytic-listing-action-button-click {
    min-width: 160px;
  }
  .analytic-listing-premind-button-click {
    min-width: 175px;
  }
  .analytic-listing-social-media-click {
    min-width: 150px;
  }
  .analytic-data {
    align-content: center;
    text-align: center;
  }
}
.date-range-pop {
  right: 0;
  top: 40px;
  width: 460px;
  height: fit-content;
  margin-top: 10px;
  background-color: white;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0.5px 5px #e5e5e5;
  border-radius: 10px;
  .react-datepicker__day {
    margin: 0 !important;
    min-width: 30px;
  }
  .react-datepicker__day--in-selecting-range {
    background-color: #00a7ad !important;
  }
  .react-datepicker__day--in-range {
    border-radius: 0 !important;
    &.react-datepicker__day--selected {
      background-color: #00a7ad !important;
      color: white;
      border-radius: 40% 0 0 40% !important;
    }
    background-color: #dcf7f5 !important;
    color: #009294;
    &.react-datepicker__day--range-end {
      color: white;
      background-color: #00a7ad !important;
      border-radius: 0 40% 40% 0 !important;
    }
  }
  .react-datepicker__week {
    margin-top: 5px !important;
    & > div:first-child {
      &.react-datepicker__day--in-range {
        border-radius: 40% 0 0 40% !important;
      }
    }
    & > div:last-child {
      &.react-datepicker__day--in-range {
        border-radius: 0 40% 40% 0 !important;
      }
    }
  }
}
.date-range-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #009294;
  border-radius: 15px;
  box-shadow: 0px 0px 5px #a9eae7;
  padding: 10px 15px;
  margin-left: 10px;
  & > input {
    min-width: 210px;
    border: none;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &:hover {
    cursor: pointer;
    border-color: #00a7ad;
  }
}

.react-datepicker {
  & > div.react-datepicker__month-container:last-child {
    border-left: 0.5px solid #e5e5e5;
  }
  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: white;
      border-bottom: white;
    }
  }
}

.date-range-actions-analytic {
  display: flex;
  justify-content: space-between;
  max-width: 460px;
  padding-bottom: 15px;
  min-width: 460px;
  &>div:first-child {
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      width: 10px;
      line-height: 1px;
      height: 1px;
      border: 1px solid #667085;

    }
    input {
      width: 120px;
      border: 1px solid #D0D5DD;
      padding: 5px 8px;
      box-shadow: 0px 0px 5px #D0D5DD;
      text-align: center;
      border-radius: 5px;
      &:focus {
        outline: none;
      }
    }
  }
  &>div:last-child {
    margin-right: 10px;
    display: flex;
    gap: 10px;
    &>button {
      padding: 5px 10px;
      border-radius: 5px;
      border: 1px solid #009294;
      background-color: #009294;
      color: white;
      &:hover {
        background-color: #00a7ad;
        border-color: #00a7ad;
      }
    }
    &>button:first-child {
      background-color: white;
      color: #344054;
    }
  }
}


@media screen and (max-width: 480px) {
  .analytic-header {
    flex-direction: column;
    margin: 0;
    padding: 0;
    .date-range-input {
      min-width: 320px !important;
      // padding: 0;
      margin: 0;
      width: 280px;
      z-index: 0;
      .date-range-pop {
        right: auto;
        left: 0;
      }
    }
  }
  .dashboard-page__content {
    margin: 0;
    padding: 0 !important;
  }
  .dashboard-page__sidebar {
    z-index: 9999;
  }
  .profile__content {
    padding: 0 !important;
  }
}