.listing-detail-page {
  position: relative;
  width: 100vw;
  .page-content {
    display: flex;
    padding: 10px 30px;
    flex-direction: column;
    .listing-info {
      padding: 32px 0;

      .head-title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 32px;
        column-gap: 24px;
        &__name {
          font-size: convert-rem(48);
          font-weight: 700;
          line-height: 60px;
          letter-spacing: -0.96px;

          font-family: 'Nunito Sans', sans-serif;
          margin: 0;
        }
        &__btn {
          flex-shrink: 0;
          @include secondary-green-button();
          text-decoration: underline;
        }
      }
      .detail {
        padding-top: 32px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        column-gap: 24px;

        .basic-info {
          display: flex;
          align-items: stretch;
          column-gap: 32px;

          .avatar {
            flex-shrink: 0;
            width: 136px;
            height: 136px;

            position: relative;

            border-radius: 50%;
            border: 6px solid $whiteFFF;
            overflow: hidden;
          }
          .name {
            grid-area: item2;
            font-size: convert-rem(24);
            font-weight: 700;
            line-height: 32px;
            margin-bottom: 0;
          }
          .info {
            flex-grow: 1;
            max-width: 516px;
            width: 100%;
            &__role {
              font-size: convert-rem(18);
              color: $green009;
              line-height: 28px;
              text-transform: capitalize;
            }
            &__contact {
              margin-top: 16px;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              column-gap: 24px;
              .contact {
                margin-bottom: 0;
              }
              .phone-number {
                flex-shrink: 0;
              }
            }
          }
        }
        .interactive {
          width: 40%;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &__actions {
            display: flex;
            align-items: center;
            column-gap: 16px;
            margin-bottom: 20px;

            .add-favourite-btn,
            .share-btn {
              @include primary-white-button();
            }
          }
        }
      }

      @media screen and (max-width: 1023px) {
        .detail {
          padding-top: 32px;
          flex-wrap: wrap;
          row-gap: 24px;
        }
        .interactive {
          width: 100% !important;
          flex-direction: row !important;
          align-items: center !important;
          justify-content: space-between;
          &__actions {
            margin-bottom: 0 !important;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .head-title {
          flex-direction: column;
          row-gap: 24px;
          margin-bottom: 24px;
          &__name {
            font-size: convert-rem(36);
            line-height: 44px;
          }
        }
        .detail {
          flex-direction: column;
          .basic-info {
            gap: 16px;
            grid-template-areas:
              'item1 item2 item2 item2'
              'item3 item3 item3 item3';
            .avatar {
              width: 92px;
              height: 92px;
            }
            .name {
              font-size: convert-rem(20);
              line-height: 30px;
            }
            .info {
              &__contact {
                flex-direction: column;
                row-gap: 12px;
                font-size: convert-rem(14);
                line-height: 20px;
              }
              &__role {
                font-size: convert-rem(16);
                line-height: 24px;
              }
            }
          }
          .interactive {
            width: 100%;
            flex-direction: column !important;
            align-items: flex-start !important;
            row-gap: 16px !important;

            &__actions {
              width: 100%;
            }
          }
        }
      }
    }
    .venue-info {
      .block-wrap {
        padding: 32px 0;
        border-top: 1px solid $grayD0D;
      }
      &__tabs {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 24px;
        &.sticky {
          max-width: 100%;
          position: sticky;
          z-index: 99;
          top: 0;
          left: 0;
          box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
          .tab-wrap {
            padding: 0 24px;
            border: 0;
          }
        }
        .tab-wrap {
          max-width: 1280px;
          margin: 0 auto;

          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 24px;

          border-bottom: 1px solid $grayD0D;
          background-color: $grayF9F;
        }
        .tab-item {
          padding: 16px 0;
          font-size: convert-rem(24);
          font-weight: 700;
          line-height: 32px;

          color: $black475;
          cursor: pointer;
          position: relative;

          &:before {
            content: '';
            width: 0;
            height: 3px;

            position: absolute;
            bottom: 0;
            left: 0;

            background-color: $black000;
            transition: width ease-in-out 0.6s;
          }
          &:hover:before {
            width: 100%;
          }
          &.active:before {
            width: 100%;
          }
        }
        @media screen and (max-width: 1279px) {
          &.sticky .tab-wrap {
            padding: 0;
          }
        }

        @media screen and (max-width: 992px) {
          .tab-item {
            font-size: convert-rem(18);
            line-height: 27px;
          }
        }
      }
      &__about {
        padding-bottom: 32px;
        .about-description {
          padding: 32px 0;
          font-size: convert-rem(18);
          line-height: 28px;
          color: $black667;

          @media screen and (max-width: 767px) {
            font-size: convert-rem(16);
            line-height: 24px;
          }
        }
        .about-more {
          &.grid-template {
            display: grid;
            grid-template-columns: repeat(3, minmax(calc(33.33% - 32px), 1fr));
            grid-template-areas:
              'item1 item2 item2'
              'item1 item3 item3';
            gap: 32px;
          }
          &.flexbox-template {
            display: flex;
            align-items: flex-start;
            gap: 32px;
            .details,
            .dietary-status,
            .facilities {
              width: calc(50% - 32px);
            }
          }
          &__block {
            &.details {
              grid-area: item1;
            }
            &.dietary-status {
              grid-area: item2;
            }
            &.facilities {
              grid-area: item3;
            }
            .venue-title {
              padding: 4px 16px;
              margin-bottom: 16px;
            }
            .detail-content {
              font-weight: 600;
              &.text-capitalize {
                text-transform: capitalize;
              }
            }
          }
        }
      }
      &__other-venues,
      &__also-like,
      &__deals {
        overflow: hidden;
        .venue-title {
          padding: 16px 0;
          margin-bottom: 8px;
        }
      }
      &__deals {
        border-bottom: 0;
        .venue-title {
          margin-bottom: 24px;
        }
      }
      &__reviews {
        padding: 32px 24px;
        .venue-title {
          padding: 16px 0;
          margin-bottom: 8px;
          display: flex;
          align-items: center;
          column-gap: 6px;
        }
        .review-action {
          margin-bottom: 32px;
          .review-btn {
            @include primary-white-button();
          }
        }
        .review-rates {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $black475;

          column-gap: 24px;
          margin-bottom: 32px;
          .rating-summary {
            display: flex;
            align-items: center;
            column-gap: 24px;
            flex: 1 0 34%;
            .vertical-line {
              width: 3px;
              height: 24px;
              background-color: $black475;
            }
            .total-points {
              font-size: convert-rem(24);
              font-weight: 700;
              line-height: 32px;
            }
          }
          .rating-filter {
            flex: 1 0 34%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            column-gap: 16px;
            .filter-type {
              font-size: convert-rem(20);
              font-weight: 400;
              line-height: 30px;
            }
            .filter-selection {
              flex-shrink: 0;
              min-width: 182px;
              max-width: 200px;
              width: 100%;
            }
          }
        }

        .review-list {
          .reviews {
            display: flex;
            flex-direction: column;
            row-gap: 32px;
            padding: 20px;
            border: 1px solid #d3d3d3;
            border-radius: 8px;
            background-color: #fff;
            .review {
              display: flex;
              column-gap: 18px;
              &-user-avatar {
                position: relative;
                width: 96px;
                height: 96px;
                flex-shrink: 0;

                border-radius: 50%;
                overflow: hidden;
              }
              &-in-detail {
                flex-grow: 1;
              }
              &-top {
                display: flex;
                justify-content: space-between;
              }
              &-user-rating {
                display: flex;
                flex-direction: column;
                row-gap: 8px;

                margin-bottom: 12px;
                .user-name {
                  font-size: convert-rem(24);
                  font-weight: 700;
                  line-height: 32px;
                  color: $black475;
                }
                .date-visited {
                  color: $gray98A;
                  font-size: convert-rem(20);
                  line-height: 30px;
                }
              }
              &-description {
                color: $black667;
                font-size: convert-rem(18);
                line-height: 28px;
                margin-bottom: 24px;
              }
              &-images {
                display: flex;
                align-items: center;
                column-gap: 12px;
                margin-bottom: 24px;
                .image {
                  position: relative;
                  width: 92px;
                  height: 92px;
                  overflow: hidden;
                  cursor: pointer;
                  img {
                    border-radius: 6px;
                  }
                }
              }

              .flag-btn,
              .upvote-btn {
                @include primary-white-button();
              }
              .button-reply {
                background: none;
                border: none;
                color: #007bff;
                font-size: 16px;
                cursor: pointer;
                padding: 0;

                &:hover {
                  color: #0056b3;
                  text-decoration: underline;
                }

                &:focus {
                  outline: none;
                }

                display: flex;
                align-items: center;
                gap: 8px;
              }
            }
          }
        }

        @media screen and (max-width: 767px) {
          .review-rates {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 16px;

            .rating-summary {
              flex: 1;
              width: 100%;
              .total-points {
                font-size: convert-rem(20);
                line-height: 28px;
              }
              .vertical-line {
                height: 20px;
              }
              .total-rates {
                .total-stars svg {
                  width: 20px;
                  height: 20px;
                }
                .total-reviews {
                  font-size: convert-rem(18);
                  line-height: 26px;
                }
              }
            }
            .rating-filter {
              width: 100%;
              flex: 1;
              justify-content: flex-start;
              .filter-type {
                font-size: convert-rem(18);
                line-height: 26px;
              }
            }
          }
          .review-list .reviews {
            .review-user-avatar {
              width: 62px;
              height: 62px;
            }
            .review-in-detail {
              .review-user-rating {
                row-gap: 4px;
              }

              .user-name {
                font-size: convert-rem(18);
                line-height: 26px;
              }
              .review-stars svg {
                width: 20px;
                height: 20px;
              }
              .date-visited {
                font-size: convert-rem(14);
                line-height: 20px;
              }
              .review-description {
                font-size: convert-rem(14);
                line-height: 20px;
                margin-bottom: 16px;
              }
              .review-images {
                margin-bottom: 16px;
              }
            }
          }
        }
      }
      &__also-like {
        border: 0;
        padding-bottom: 48px;
      }

      @media screen and (max-width: 1023px) {
        &__about {
          .about-more {
            &.grid-template {
              grid-template-areas:
                'item1'
                'item2'
                'item3';
              grid-template-columns: 1fr;
            }
            &.flexbox-template {
              flex-direction: column;
              .details,
              .dietary-status,
              .facilities {
                width: 100%;
              }
            }
          }
        }
      }

      // @media screen and (max-width: 767px) {
      //   .block-wrap {
      //     padding: 24px 0;
      //   }
      // }
    }
    .contact {
      display: flex;
      align-items: flex-start;
      column-gap: 12px;
      font-weight: 600;
      margin-bottom: 12px;

      .icon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin-top: 4px;

        position: relative;
      }
      .text {
        word-break: break-word;
      }

      .opening-hours {
        flex-grow: 1;
        .title {
          margin-bottom: 12px;
        }
        .hour {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          column-gap: 16px;
          padding-left: 16px;
          font-size: convert-rem(14);
          line-height: 20px;
          .name {
            width: 92px;
            flex-shrink: 0;
          }
          .time {
            flex-grow: 1;
            word-break: keep-all;
          }
        }
      }
    }
    .venue-title {
      font-size: convert-rem(24);
      font-weight: 700;
      line-height: 32px;
      text-transform: capitalize;

      color: $black475;
      &.has-border {
        border-left: 3px solid $black000;
      }

      @media screen and (max-width: 992px) {
        font-size: convert-rem(18);
        line-height: 27px;
      }
    }
    .negative-margin-x {
      margin: 0 -24px;
    }
  }
}

.listing-review-page {
  .listing-review-blocks {
    padding: 48px 0;
    display: flex;
    column-gap: 56px;

    .block-listing-info {
      width: 490px;
      max-width: 48%;
      flex-shrink: 0;

      &.sticky {
        position: sticky;
        top: 48px;
        left: 0;
        align-self: flex-start;
      }
      .title {
        color: $black1D2;
        font-size: convert-rem(36);
        font-weight: 700;
        line-height: 44px;
        letter-spacing: -0.72px;

        margin-bottom: 16px;
        &--color-green {
          color: $cyan00B;
        }
      }
      .description {
        color: $black1D2;
        font-size: convert-rem(20);
        font-weight: 600;
        line-height: 30px;

        margin-bottom: 42px;
      }
      .listing {
        &-images {
          position: relative;
          margin-bottom: 32px;
          .image-carousel {
            .rec-pagination {
              display: none;
            }
            .rec-slider-container {
              margin: 0;
            }
            .rec-carousel {
              position: relative;
              .arrow-btn {
                width: 24px;
                height: 24px;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                border-radius: 50%;
                background-color: $whiteFFF;
                border: 0;
                box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                svg {
                  width: 16px;
                  height: 16px;
                }
                &.hidden {
                  display: none;
                }
                &.show {
                  display: flex;
                }
                &.arrow-right-btn {
                  right: -24px;
                }
              }
            }
          }
          .image {
            width: 100%;
            height: 306px;
            position: relative;
            img {
              border-radius: 14px;
            }
          }
        }
        &-name {
          color: $black101;
          font-size: convert-rem(24);
          font-weight: 700;
          line-height: 32px;
          margin-bottom: 12px;
        }
      }
    }
    .block-listing-review {
      flex-grow: 1;
      padding: 0px 16px 72px;
      border-left: 1px solid $grayD0D;
      padding-left: 56px;

      .review-form {
        display: flex;
        flex-direction: column;
        row-gap: 54px;

        .form-item {
          &__label {
            color: $black1D2;
            font-size: convert-rem(24);
            font-weight: 700;
            line-height: 32px;

            margin-bottom: 16px;
          }
          &__field {
            &.selection {
              min-width: 182px;
              max-width: 320px;
              width: 100%;

              .review-filter__value-container {
                font-size: convert-rem(16);
              }
            }
            .textarea-custom {
              width: 100%;
              height: 230px;
              padding: 10px;

              border-radius: 8px;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              border-color: $grayD0D;
              resize: none;

              line-height: 24px;
              color: $black101;
              &::placeholder {
                color: $black667;
              }
              &:focus-visible {
                outline: none;
                border-color: $green00A;
              }
            }
            .file-inputting {
              width: 100%;
              height: 209px;

              position: relative;
              border: 1px solid $grayD0D;
              border-radius: 8px;
              overflow: hidden;
              background-color: $whiteFFF;

              &.file-disabled {
                .input-file-custom {
                  cursor: no-drop;
                }
                .file-content {
                  opacity: 0.6;
                }
              }

              input[type='file'] {
                color: transparent;
              }
              .input-file-custom {
                width: 100%;
                height: 100%;
                border: 0;
                cursor: pointer;

                position: relative;
                z-index: 9;
                &::-webkit-file-upload-button {
                  display: none;
                }

                &:focus-visible {
                  border-color: $green00A;
                }
              }
              .file-content {
                position: absolute;
                top: 50%;
                left: 50%;

                transform: translate(-50%, -50%);

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 1;

                .file-icon {
                  position: relative;
                  width: 42px;
                  height: 42px;
                }
                .file-text {
                  color: $black1D2;
                  font-size: convert-rem(20);
                  font-weight: 600;
                  line-height: 30px;
                  text-align: center;

                  margin-top: 8px;
                  white-space: nowrap;
                }
              }
            }
            .accept-certification {
              display: flex;
              column-gap: 8px;
              .field-description {
                p {
                  font-size: convert-rem(14);
                  font-weight: 600;
                  line-height: 20px;
                }
              }
            }
          }
          &__images {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            column-gap: 8px;

            .image-uploaded {
              position: relative;
              .image {
                width: 104px;
                height: 104px;
                position: relative;
                img {
                  border-radius: 8px;
                }
              }
              .remove {
                position: absolute;
                top: 8px;
                right: 8px;

                &-btn {
                  width: 24px;
                  height: 24px;

                  border-radius: 50%;
                  background-color: $whiteFFF;
                  border: 0;

                  display: flex;
                  align-items: center;
                  justify-content: center;

                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
            }
            .image-waiting-upload {
              width: 104px;
              height: 104px;

              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $whiteFFF;

              border-radius: 8px;
              overflow: hidden;
              border: 1px solid $grayD0D;

              .icon {
                position: relative;
                width: 32px;
                height: 32px;
              }
            }
          }

          .submit-btn {
            @include primary-green-button();
            width: 100%;
          }
        }
      }
      .review-more {
        &__title {
          color: $black1D2;
          font-size: convert-rem(36);
          font-weight: 700;
          line-height: 44px;
          letter-spacing: -0.72px;
          margin-bottom: 16px;
        }
        &__search {
          background-color: $whiteFFF;
          border: 1px solid $grayD0D;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 8px;

          padding: 12px 14px;
          overflow: hidden;
          margin-bottom: 44px;

          .search-wrap {
            display: flex;
            align-items: center;
            .search-icon {
              position: relative;
              width: 20px;
              height: 20px;
              flex-shrink: 0;

              margin-right: 8px;
            }
            .input-field {
              flex-grow: 1;
              padding: 0;
              margin: 0;
              border: 0;
              outline: none;
              color: $black101;
              &:focus-visible {
                border: 0;
                box-shadow: none;
              }
              &::placeholder {
                color: $black667;
                line-height: 24px;
              }
            }
          }
        }
        &__venues {
          .note {
            color: $black1D2;
            font-size: convert-rem(24);
            font-weight: 700;
            line-height: 32px;

            margin-bottom: 16px;
          }
          .venues {
            display: flex;
            flex-direction: column;
            row-gap: 16px;

            .item {
              display: flex;
              align-items: stretch;
              column-gap: 24px;

              padding: 12px;
              cursor: pointer;

              border-radius: 6px;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

              .venue-image {
                width: 144px;
                height: auto;
                flex-shrink: 0;

                overflow: hidden;
                position: relative;
                img {
                  transition: all ease 0.6s;
                  border-radius: 6px;
                }
              }
              .venue-info {
                flex-grow: 1;
                color: $black667;
                font-size: 14px;
                line-height: 20px;
                .venue-name {
                  color: $black101;
                  font-size: convert-rem(16);
                  font-weight: 700;
                  line-height: 24px;
                  margin-bottom: 4px;
                }
                .venue-rates {
                  display: flex;
                  align-items: center;
                  column-gap: 4px;
                  margin-bottom: 4px;

                  .total-stars {
                    display: flex;
                    align-items: center;
                    column-gap: 4px;
                    svg {
                      width: 16px;
                      height: 16px;
                    }
                  }
                }
              }

              &:hover {
                .venue-image img {
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1279px) {
      .block-listing-review {
        padding-right: 0;
      }
    }

    @media screen and (max-width: 991px) {
      column-gap: 24px;
      .block-listing-info {
        .title {
          font-size: convert-rem(32);
        }
        .description {
          font-size: convert-rem(18);
        }
        .listing {
          &-image {
            .image {
              width: 100%;
              height: 250px;
            }
          }
          &-name {
            font-size: convert-rem(20);
            line-height: 30px;
          }
        }
      }
      .block-listing-review {
        padding-left: 24px;
        .review-form {
          row-gap: 32px;
          .form-item {
            &__label {
              font-size: convert-rem(20);
              line-height: 30px;
            }
            &__field {
              .file-inputting .file-content .file-text {
                font-size: convert-rem(16);
                line-height: 24px;
              }
            }
          }
        }
        .review-more {
          &__title {
            font-size: convert-rem(32);
            line-height: 44px;
          }
          &__venues {
            .note {
              font-size: convert-rem(20);
              line-height: 30px;
            }
            .venues .item {
              column-gap: 12px;
              .venue-image {
                width: 102px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
      row-gap: 32px;
      .block-listing-info {
        width: 100%;
        max-width: 100%;

        &.sticky {
          position: static;
        }
      }
      .block-listing-review {
        padding: 32px 0 32px;
        border-top: 1px solid $grayD0D;
        border-left: 0;

        .review-form .form-item {
          &__field.selection {
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.listing-detail-page,
.listing-review-page {
  scroll-snap-type: mandatory;
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  background-color: $grayF9F;
  color: $black344;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Nunito Sans', sans-serif;
    color: $black101;
  }
  .btn {
    position: relative;
  }

  .rating {
    display: flex;
    align-items: center;
    column-gap: 8px;
    .total-stars {
      display: flex;
      align-items: center;
      column-gap: 6px;
    }
    .total-reviews {
      color: $black212;
      font-size: convert-rem(20);
      font-weight: 600;
      line-height: 34px;
    }
  }

  a {
    color: unset;
    &:hover {
      text-decoration: none;
      color: $green009;
    }
  }
}
.upvote-reply {
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.modal-reply {
  overflow-y: auto;
  .review-list {
    .reviews {
      display: flex;
      flex-direction: column;
      row-gap: 32px;

      .review {
        display: flex;
        column-gap: 18px;
        &-user-avatar {
          position: relative;
          width: 90px;
          height: 90px;
          flex-shrink: 0;

          border-radius: 50%;
          overflow: hidden;
          @media (max-width: 768px) {
            width: 50px;
            height: 50px;
          }
        }
        &-in-detail {
          flex-grow: 1;
        }
        &-top {
          display: flex;
          justify-content: space-between;
        }
        &-user-rating {
          display: flex;
          flex-direction: column;
          row-gap: 8px;

          margin-bottom: 12px;
          .text-owner {
            color: #009294;
            font-size: 16px;
          }
          .row-name-owner {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }
          .verified-boder {
            padding: 4px;

            border-radius: 12px;
            background-color: #eff8ff;
            color: #175cd3;
          }
          .verified {
            font-size: 12;
          }
          .user-name {
            font-size: convert-rem(24);
            font-weight: 700;
            line-height: 32px;
            color: $black475;
          }
          .date-visited {
            color: $gray98A;
            font-size: convert-rem(20);
            line-height: 30px;
            @media (max-width: 768px) {
              color: $gray98A;
              font-size: convert-rem(10);
              line-height: 10px;
            }
          }
        }
        &-description {
          color: $black667;
          font-size: convert-rem(18);
          line-height: 28px;
          margin-bottom: 24px;
        }
        &-images {
          display: flex;
          align-items: center;
          column-gap: 12px;
          margin-bottom: 24px;
          .image {
            position: relative;
            width: 92px;
            height: 92px;
            overflow: hidden;
            cursor: pointer;
            img {
              border-radius: 6px;
            }
          }
        }
        .rating {
          display: flex;
          align-items: center;
          column-gap: 8px;
          .total-stars {
            display: flex;
            align-items: center;
            column-gap: 6px;
            @media (max-width: 768px) {
              display: flex;
              align-items: center;
              column-gap: 2px;
            }
          }
          .total-reviews {
            color: $black212;
            font-size: convert-rem(20);
            font-weight: 600;
            line-height: 34px;
          }
        }

        .flag-btn,
        .upvote-btn {
          @include primary-white-button();
        }
        .button-reply {
          background: none;
          border: none;
          color: #007bff;
          font-size: 16px;
          cursor: pointer;
          padding: 0;

          &:hover {
            color: #0056b3;
            text-decoration: underline;
          }

          &:focus {
            outline: none;
          }

          display: flex;
          align-items: center;
          gap: 8px;
        }
        .owner-reply-input {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
          margin-top: 16px;

          .reply-textarea {
            width: 100%; /* Full width of the container */
            min-height: 100px; /* Ensure it has a reasonable height */
            padding: 12px; /* Add padding for better usability */
            border: 1px solid #ccc; /* Border styling */
            border-radius: 6px; /* Rounded corners */
            font-size: 16px; /* Comfortable font size */
            color: #333; /* Text color */
            resize: vertical; /* Allow resizing only vertically */
            overflow: auto; /* Ensure scrolling works for large text */
            box-sizing: border-box;
          }

          .on-button {
            align-self: flex-end;
            display: flex;
            gap: 10px;
            .button-right {
              align-self: flex-end;
              padding: 10px 20px;
              background-color: #00a7ad;
              color: #fff;
              border: none;

              border-radius: 6px;
              cursor: pointer;
              font-size: 16px;

              &:hover {
                background-color: #0056b3;
              }
            }
            .button-left {
              align-self: flex-start;
              padding: 10px 20px;
              padding-right: 30px;
              background-color: #fff;
              color: #000000;
              border: 1px solid #000000;
              border-radius: 8px;
              cursor: pointer;
              font-size: 16px;

              &:hover {
                background-color: #0056b3;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    /* Adjust styles for mobile screens */
    max-height: 70vh; /* Further reduce height for small screens */
    overflow-y: auto;
    padding: 12px; /* Add some padding for better spacing */
  }
}
