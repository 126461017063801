* {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
  }
  ul,
  ol {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  body {
    font: convert-rem(16) "Open Sans", sans-serif;
    line-height: 24px;
    color: $black333;

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      transition: all 150ms;
      border-radius: 0;
      background-color: #f1f6f7;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 16px;
      background-color: $grayC7C;
      padding: 0 4px;
    }
  }

  .form-control:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }
  a,
  button {
    cursor: pointer;
  }
  button:focus,
  a {
    color: unset;
    &:hover {
      text-decoration: none;
      color: unset;
    }
    &:focus {
      outline: none !important;
    }
  }
  img.white {
    filter: brightness(0) invert(1);
    -moz-filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
  }
}
