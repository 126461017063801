.drawer {
  padding: 16px 20px;
  background: #f9fafb !important;
  height: auto !important;
  max-height: calc(100vh - 64px) !important;

  &__header {
    @include flex_center(center, space-between);
    font-size: convert-rem(16);
    color: $black101;

    &__title {
      font-size: convert-rem(16);
      color: $black101;
      display: flex;
      align-items: center;

      span {
        margin-left: 8px;
      }
    }
  }

  &__content {
    height: calc(100% - 48px);
    margin-top: 16px;
    font-size: convert-rem(14);
    color: $black101;
    background: $whiteFFF;
    border-radius: 8px;
    padding: 16px;

    .jump-list {
      max-height: 288px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: $black344;
        border-radius: 4px;
        border-radius: 2010px;
      }

      &::-webkit-scrollbar-track {
        background: #eaecf0 !important;
        border-radius: 2010px;
      }
    }

    li {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: block;
      font-size: convert-rem(14);
      color: $black101;
      text-decoration: none;

      &.active {
        color: $cyan00CB;
        font-weight: 500;
      }
    }
    a.w-bullet[class*="h3-"] {
      &::before {
        content: "•";
        color: $black101;
        margin-right: 8px;
      }
    }
  }

  p,
  a {
    font-size: convert-rem(14);
    color: $black101;
  }
}
