.import-google-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d0d5dd;
  border-radius: 10px;
  padding-top: 20px;
  padding: 10px 15px;
  background-color: #fff;
  width: 99%;
}

.left-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.google-icon {
  width: 24px;
  height: 24px;
}

.text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.import-button {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  background-color: #fff; /* Màu xanh cho nút */
  color: #009294; /* Màu chữ trắng */
  border: 1px solid #009294;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.import-button:hover {
  background-color: #0056b3; /* Màu xanh đậm khi hover */
}

@media screen and (max-width: 600px) {
  .import-google-container {
    // flex-direction: column;
    align-items: center;
  }

  .left-content {
    // flex-direction: column;
    // align-items: center;
    // gap: 5px;
    // display: none;
    span {
      display: none;
    }
  }

  .text {
    font-size: 16px;
  }

  .import-button {
    padding: 6px 12px;
    font-size: 12px;
  }
  
}
