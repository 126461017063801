.hero-sec {
  overflow: hidden;
  padding: 80px 0;
  position: relative;
  margin-bottom: 32px;

  .container {
    @media screen and (min-width: 768px) {
      max-width: 992px;
    }

    @media screen and (min-width: 992px) {
      max-width: 1200px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1440px;
    }
  }

  @include laptop_under {
    padding: 32px 0;
  }

  @include small-tablet_under {
    padding: 32px 0 0px;
  }

  .bg-overlay {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $cyanBFA;

    @include small-tablet_under {
      height: 60%;
    }
  }

  .hero-content {
    min-height: 344px;
    max-height: 550px;
    position: relative;
    justify-content: space-between;
    z-index: 2;

    @media screen and (min-width: 768px) and (max-width: 992px) {
      max-height: 410px;
    }

    @include tablet_under {
      min-height: 130px;
    }

    @include small-tablet_under {
      max-height: unset;
    }

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    &__title {
      order: 1;
      width: 50%;

      @include small-tablet_under {
        width: 100%;
      }

      h1 {
        font-size: convert-rem(48);
        color: $black101;
        line-height: 58px;
        margin-bottom: 0;
        display: inline-block;

        @include desktop_under {
          font-size: convert-rem(36);
          line-height: 46px;
        }

        @include small-tablet_under {
          font-size: convert-rem(28);
          line-height: 36px;
        }

        @include mobile {
          font-size: convert-rem(24);
          line-height: 32px;
        }
      }
    }

    &__title.min-h {
      min-height: 190px;

      @include tablet_under {
        min-height: 140px;
      }

      @include small-tablet_under {
        min-height: unset;
      }
    }

    &__line {
      order: 3;
      height: 1px;
      width: 50%;
      background: rgba(208, 213, 221, 0.3);

      @media screen and (min-width: 768px) and (max-width: 992px) {
        margin: 12px 0 8px;
      }

      @include small-tablet_under {
        display: none;
      }
    }

    &__category {
      order: 2;
      width: 50%;

      @include small-tablet_under {
        width: 100%;
      }

      margin-top: 24px;
      gap: 16px;
      row-gap: 10px;
      @include flex_center(center, flex-start);
      flex-wrap: wrap;

      @include small-tablet_under {
        margin: 20px 0 20px;
      }

      p {
        margin-bottom: 0;
        color: $black475;
        font-size: convert-rem(16);
      }

      .category__links {
        display: flex;
        flex-wrap: wrap;
        row-gap: 14px;

        a {
          color: $black563;
          outline: none;
          background: $whiteFFF;
          border-radius: 8px;
          border: 1px solid $grayD0D;
          font-family: "Open Sans", sans-serif;
          padding: 4px 8px;
          font-size: convert-rem(14);
          line-height: 24px;
          white-space: nowrap;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            background: $green7AD;
            border-color: $green7AD;
            color: $whiteFFF;
            transition: all 0.3s;
          }
        }
      }

      > div {
        @include flex_center(center, center);
        gap: 12px;
      }
    }

    &__avatar-social {
      order: 3;
      width: 50%;

      @include small-tablet_under {
        width: 100%;
      }

      @include flex_center(center, space-between);

      @include small-tablet_under {
        order: 4;
      }

      .hero-avatar {
        margin-right: 16px;

        .avatar-img {
          width: 46px;
          height: 46px;

          @include desktop_under {
            width: 36px;
            height: 36px;
          }
        }

        a {
          color: $black101;
          font-size: convert-rem(16);
        }

        @media screen and (min-width: 768px) and (max-width: 992px) {
          p {
            padding-left: 4px;
          }
        }
      }

      .hero-social {
        .btn-favorite {
          width: max-content;
          padding: 8px 12px;
          height: 36px;
          color: $green7AD;
          border-color: $green7AD;
          font-weight: 500;
          gap: 8px;
          background: transparent;
        }
      }
    }

    &__img {
      order: 4;
      width: 42%;
      margin-left: 8%;
      height: 344px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;

      @include desktop_under {
        width: 46%;
        margin-left: 4%;
      }

      @include tablet_under {
        height: 276px;
      }

      @include small-tablet_under {
        margin-left: 0;
        width: 100%;
        height: 196px;
        order: 3;
        margin-bottom: 16px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.menu-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.55);
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  display: none;
}
