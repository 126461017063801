@mixin primary-green-button {
  border: 1px solid $green00A;
  border-radius: 8px;
  overflow: hidden;

  background-color: $green00A;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  text-align: center;

  color: $whiteFFF;
  font-weight: 700;
  line-height: 24px;

  &.has-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    .icon {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .loading {
      position: relative;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }
  &.no-border {
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    border: #fff;
    color: #009294;
    font-size: 16px;
    background-color: transparent;
    .icon {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 100%;
        height: 100%;
      }
    }
    .loading {
      position: relative;
      flex-shrink: 0;
      width: 24px;
      height: 24px;
    }
  }
  &:hover:not(:disabled) {
    opacity: 0.8;
  }
  &:disabled {
    cursor: no-drop;
    opacity: 0.6;
  }

  @media screen and (max-width: 767px) {
    font-size: convert-rem(14);
    line-height: 22px;
  }
}

@mixin secondary-green-button {
  @include primary-green-button();
  border: 1px solid $greenDCF;
  background-color: $greenDCF;
  color: $green009;
}

@mixin primary-gray-button {
  @include primary-green-button();
  border: 1px solid $grayD0D;
  background-color: $grayF9F;
  color: $black1D2;
}

@mixin primary-white-button {
  @include primary-gray-button();
  background-color: $whiteFFF;
  color: $black344;
}

@mixin primary-red-button {
  @include secondary-green-button();
  background-color: $redE60;
  border: 1px solid $redE60;
  color: $redFDE;
}

@mixin secondary-red-button {
  @include secondary-green-button();
  background-color: $redFDE;
  border: 1px solid $redFDE;
  color: $redE60;
}

@mixin flex_center($align: center, $justify: unset) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

$small-mobile: 390px;
$mobile-width: 480px;
$small-tablet-width: 768px;
$tablet-width: 992px;
$laptop: 1024px;
$desktop: 1200px;

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin small-tablet_under {
  @media (max-width: #{$small-tablet-width}) {
    @content;
  }
}

@mixin tablet_under {
  @media (max-width: #{$tablet-width}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$tablet-width}) and (max-width: #{$laptop - 1px}) {
    @content;
  }
}

@mixin laptop_under {
  @media (max-width: #{$laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$laptop}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin desktop_under {
  @media (max-width: #{$desktop}) {
    @content;
  }
}
