.not-found-page,
.exit-page {
  overflow: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: convert-rem(72);
      margin: 16px 0;
    }
    a {
      display: block;
      padding: 12px 24px;
      background-color: $cyan0CC;
      color: $whiteFFF;
      border-radius: 4px;

      &:hover {
        opacity: 0.8;
        color: $whiteFFF;
        text-decoration: none;
      }
    }
  }
}
