.float-hdr {
  padding: 12px 0;
  position: fixed;
  z-index: 999;
  width: 100%;
  background: $whiteFFF;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  display: none;

  .reading-progressbar {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    accent-color: $cyan00C !important;
    background-color: #f2f5fb;
    pointer-events: none;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition:
      transform 0.2s,
      -webkit-transform 0.2s;
  }

  // .reading-progressbar--is-out {
  //   -webkit-transform: translateY(-100%);
  //   transform: translateY(-100%);
  // }

  .reading-progressbar--is-active {
    @include small-tablet_under {
      display: block;
    }
  }

  .reading-progressbar::-webkit-progress-bar {
    background-color: #f2f5fb;
  }

  .reading-progressbar::-webkit-progress-value {
    background-color: $cyan00B;
  }

  .reading-progressbar::-moz-progress-bar {
    background-color: $cyan00B;
  }

  .reading-progressbar__fallback {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: currentColor;
  }

  /* utility classes */
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include small-tablet_under {
      max-width: 100%;
    }

    @media screen and (min-width: 768px) {
      max-width: 992px;
    }

    @media screen and (min-width: 992px) {
      max-width: 1200px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1440px;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 8px 5px;

    .nav-brand {
      display: none !important;
    }

    .hero-social {
      display: flex;
    }
  }

  .nav-title {
    display: block;
    font-weight: 700;
    padding-right: 8px;
    flex: 3;

    a {
      font-size: convert-rem(12);
      color: $green7AD;

      @media screen and (max-width: 767px) {
        font-size: convert-rem(10);
      }
    }

    p {
      font-size: convert-rem(18);
      margin-bottom: 0;
      //css max lines 2
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @media screen and (max-width: 767px) {
        font-size: convert-rem(14);
      }
    }
  }
}

.hero-social {
  @include flex_center(center, flex-end);

  @include tablet_under {
    justify-content: flex-start;
    row-gap: 8px;
  }

  @include mobile {
    flex: 1;
    justify-content: flex-end;
  }

  .react-share__ShareButton {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }

  .ss-btn {
    width: 40px;
    height: 40px;
    background: $black475;
    color: $whiteFFF;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;

    &.link {
      margin-right: 10px;
      img {
        filter: brightness(0) invert(1);
        -moz-filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
        margin-left: 1px;
      }
      &:hover img {
        filter: brightness(1) invert(0);
        -moz-filter: brightness(1) invert(0);
        -webkit-filter: brightness(1) invert(0);
      }
    }

    svg {
      width: 14px;
      height: 14px;
    }

    &:hover {
      text-decoration: none;
      border: 1px solid $grayD8D;
      background-color: $whiteFFF;
      color: $cyan0CC;

      svg {
        * {
          stroke: $cyan0CC;
          fill: $cyan0CC;
        }
      }
    }
  }

  .btn {
    @include flex_center(center, center);
    border: 1px solid $green7AD;
    width: auto;
    height: 36px;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    color: $green7AD;
    background-color: $whiteFFF;

    @media screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
      padding: 8px;
    }

    &.btn-view-as-map {
      margin-left: 12px;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &.btn-save {
      span {
        margin-left: 8px;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    &.btn-share {
      display: none;
      @media screen and (max-width: 767px) {
        display: flex;
      }
    }

    &.active-btn .fa-heart-o:before {
      content: "\f004";
    }

    &:last-child {
      margin-left: 10px;
    }
  }

  .save-btn {
    margin-right: 5px;
    border-right: 1px solid rgba(208, 213, 221, 0.3);

    .ss-btn {
      margin-right: 5px;
    }
  }
}
