$bg-color: #f2f4f7;

.pfr_container {
  background-color: $bg-color;
  padding: 20px;
  padding-bottom: 100px;
  // height: 100vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(248, 177, 177, 0.1);
  position: relative;
  &__redeemed-btn-sticky {
    display: none;
  }
  .pfr_content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 200px;
    .pfr_content__full {
      .pfr_content__date-location__mobile {
        display: none;
      }
      display: flex;
      flex-direction: column;
      gap: 20px;
      .pfr_content__box {
        border-radius: 12px;
        display: flex;
        padding: 14px 24px;
        border: 1px solid #e5e7eb;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &.pfr_content_category {
          gap: 20px;
          align-items: center;
          align-content: center;
          h5 {
            margin: 0;
            padding: 2px 10px;
          }
          span {
            padding: 2px 10px;
            background-color: #f2f4f7;
            border-radius: 15px;
            align-items: center;
          }
        }
        &.pfr_content_description {
          h6,
          h5 {
            margin: 0;
          }
          flex-direction: column;
          .pfr-content__terms {
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              color: #00a7ad;
              font-weight: 600;
              cursor: pointer;
            }
          }
          & > div:last-child {
            hr {
              height: 1px;
              margin: 0;
              margin-top: 10px;
              margin-bottom: 10px;
            }
            ol {
              margin-top: 15px;
              padding-left: 20px;
              list-style: decimal;
              li {
                color: #475467;
                span {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      .pfr_content__image {
        position: relative;
        border-radius: 15px;
        padding: 5px;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 350px;
        overflow: hidden;
        width: 100%;
      }
      background-color: white;
      padding: 25px;
      width: 800px;
      flex-shrink: 0;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 15px;
    }
    .pfr_content__redeemed {
      padding: 24px;
      background-color: white;
      width: 500px;
      border-radius: 15px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &__tier {
        margin-bottom: 25px;
        span {
          padding: 5px 10px;
          color: #027a48;
          background-color: #ecfdf3;
          border-radius: 15px;
        }
      }
      &__date-and-location {
        display: flex;
        flex-direction: column;
        gap: 10px;
        div {
          display: flex;
          gap: 10px;
          span {
            font-weight: 600;
          }
        }
      }
      button {
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid #00a7ad;
        color: white;
        background-color: #00a7ad;
        width: 100%;
        &:hover {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        &:active {
          transform: scale(0.95);
          transition: all 0.1s ease-in-out;
        }
        &:disabled {
          background-color: #d0d5dd;
          border: 1px solid #d0d5dd;
          transition: none;
          transform: none;
        }
      }
    }
  }
  &__reward {
    // padding: 0 80px;
    padding: 0;
    padding-left: 80px;
    padding-right: 50px;
    padding-bottom: 15px;
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 25px;
    .pfr_reward__filter {
      padding: 24px;
      background-color: white;
      border-radius: 10px;
    }
    .pfr_filter {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      label {
        margin-left: 5px;
      }
    }
    .pfr_reward__items {
      background-color: white;
      padding: 24px;
      border-radius: 10px;
      // width: 1400px;
      &__view_more {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        button {
          padding: 10px 20px;
          border-radius: 10px;
          border: 1px solid #00a7ad;
          background-color: white;
          color: #00a7ad;
          &:hover {
            background-color: #00a7ad;
            color: white;
          }
        }
      }
      &__head {
        padding-inline: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          display: none;
        }
        select {
          width: 100px;
          padding: 5px 10px;
          border-radius: 5px;
          border: 1px solid #e5e7eb;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      &__contain {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-evenly;
        display: grid;
        grid-template-columns: repeat(2, minmax(390px, 1fr));
        gap: 50px;
        margin-top: 20px;
        margin-bottom: 20px;
        padding: 0 50px;
        &__ad {
          grid-column: span 2;
          background-image: url('../../../public/images/ads-temp.png');
          background-size: contain;
          width: 100%;
          background-repeat: no-repeat;
          background-position: center;
          height: 200px;
        }
        .pfr_reward__item {
          & > div:last-child {
            height: calc(100% - 250px);
          }
          margin-top: 20px;
          width: 390px;
          height: 450px;
          border: 1px solid #e5e7eb;
          border-radius: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          // transform: translate(-50%);
          // box-sizing: border-box;
          margin-left: 75px;
          &:hover {
            cursor: pointer;
            // padding: 20px;
            transform: scale(1.05);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
          }
          &__image {
            width: 100%;
            height: 250px;
            position: relative;
          }
        }
      }
    }
  }
  .pfr_redeemed {
    background-color: white;
    padding: 15px 50px;
    // width: 1600px;
    margin-left: 80px;
    border-radius: 10px;
    margin-bottom: 50px;
    &.pfr_not-login {
      // width: 100%;
      height: 400px;
      // margin-left: 120px;
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: 10px;
      margin-bottom: 20px;
      h1 {
        display: block;
        padding: 15px;
        font-size: 4.1rem;
        color: white;
        margin-top: 20px;
      }
      & > span {
        display: block;
        padding: 0 15px;
        font-size: 1.2rem;
        color: white;
      }
      button {
        padding: 10px 18px;
        margin-left: 15px;
        margin-top: 20px;
        border-radius: 10px;
        border: 1px solid white;
        span {
          font-size: 1.2rem;
          color: #00a7ad;
        }
      }
    }
    &__reward {
      background-color: #f9fafb;
      border-radius: 10px;
      padding: 20px;
      overflow-x: auto;
      box-sizing: content-box;
    }
    &__empty {
      // display: flex;
      flex-direction: column;
    }
    &__description,
    &__title {
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-weight: 600;
      }
      button {
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid gray;
        background-color: white;
        color: #101828;
        text-align: center;
        &:hover {
          background-color: #00a7ad;
          color: white;
        }
        .pfr_history_icon {
          margin-bottom: 4px;
          margin-right: 5px;
        }
      }
    }
    &__description {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        display: flex;
        gap: 0px;
        align-items: center;
        color: #00a7ad;
        &:hover {
          cursor: pointer;
          color: #027a48;
        }
      }
    }
    &__history {
      display: flex;
      gap: 20px;
      width: 100%;
      flex-shrink: 1;
      height: auto;
    }
    &__history_contain {
      border: 1px solid #e5e7eb;
      // height: 250px;
      //   max-height: 300px;
      min-width: 300px;
      max-width: 350px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      box-sizing: content-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &:hover {
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
      }
    }
    &__itemRedeemed {
      padding: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      & > div {
        background-color: #91fabd;
        color: #027a48;
        border-radius: 7px;
        padding: 0 8px;
        span {
          margin-left: 10px;
        }
      }
    }
    &__itemContext {
      padding: 16px;
      display: flex;
      height: 100%;
      flex-grow: 1;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      .pfr_redeemed_location {
        margin-top: auto;
      }
    }
    &__itemName {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;
      span {
        display: block;
        font-size: 0.9rem;
        font-weight: bolder;
        text-transform: uppercase;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-overflow: ellipsis;
      }
      h5 {
        max-width: 100%;
        max-height: 100%;
        font-weight: 600;
        text-wrap: auto;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__location {
      margin-top: 25px;
      display: flex;
      gap: 10px;
      margin-top: 10px;
      span {
        font-weight: 600;
      }
    }
  }
}

.pfr_breadcrumb {
  // background-color: $bg-color;
  opacity: 0.7;
  padding: 5px 10px;
  // position: absolute;
  top: 10px;
  left: 0px;
  width: 80%;
}
@media screen and (max-width: 1900px) {
  .pfr_container {
    .pfr_redeemed {
      width: 100%;
      margin-left: 0px;
      &.pfr_not-login {
        margin: 0;
        padding: 0 50px;
        padding-top: 50px;
      }
    }
    &__reward {
      padding: 0;
      width: auto;
      .pfr_reward__items {
        width: 100%;
        &__contain {
          .pfr_reward__item {
            width: 400px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .pfr_container {
    .pfr_content {
      .pfr_content__full{
        width: 700px;
      }
      & > div:last-child {
        width: 300px;
      }

    }
  }
  
}

@media screen and (max-width: 1400px) {
  .pfr_container {
    
    .pfr_redeemed {
      width: 100%;
      margin-left: 0px;
      &.pfr_not-login {
        margin: 0;
        padding: 0;
      }
    }
    &__reward {
      padding: 0;
      width: auto;
      .pfr_reward__items {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .pfr_container {
    .pfr_redeemed {
      width: 100%;
      margin-left: 0px;
      &.pfr_not-login {
        margin: 15px 0;
        padding: 0 15px;
        height: 200px;
        h1 {
          padding: 0;
          padding-left: 15px;
          padding-top: 10px;
          font-size: 2rem;
        }
        & > span {
          font-size: 1rem;
        }
        button {
          padding: 10px 18px;
          margin-left: 15px;
          margin-top: 20px;
          span {
            font-size: 1rem;
          }
        }
      }
    }
    &__reward {
      padding: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .pfr_container {
    padding: 0 10px;
    padding-bottom: 30px;
    &__redeemed-btn-sticky {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: white;
      position: sticky;
      bottom: 0;
      padding: 10px;
      z-index: 100;
      button {
        padding: 10px 20px;
        width: 70%;
        border-radius: 10px;
        background-color: #00a7ad;
        color: white;
        border: 1px solid #00a7ad;
        &:active {
          transform: scale(0.95);
          transition: all 0.1s ease-in-out;
        }
        &:disabled {
          background-color: #d0d5dd;
          border: 1px solid #d0d5dd;
          transition: none;
          transform: none;
        }
      }
    }
    .pfr_content {
      display: block;
      .pfr_content__full {
        width: 100%;
        .pfr_content__date-location__mobile {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 20px;
          display: block;
        }
      }
      & > div:last-child {
        display: none;
      }
      h5,
      h6 {
        font-size: 1rem;
      }
      .pfr_content_category {
        padding: 5px !important;
        gap: 10px !important;
      }
      .pfr_content_description {
        padding: 5px 8px !important;
        h6,
        h5 ~ span {
          font-size: 0.9rem;
        }
      }
    }
    .pfr_reward__items {
      width: auto;
      &__head {
        width: 100%;
        padding: 0;
        div {
          justify-content: space-between;
          padding: 0;
        }
      }
      &__contain {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .pfr_reward__item {
          width: 100%;
          height: 450px;
          margin: 0;
        }
        a {
          width: 100%;
        }
        &__ad {
          height: 80px;
        }
      }
    }
    .pfr_container__reward {
      padding: 0;
      position: relative;
    }
    .pfr_redeemed {
      &.pfr_not-login {
        background-image: none !important;
        background-color: #00a7ad;
        h1 {
          padding: 0;
          padding-left: 15px;
          padding-top: 10px;
          font-size: 2rem;
        }
        & > span {
          font-size: 1rem;
        }
        button {
          padding: 10px 5px;
          margin: 0;
          width: 70%;
          margin-top: 20px;
          margin-left: 55px;
          // transform: translate(-50%);
          span {
            font-size: 1rem;
          }
        }
      }
      &__title {
        h1 {
          font-size: 1rem;
        }
        button {
          padding: 5px 10px;
          font-size: 0.8rem;
          padding: 5px;
          padding-left: 10px;
          span {
            display: none;
          }
        }
      }
      &__description,
      &__reward {
        font-size: 16px;
      }
    }
    &__reward {
      display: block;
      // flex-direction: column;
      .pfr_reward__filter {
        position: absolute;
        top: 90px;
        left: 0;
        z-index: 100;
        border: 1px solid #e5e7eb;
        display: block;
        transform: translateX(-110%);
      }
      .pfr_reward__items {
        &__head {
          display: block;
          & > div {
            margin-top: 15px;
            display: flex;
            flex-basis: 50%;
            button,
            select {
              width: 45%;
              background-color: white;
              border-radius: 8px;
              border: 1px solid gray;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            }
            button {
              display: block;
              padding: 5px 10px;
            }
          }
        }
        h1 {
          font-size: 16px;
        }
      }
    }
    .show-filter {
      // display: block;
      &:checked ~ .pfr_reward__filter {
        // display: block;
        transform: translateX(0);
        transition: all 1s ease-in;
      }
      // &:not(:checked) ~ .pfr_reward__filter {
      //   transform: translateX(-110%);
      //   transition: all 1s ease-in;
      // }
    }
  }
}
