.row-review {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .left-column {
    flex: 1;
    background-color: #f4f4f4;
    padding: 20px;
  }

  .right-column {
    flex: 1;
    background-color: #e0e0e0;
    padding: 20px;
  }
}
.row-buttom {
  display: flex;
  // justify-content: space-between;
  gap: 20px;
}
.bold-underline {
  font-weight: bold; 
  text-decoration: underline; 
  color: black; 
}
