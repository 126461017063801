footer {
  background-color: $cyanE6F;
  position: relative;
  z-index: 9;

  .container {
    padding-left: 0;
    padding-right: 0;
    .ftr-snippet {
      h4 {
        font-size: convert-rem(20);
        margin-top: 0;
        color: $black333;
        margin-bottom: 20px;
      }

      ul.list-unstyled {
        li a {
          color: $cyan0CC;
          font-size: convert-rem(14);
          outline: none;
          line-height: 24px;
          font-weight: 600;
        }

        &.ss-icon {
          display: flex;
          a {
            color: $whiteFFF;
            background-color: $cyan00C;
            font-size: convert-rem(18);
            border-radius: 50%;
            margin-right: 10px;
            width: 35px;
            height: 35px;
            text-align: center;
            border: 1px solid $cyan00C;
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              text-decoration: none;
              background-color: $whiteFFF;
              border: 1px solid $grayD8D;
              color: $cyan0CC;
            }
          }
        }

        @media screen and (max-width: 767px) {
          &:not(.ss-icon) {
            display: flex;
            flex-direction: column;
          }
        }
      }

      @media screen and (max-width: 1199px) {
        h4 {
          font-size: convert-rem(16);
          margin-bottom: 10px;
        }

        ul li a {
          font-size: convert-rem(12);
          line-height: 19px;
        }
      }

      @media screen and (max-width: 767px) {
        h4 {
          margin-bottom: 5px;
        }
        ul li {
          display: inline-block;
          margin-right: 4px;
          margin-bottom: 3px;

          a {
            font-size: convert-rem(14);
          }
        }
      }
    }
    .copy-right {
      .ftr-logo {
        width: 90px;
        filter: brightness(1) invert(1);
        -moz-filter: brightness(1) invert(1);
        -webkit-filter: brightness(1) invert(1);
        opacity: 0.7;
        margin-bottom: 10px;
      }
      p,
      .cr-text {
        color: $black333;
        font-size: convert-rem(12);
      }
      a {
        color: $cyan0CC;
        text-decoration: underline;
        font-size: 12px;
        outline: none;
      }
    }
  }
}

.page-footer {
  display: flex;
  flex-direction: column;
  background-color: $cyanE6F;
  border-top: 4px solid $cyan03C;
  color: $black333;

  &__content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    padding-bottom: 40px;

    .footer-logo {
      width: 100px;
      height: 100px;
      margin: 16px auto;
    }
    .footer-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;

      &__item {
        .item-title {
          font-size: convert-rem(18);
          line-height: 21px;
          margin-bottom: 12px;
          color: $black0A0;
        }
        .item-list-child {
          &__in-row {
            display: flex;
            align-items: center;
            column-gap: 8px;

            li {
              margin-bottom: 0;
            }
          }
          &:not(.item-list-child__in-row) {
            li:not(:last-child) {
              margin-bottom: 8px;
            }
          }
          li > a {
            color: unset;
            &:hover {
              text-decoration: none;
              color: $cyan03C;
            }
          }
        }
      }
    }
  }

  &__license {
    background-color: $cyanCDF;
    padding: 16px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    font-size: convert-rem(14);
    line-height: 22px;

    .privacy-and-license {
      margin-bottom: 6px;
    }

    a {
      color: unset;
      &:hover {
        text-decoration: none;
        color: $cyan03C;
      }
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  @media screen and (max-width: 1199px) {
    &__content {
      padding: 0 24px 40px;
    }
  }

  @media screen and (max-width: 767px) {
    &__content .footer-grid {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: unset;
    }
  }
}
