.privacy-policy-page {
  position: relative;
  overflow: hidden;

  .page-head {
    width: 100%;
    padding: 24px 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    &__title {
      max-width: 1200px;
      margin: 0 auto;

      font-size: convert-rem(46);
      color: $gray556;
      margin-bottom: 0;
    }
  }

  .page-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px 0;

    a {
      color: $cyan0CC;
      &:hover {
        text-decoration: underline;
      }
    }
    ol {
      padding-left: 40px;
      margin-bottom: 16px;
      list-style-type: decimal;
      li:not(:last-child) {
        padding-bottom: 4px;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .page-head {
      padding: 24px;
      &__title {
        font-size: convert-rem(36);
      }
    }
    .page-content {
      padding: 24px;
    }
  }

  @media screen and (max-width: 768px) {
    .page-head {
      &__title {
        font-size: convert-rem(24);
        text-align: center;
      }
    }
    .page-content {
      font-size: convert-rem(14);
      line-height: 20px;
      letter-spacing: 0.0025em;
    }
  }
}
