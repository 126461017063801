.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  list-style-type: none;
  &.pagination-bar {
    border-top: 1px solid $grayEAE;
    padding: 16px 24px;

    @media screen and (max-width: 767px) {
      padding: 16px 12px;
    }

    @media screen and (max-width: 474px) {
      padding: 16px 0;
    }
  }
  .pagination-item {
    width: 40px;
    height: 40px;
    color: $black667;
    font-size: convert-rem(14);
    font-weight: 500;
    line-height: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    &__list {
      flex-grow: 1;
    }
    &__prev,
    &__next {
      width: max-content;
      border: 1px solid $grayD0D;
      border-radius: 8px;
      padding: 8px 14px;

      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $black344;
      font-size: convert-rem(14);
      font-weight: 500;
      line-height: 20px;

      display: flex;
      align-items: center;
      column-gap: 8px;
      .icon {
        width: 20px;
        height: 20px;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        background-color: transparent;
        color: $green00A;
      }
    }

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      cursor: pointer;
      color: $green00A;
    }
    &.selected {
      background-color: $greenDCF;
      color: $green00A;
    }
    &.disabled {
      pointer-events: none;
      background: rgba(0, 0, 0, 0.08);
      &:hover {
        color: $black344;
        cursor: default;
      }
    }
    ul.pagination-container {
      gap: 0;
    }

    @media screen and (max-width: 992px) {
      width: 36px;
      height: 36px;
      &__next,
      &__prev {
        border-radius: 50%;
        padding: 8px;
        .icon {
          width: 16px;
          height: 16px;
        }
        span {
          display: none;
        }
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 24px 0;

  .pagination-item {
    width: 40px;
    height: 40px;
    color: $black475;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid transparent;
    background-color: #fff;
    transition:
      color 0.3s,
      background-color 0.3s;

    &.selected {
      background-color: $cyanBFA;
      color: $green00A;
      border-color: $green00A;
    }

    &:hover {
      cursor: pointer;
      color: $green7AD;
    }

    &.dots {
      cursor: default;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0;
    }
  }

  .pagination-item__prev,
  .pagination-item__next {
    width: max-content;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 8px 14px;
    background-color: #fff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    color: #344054;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    transition:
      color 0.3s,
      background-color 0.3s;

    &:hover {
      background-color: transparent;
      color: #00a38a;
    }

    &:disabled {
      pointer-events: none;
      color: rgba(0, 0, 0, 0.3);
    }

    &-text {
      display: flex;
      align-items: center;
      column-gap: 8px;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .pagination-item-list {
    display: flex;
    gap: 2px;
  }
}
