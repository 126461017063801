.loaderCnt {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;

  position: fixed;
  top: 0;
  left: 0;
  background: rgba(52, 64, 84, 0.7);
  * {
    display: inline-block;
    vertical-align: middle;
  }

  .loading-icon {
    position: relative;
    width: 64px;
    height: 64px;
  }
}
.loading-content {
  width: 100%;
  min-height: 250px;

  display: flex;
  align-items: center;
  justify-content: center;
  &.absolute-content {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    min-height: 100%;
    z-index: 9;

    .loading-icon {
      width: 40px;
      height: 40px;
    }
  }

  .loading-icon {
    position: relative;
    width: 64px;
    height: 64px;
  }
}

.carousel-loading {
  width: 100%;
  max-width: 1280px;

  margin: 24px auto 0;
  overflow: hidden;
  .loading-list {
    width: 100%;
    height: 440px;

    display: flex;
    column-gap: 32px;
    .loading-item {
      width: calc(24% - 16px);
      flex-shrink: 0;
      border-radius: 8px;
      overflow: hidden;
      .react-loading-skeleton {
        height: 100%;
      }
    }
  }

  &.latest-article-carousel .loading-list .loading-item:first-child {
    width: calc(50% - 16px);
  }

  &.destination-carousel {
    padding: 24px 0;
    .loading-list {
      height: 472px;
      flex-wrap: wrap;
      row-gap: 24px;
      .loading-item {
        width: calc(33.33% - 24px);
      }
    }
  }

  @media screen and (max-width: 1023px) and (min-width: 768px) {
    padding: 24px 0;
    .loading-list {
      column-gap: 16px;
      .loading-item,
      .loading-item:first-child {
        width: calc(33.33% - 8px) !important;
      }
    }
    &.destination-carousel .loading-list {
      gap: 24px;
      .loading-item {
        width: calc(33.33% - 24px) !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    padding: 24px 0;
    .loading-list {
      height: 336px;
      column-gap: 16px;
      .loading-item {
        width: calc(50% - 4px) !important;
      }
    }
    &.destination-carousel .loading-list {
      gap: 16px;
      .loading-item {
        width: calc(50% - 12px) !important;
      }
    }
  }
}
