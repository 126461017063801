.profile {
  .box-shadow {
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  }

  width: 100%;
  background-color: $grayF9F;
  padding: 40px 0px;

  @media screen and (max-width: 576px) {
    padding-top: 20px;
  }

  &__info {
    width: 100%;
    padding: 0 86px;
    @media screen and (max-width: 1024px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 576px) {
      padding: 0 20px;
    }

    &__user {
      width: 1280px;
      max-width: 100%;
      padding: 40px 40px 0px 40px;
      align-items: flex-start;
      gap: 46px;
      margin: 0 auto;
      background: $whiteFFF;
      border-radius: 16px;
      box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
        0px 12px 16px -4px rgba(16, 24, 40, 0.08);

      @media screen and (max-width: 576px) {
        padding: 20px 20px 0px 20px;
      }

      &__top {
        display: flex;
        gap: 8px;
      }
      &__detail {
        display: flex;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;

        @media screen and (max-width: 576px) {
          gap: 12px;
        }

        &__info__wrapper {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 12px;

          @media screen and (max-width: 576px) {
            flex-direction: column;
            gap: 8px;
          }
        }

        &__info__top {
          display: flex;
          align-items: center;
          gap: 12px;

          &__name {
            font-size: 20px;
            font-weight: 700;
            min-width: 150px;

            @media screen and (max-width: 576px) {
              font-size: 16px;
              min-width: 100px;
            }
          }

          &__content {
            font-size: 14px;
            color: $black475;

            @media screen and (max-width: 576px) {
              font-size: 12px;
            }
          }

          &__icon {
            padding: 4px 8px;
            border-radius: 100rem;
            background-color: $whiteFFF;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            gap: 4px;
            color: #876747;
            font-weight: 500;
            font-size: 12px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              bottom: -10px;
              left: 0;
              background-color: transparent;
              width: 100%;
              height: 10px;
            }

            img {
              width: 14px;
              height: 14px;

              @media screen and (max-width: 576px) {
                width: 12px;
                height: 12px;
              }
            }

            @media screen and (max-width: 576px) {
              height: 18px;
            }

            &:hover &__content {
              display: block;
            }

            &__content {
              display: none;
              position: absolute;
              bottom: -230%;
              left: 50%;
              transform: translateX(-50%);
              width: 200px;
              background-color: $black101;
              color: $whiteFFF;
              padding: 8px;
              border-radius: 12px;
              font-size: 12px;
              font-weight: 300;

              &__link {
                text-decoration: underline;
              }

              &::after {
                content: '';
                position: absolute;
                top: -8px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $black101;
              }
            }
          }
        }

        &__avatar {
          width: 104px;
          height: 104px;
          border: 4px solid $whiteFFF;
          border-radius: 50%;
          overflow: hidden;
          box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
          position: relative;

          @media screen and (max-width: 576px) {
            width: 64px;
            height: 64px;
          }

          &__wrapper {
            position: relative;
            cursor: pointer;
          }

          &__edit {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            cursor: pointer;

            @media screen and (max-width: 576px) {
              display: none;
            }
          }
        }
      }
      &__edit {
        height: 44px;
        display: flex;
        align-items: flex-start;
        padding: 10px 18px;
        justify-content: center;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid $grayD0D;
        background: $whiteFFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        color: $black344;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @media screen and (max-width: 1024px) {
          display: none;
        }
      }
      &__more {
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        border: 1px solid $grayEAE;
        border-radius: 6px;
        display: none;

        @media screen and (max-width: 1024px) {
          display: flex;
        }
      }
    }
  }
  &__content {
    width: 1280px;
    max-width: 100%;
    margin: 0 auto;

    &__empty {
      border-radius: 16px;
      padding: 40px 24px;
      background-color: $whiteFFF;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 24px;

      &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__title {
          font-size: 18px;
          font-weight: 700;
          color: $black101;
          line-height: 22px;

          @media screen and (max-width: 576px) {
            font-size: 16px;
            line-height: 18px;
          }
        }

        &__description {
          max-width: 425px;
          color: $black475;
          font-size: 16px;
          line-height: 28px;

          @media screen and (max-width: 576px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }

    @media screen and (max-width: 1440px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 1280px) {
      padding: 0 86px;
    }

    @media screen and (max-width: 1024px) {
      padding: 0 40px;
    }

    @media screen and (max-width: 576px) {
      padding: 0 20px;
    }
  }

  &__progress {
    width: 100%;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 20px;
    background-color: $whiteFFF;

    @media screen and (max-width: 1024px) {
      margin-bottom: 0;
    }

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    &__outer {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &__header {
      margin-bottom: 20px;
      display: flex;
      gap: 12px;

      &__icon {
        color: #d92d20;
        padding: 12px;
        width: 48px;
        height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fef3f2;
      }

      &__content {
        display: grid;
        gap: 2px;

        h2 {
          font-size: 20px;
          font-weight: 700;
          color: $black101;
          margin: 0;

          @media screen and (max-width: 576px) {
            font-size: 16px;
          }
        }

        p {
          font-size: 14px;
          color: $black475;
          margin: 0;

          @media screen and (max-width: 576px) {
            font-size: 12px;
          }
        }
      }
    }

    &__container {
      background-color: #e0e0e0;
      border-radius: 5px;
      height: 8px;
      width: 100%;
      position: relative;
      margin: 0 auto;
    }

    &__bar {
      background-color: $green7AD;
      height: 8px;
      border-radius: 5px;
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      color: $black101;
    }

    &__edit-button {
      display: flex;
      width: 100%;
      justify-content: center;
      flex: 1;
      border: 1px solid $green00A;
      background-color: transparent;
      color: $green00A;
      border-radius: 5px;
      min-width: 120px;
      padding: 8px 14px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
    }

    &__footer {
      margin-top: 20px;
      display: flex;
      gap: 12px;
      padding-bottom: 10px;
      overflow-x: auto;
      align-items: center;
      ::-webkit-scrollbar {
        display: none;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;

      @media screen and (max-width: 1024px) {
        display: none;
      }

      &__item {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: 12px;
        color: $black101;
        border: 1px solid $grayEAE;
        border-radius: 12px;
        padding: 14px 16px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;

        &__arrow {
          &__wrapper {
            display: none;
            position: sticky;
            right: 0;
            cursor: pointer;

            @media screen and (max-width: 1280px) {
              display: block;
            }
          }

          &__item {
            background-color: $whiteFFF;
            height: 100%;
            border-radius: 100rem;
            border: 1px solid $grayEAE;
            width: 36px;
            height: 36px;
            box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &__icon__check {
          display: none;
        }

        &__icon__arrow {
          display: block;
        }

        &.disabled {
          cursor: default;
          border-color: $grayEAE;
          background-color: $grayF9F;
          color: $gray98A;

          .profile__progress__footer__item__icon__check {
            display: block;
          }

          .profile__progress__footer__item__icon__arrow {
            display: none;
          }
        }

        &:not(.disabled):hover {
          box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
        }
      }
    }
  }
  &__activity {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    display: grid;
    grid-template-columns: 2.31fr 1fr;
    gap: 24px;

    @media screen and (max-width: 768px) {
      & > :nth-child(1) {
        order: 2;
      }

      & > :nth-child(2) {
        order: 1;
      }

      gap: 16px;
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    &__new__feature {
      overflow: hidden;
      border-radius: 16px;

      &__content__wrapper {
        position: relative;
        background: url('/images/profile-bg-new-feature.png') no-repeat;
        background-size: cover;
        overflow: hidden;
        border-radius: 16px;
        margin: -10px 0;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 40px 24px;

        @media screen and (max-width: 1024px) {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 20px;
        }
      }

      &__content {
        &__header {
          h2 {
            font-size: 20px;
            font-weight: 700;
            color: $black101;

            @media screen and (max-width: 576px) {
              font-size: 18px;
            }
          }

          p {
            margin-bottom: 24px;
            color: $black475;
          }
        }

        &__footer {
          a {
            display: inline-block;
            border: none;
            padding: 8px 12px;
            border-radius: 8px;
            color: $black101;
            background-color: $whiteFFF;
            font-weight: 500;
            font-size: 14px;

            &:hover {
              opacity: 0.85;
            }

            @media screen and (max-width: 1024px) {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }

    &__content {
      background: url('/images/profile-bg-rewards.png') no-repeat;
      background-size: cover;
      background-position: 100%;
      border-radius: 16px;
      padding: 24px;
      min-height: 140px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      @media screen and (max-width: 576px) {
        padding: 16px;
        gap: 16px;
        min-height: auto;
      }

      &__new {
        position: absolute;
        top: 24px;
        right: -8px;
        background-color: $redF04;
        border-top-left-radius: 100rem;
        border-top-right-radius: 100rem;
        border-bottom-left-radius: 100rem;
        padding: 6px 16px;
        color: $whiteFFF;
        font-weight: 700;
        font-size: 14px;

        @media screen and (max-width: 576px) {
          font-size: 10px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -8px;
          right: 0;
          background-color: #7a271a;
          width: 18%;
          height: 8px;
          border-bottom-right-radius: 100rem;
        }
      }

      &__icon {
        padding: 8px 10px;
        background-color: $whiteFFF;
        border-radius: 100rem;
        width: fit-content;
        display: flex;
        gap: 4px;
        color: #876747;
        font-weight: 500;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
        height: fit-content;

        span {
          line-height: 26px;

          @media screen and (max-width: 576px) {
            font-size: 12px;
            line-height: 15px;
          }
        }

        @media screen and (max-width: 576px) {
          img {
            width: 14px;
            height: 14px;
          }
        }
      }

      &__button {
        border: none;
        padding: 8px 12px;
        border-radius: 8px;
        color: $black101;
        background-color: $whiteFFF;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          opacity: 0.85;
        }
      }
    }

    &__refer {
      border-radius: 16px;
      padding: 24px;
      background-color: $whiteFFF;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__title,
        &__description {
          margin: 0;
        }

        &__title {
          color: #d92d20;
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            color: $black101;
            font-size: 16px;
            font-weight: 700;
          }
        }

        &__description {
          color: $black475;
          font-size: 14px;
          font-weight: 400;
        }
      }

      &__button {
        button {
          width: 100%;
          border: none;
          padding: 8px 16px;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid $green00A;
          background-color: transparent;
          color: $green00A;
        }
      }
    }

    &__main {
      border-radius: 16px;
      padding: 24px;
      background-color: $whiteFFF;

      @media screen and (max-width: 768px) {
        padding: 16px;
      }

      &__content {
        &__header {
          display: flex;
          justify-content: space-between;

          &__select__wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            position: relative;
            cursor: pointer;
            user-select: none;

            &.status {
              padding: 8px 14px;
              border-radius: 8px;
              border: 1px solid $grayEAE;

              h3 {
                min-width: 80px;
                font-weight: 500;
                color: $black344;
                font-size: 14px;

                @media screen and (max-width: 768px) {
                  font-size: 12px;
                  line-height: 15px;
                  min-width: 36px;
                }
              }

              .profile__activity__main__content__header__select__wrapper__dropdown {
                right: 0;
                left: auto;
              }
            }

            &__dropdown {
              display: none;

              &.active {
                display: block;
              }

              position: absolute;
              top: 120%;
              left: 0;
              z-index: 10;
              width: 120%;
              background-color: $whiteFFF;
              border-radius: 8px;
              overflow: hidden;
              border: 1px solid $grayEAE;

              &__item {
                padding: 12px;
                font-size: 16px;
                font-weight: 400;
                color: $black101;
                cursor: pointer;

                &.active {
                  background-color: $cyanBFA;
                }

                &:hover {
                  background-color: $cyanBFA;
                }
              }
            }

            h3 {
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              color: $black101;
              margin: 0;
              min-width: 120px;

              @media screen and (max-width: 768px) {
                min-width: 93px;
                font-size: 16px;
                line-height: 20px;
              }
            }

            &__icon {
              color: $black101;
              rotate: 180deg;
              display: flex;
              align-items: center;
              justify-content: center;

              @media screen and (max-width: 768px) {
                width: 16px;
                height: 16px;
              }

              &.active {
                rotate: 0deg;
              }
            }
          }
        }

        &__body {
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          &__item {
            padding: 24px;
            border-radius: 16px;
            border: 1px solid $grayEAE;

            @media screen and (max-width: 768px) {
              padding: 16px;
            }

            &__wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 16px;

              img {
                @media screen and (max-width: 768px) {
                  width: 40px;
                  height: 40px;
                }
              }

              &__content {
                display: flex;
                align-items: center;
                gap: 16px;

                @media screen and (max-width: 768px) {
                  gap: 12px;
                }

                &__avatar {
                  width: 48px;
                  height: 48px;
                  border-radius: 100rem;
                }

                p {
                  margin: 0;
                }

                &__text {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;

                  &__name {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    display: -webkit-box;

                    @media screen and (max-width: 768px) {
                      font-size: 14px;
                      line-height: 18px;
                    }

                    &__action {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 20px;

                      @media screen and (max-width: 768px) {
                        font-size: 12px;
                        line-height: 15px;
                      }
                    }
                  }

                  &__time {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                  }
                }

                &__more {
                  &__wrapper {
                    cursor: pointer;
                    position: relative;
                  }

                  @media screen and (max-width: 768px) {
                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }

                  &__dropdown {
                    position: absolute;
                    right: 0;
                    bottom: -100px;
                    background-color: $whiteFFF;
                    border-radius: 8px;
                    border: 1px solid $grayEAE;
                    min-width: 160px;
                    color: $black101;
                    overflow: hidden;

                    &__item {
                      padding: 12px;
                      font-size: 14px;
                      cursor: pointer;

                      &:hover {
                        background-color: $grayF9F;
                      }
                    }
                  }
                }

                &__review {
                  margin-top: 20px;
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  @media screen and (max-width: 768px) {
                    margin-top: 16px;
                  }

                  &__text {
                    font-size: 16px;
                    line-height: 28px;
                    color: $black101;
                    margin: 0;

                    @media screen and (max-width: 768px) {
                      font-size: 12px;
                      line-height: 21px;
                    }
                  }

                  &__article {
                    margin-top: 20px;
                    border-radius: 16px;
                    padding: 16px;
                    background-color: $grayF9F;
                    display: flex;
                    border: 1px solid $grayF2F;
                    gap: 16px;
                    max-width: 568px;
                    position: relative;

                    @media screen and (max-width: 768px) {
                      margin-top: 16px;
                      padding: 12px;
                      flex-direction: column;
                    }

                    &__image {
                      width: 154px;
                      height: 104px;
                      border-radius: 8px;
                      object-fit: cover;

                      @media screen and (max-width: 768px) {
                        width: 100%;
                        height: 172px;
                      }
                    }

                    &__text {
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      flex: 1;

                      p {
                        margin: 0;
                      }

                      &__rating {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: $black475;
                        font-size: 14px;
                        line-height: 18px;

                        @media screen and (max-width: 768px) {
                          font-size: 12px;
                          line-height: 15px;
                        }
                      }

                      &__name {
                        color: $black101;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;

                        @media screen and (max-width: 768px) {
                          font-size: 14px;
                          line-height: 18px;
                        }
                      }

                      &__category {
                        color: $black475;
                        font-size: 14px;
                        line-height: 18px;

                        @media screen and (max-width: 768px) {
                          font-size: 12px;
                          line-height: 15px;
                        }
                      }

                      &__link {
                        color: $green009;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;

                        @media screen and (max-width: 768px) {
                          font-size: 12px;
                          line-height: 15px;
                        }
                      }

                      &__saved__category {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        flex-wrap: wrap;

                        &__time {
                          color: $black475;
                          font-size: 14px;
                          line-height: 18px;

                          @media screen and (max-width: 768px) {
                            font-size: 12px;
                            line-height: 15px;
                          }
                        }

                        &__name {
                          color: $black101;
                          font-size: 12px;
                          font-weight: 500;
                          line-height: 15px;
                          padding: 3px 6px;
                          border: 1px solid $black101;
                          border-radius: 6px;

                          @media screen and (max-width: 768px) {
                            font-size: 10px;
                            line-height: 12px;
                          }
                        }
                      }
                    }

                    &__button {
                      &__wrapper {
                        cursor: pointer;
                        position: relative;
                        height: fit-content;
                      }

                      @media screen and (max-width: 768px) {
                        position: absolute;
                        right: 20px;
                        bottom: 24px;
                      }

                      &__dropdown {
                        position: absolute;
                        right: 0;
                        bottom: -100px;
                        background-color: $whiteFFF;
                        border-radius: 8px;
                        border: 1px solid $grayEAE;
                        min-width: 160px;
                        color: $black101;
                        overflow: hidden;
                        z-index: 10;

                        &__item {
                          padding: 12px;
                          font-size: 14px;
                          cursor: pointer;

                          &:hover {
                            background-color: $grayF9F;
                          }
                        }
                      }
                    }
                  }

                  &__saved__icon {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    border-radius: 100rem;
                    padding: 2px 8px;
                    background-color: $grayF2F;
                    width: fit-content;
                    color: $black475;
                    font-size: 12px;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__saved {
    margin-top: 20px;
    display: grid;
    gap: 20px;
    display: grid;
    grid-template-columns: 2.31fr 1fr;
    gap: 24px;

    @media screen and (max-width: 768px) {
      & > :nth-child(1) {
        order: 2;
      }

      & > :nth-child(2) {
        order: 1;
      }

      gap: 16px;
    }

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    &__content {
      border-radius: 16px;
      padding: 24px;
      background-color: $whiteFFF;
      display: flex;
      flex-direction: column;
      gap: 12px;

      @media screen and (max-width: 768px) {
        padding: 16px;
      }

      &__header {
        display: flex;
        justify-content: space-between;

        &__title {
          font-size: 20px;
          font-weight: 700;

          @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        &__filter {
          display: flex;
          align-items: center;
          gap: 12px;

          &__item {
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 8px;
            padding: 8px;
            border: 1px solid $grayEAE;
          }

          .select__wrapper {
            @media screen and (max-width: 768px) {
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }

      &__filter {
        &__list {
          display: flex;
          align-items: center;
          gap: 12px;

          &__item {
            cursor: pointer;
            font-weight: 500;
            font-size: 14px;
            border: 1px solid $grayD0D;
            padding: 8px 12px;
            border-radius: 8px;
            color: $black475;
            line-height: 18px;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);

            @media screen and (max-width: 768px) {
              font-size: 12px;
              line-height: 15px;
              padding: 6.5px 10px;
            }

            &:hover {
              opacity: 0.85;
            }

            &.active {
              color: $green00A;
              border-color: $green00A;
            }
          }
        }
      }

      &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;

        &.grid-view {
          flex-direction: row;
          flex-wrap: wrap;
        }

        &.grid-view {
          .profile__saved__content__list__item {
            width: calc(33.333% - 16px);
            border-bottom: none;
            flex-direction: column;
            position: relative;

            @media screen and (max-width: 1024px) {
              width: calc(50% - 16px);
            }
          }

          .profile__saved__content__list__item__image {
            width: 100%;

            img {
              height: 248px;
              width: 100%;
              object-fit: cover;
            }
          }

          .profile__saved__content__list__item__content__title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 42px;
          }

          .profile__saved__content__list__item__content__icon {
            // TODO: show on hover
            display: none;
            position: absolute;
            right: 10px;
            top: 10px;
            background-color: $whiteFFF;
            border-radius: 8px;

            &__item {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        &__item {
          display: flex;
          align-items: center;
          gap: 12px;
          padding-bottom: 16px;
          border-bottom: 1px solid $grayF2F;

          @media screen and (max-width: 768px) {
            align-items: start;
          }

          &__image {
            img {
              width: 46px;
              height: 46px;
              border-radius: 8px;
              object-fit: cover;

              @media screen and (max-width: 768px) {
                width: 36px;
                height: 36px;
              }
            }
          }

          &__content {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex: 1;

            &__title {
              font-size: 16px;
              font-weight: 700;
              line-height: 20px;
              margin: 0;

              @media screen and (max-width: 768px) {
                font-size: 14px;
                line-height: 18px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &__info {
              display: flex;
              align-items: center;
              gap: 8px;

              &__type {
                color: $black475;
                font-size: 10px;
                line-height: 18px;
                padding: 3.5px 8px;
                font-weight: 600;
                line-height: 12px;
                border-radius: 6px;

                &.warning {
                  background-color: #fffaeb;
                  color: #b54708;
                }

                &.success {
                  background-color: #eef4ff;
                  color: #3538cd;
                }
              }

              &__date {
                color: $black475;
                font-size: 12px;
                line-height: 15px;
              }
            }

            &__icon {
              cursor: pointer;
              position: relative;

              &__dropdown {
                position: absolute;
                right: 0;
                bottom: -100px;
                background-color: $whiteFFF;
                border-radius: 8px;
                border: 1px solid $grayEAE;
                min-width: 160px;
                z-index: 10;
                overflow: hidden;

                &__item {
                  padding: 12px;
                  font-size: 14px;
                  cursor: pointer;
                  color: $black101;

                  &:hover {
                    background-color: $grayF9F;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__left {
    display: grid;
    gap: 20px;

    @media screen and (max-width: 576px) {
      gap: 16px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media screen and (max-width: 576px) {
      gap: 16px;
    }
  }
}

.profile-method-selection {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-top: 32px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }

  @media screen and (max-width: 576px) {
    gap: 8px;
  }

  .profile-method {
    padding: 0px 12px 12px 12px;
    font-weight: 500;
    line-height: 30px;
    color: $gray98A;
    cursor: pointer;
    &__reward {
      padding: 0px 12px 12px 12px;
      font-weight: 500;
      line-height: 30px;
      color: $gray98A;
      cursor: pointer;
      &:hover {
        color: $black101;
      }
    }

    @media screen and (max-width: 576px) {
      font-size: 12px;
      padding: 0px 8px 8px 8px;
      line-height: 15px;
    }

    &.active {
      color: $black101;
      border-bottom: 2px solid $green00A;
    }
  }
}

.profile-setting-method-selection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #667085;
  .profile-setting-method {
    padding: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
    &:not(.active) {
      cursor: pointer;
    }
    &.active {
      color: #101828;
      background: $greenDCF;
      border-radius: 8px;
    }
    @media screen and (max-width: 1024px) {
      padding: 16px 0px 12px 0px;
    }
    @media screen and (max-width: 426px) {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    padding-top: 0px;
    margin-top: 82px;
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
  }
  @media screen and (max-width: 426px) {
    padding-top: 0px;
    margin-top: 44px;
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
  }
}

.profile-list-item {
  width: 1280px;
  max-width: 100%;
  margin: 0 auto;

  &__list {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
  &__favourite-venue {
    display: flex;
    width: 70%;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 16px;
    border: 1px solid $grayD0D;
    background: $whiteFFF;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;

      &__dot-menu {
        margin-bottom: 24px;
        cursor: pointer;
      }

      &__upvote-fa {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        width: 75%;
        padding: 16px;
        border-radius: 12px;
        border: 1px solid $grayEAE;
        background: #f9fafb;
      }
      &__upvote {
        display: flex;
        align-items: flex-start;
        gap: 16px;
        width: 75%;

        &__img {
          width: 40px;
          height: 40px;
          margin: 6px;

          > span {
            padding: 10px !important;
            background-color: #eff8ff !important;
            border-radius: 50%;

            > img {
              min-width: 20px !important;
              min-height: 20px !important;
            }
          }
        }

        &__img1 {
          width: 40px;
          height: 40px;
          margin: 6px;

          > span {
            padding: 10px !important;
            background-color: #fffaeb !important;
            border-radius: 50%;

            > img {
              min-width: 20px !important;
              min-height: 20px !important;
            }
          }
        }

        &__detail {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 8px;
          color: #667085;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &__review-by {
            padding-top: 4px;
            > span {
              color: #101828;
              font-weight: 700;
            }
          }
        }
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }

      &__top {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        align-self: stretch;
        flex-direction: column;

        &__left {
          > div:first-child {
            display: flex;
            align-items: center;
            gap: 16px;
          }
          width: 100%;
          display: flex;
          align-items: center;
          gap: 16px;
          flex: 1 0 0;
          justify-content: space-between;
        }
        &__avatar {
          width: 52px;
          height: 52px;
          position: relative;
          > span {
            width: 100% !important;
            height: 100% !important;
            border-radius: 50%;
          }
        }
        &__img-venue {
          width: 126px;
          height: 126px;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @media screen and (max-width: 767px) {
            width: 100%;
            height: 140px;

            > span {
              height: 100% !important;
              width: 100% !important;
            }
          }
        }
        &__img-article {
          width: 104px;
          height: 104px;
          border-radius: 8px;
          overflow: hidden;

          > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        &__img-article-review {
          width: 133px;
          height: 110px;
          border-radius: 8px;

          overflow: hidden;
          position: relative;
        }
        &__detail {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          color: $black667;
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;

          &__user {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            > div:first-child {
              color: $black101;
              font-weight: 700;
            }
          }
          &__date {
            font-size: 14px;
            line-height: 20px;
          }

          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }

          @media screen and (max-width: 426px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
        &__venue {
          display: flex;
          width: 75%;
          flex-shrink: 0;
          max-height: 126px;
          border-radius: 12px;
          border: 1px solid $grayD0D;
          overflow: hidden;
          background-color: $grayF9F;
          @media screen and (max-width: 767px) {
            flex-direction: column;
            width: 100%;
          }
        }
        > a {
          width: 100%;
        }
        &__article {
          display: flex;
          width: 75%;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid $grayD0D;
          overflow: hidden;
          padding: 16px;
          gap: 16px;
          background-color: $grayF9F;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
          @media screen and (max-width: 426px) {
            gap: 12px;
          }
        }
        &__venue-info {
          display: flex;
          padding: 16px 20px;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          font-size: 14px;
          gap: 8px;

          &__top {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 4px;
            align-self: stretch;
            > div:first-child {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              @media screen and (max-width: 426px) {
                font-size: 14px;
                line-height: 20px;
              }
            }
            &__star {
              display: flex;
              height: 20px;
              align-items: center;
              gap: 4px;
              color: $black667;
              font-weight: 600;
            }
          }
          &__detail {
            color: #1d2939;
            max-height: 42px;
            overflow: hidden;
          }
          @media screen and (max-width: 426px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
        &__article-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          font-size: 14px;
          gap: 8px;

          &__name {
            display: flex;
            align-items: center;
            gap: 4px;
            color: $green00A;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            @media screen and (max-width: 426px) {
              font-size: 12px;
              line-height: 18px;
              gap: 4px;
            }
          }
          &__name-review {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #101828;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;

            @media screen and (max-width: 426px) {
              font-size: 16px;
              line-height: 25px;
              gap: 4px;
            }
          }
          &__title {
            color: $black101;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            max-height: 48px;
            overflow: hidden;

            @media screen and (max-width: 767px) {
              max-height: 60px;
              font-size: 14px;
              line-height: 20px;
            }
          }
          &__date {
            display: flex;
            align-items: center;
            gap: 4px;
            color: $black667;
            @media screen and (max-width: 426px) {
              gap: 2px;
            }
          }
          @media screen and (max-width: 426px) {
            font-size: 12px;
            line-height: 18px;
            gap: 4px;
          }
        }
        @media screen and (max-width: 426px) {
          gap: 16px;
        }
      }
    }
    &__action {
      display: flex;
      align-items: flex-start;
      gap: 24px;
    }
    &__action-child {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: $black667;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @media screen and (max-width: 426px) {
        font-size: 14px;
        line-height: 20px;
      }

      &__img {
        background-color: transparent;
        border-color: transparent;

        > div {
          width: 20px !important;
          height: 20px !important;
          > span {
            width: 20px !important;
            height: 20px !important;
            position: relative !important;
          }
        }
      }
    }
    @media screen and (max-width: 1170px) {
      width: 100%;
    }
    @media screen and (max-width: 426px) {
      padding: 16px 16px 16px 20px;
    }
  }
}

.profile-setting {
  display: flex;
  padding: 60px 112px 120px 112px;
  flex-direction: column;
  align-items: flex-start;
  gap: 60px;
  align-self: stretch;
  @media screen and (max-width: 1024px) {
    padding: 40px 24px;
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 1024px) {
      > div:last-child {
        padding-top: 100px;
      }
    }

    &__avatar {
      @media screen and (max-width: 1024px) {
        top: 108px;
      }
    }
    &__join {
      @media screen and (max-width: 1024px) {
        top: 340px;
        height: 10px;
      }
      @media screen and (max-width: 767px) {
        top: 320px;
        height: 10px;
      }
      @media screen and (max-width: 448px) {
        top: 355px;
        height: 10px;
      }
      @media screen and (max-width: 426px) {
        top: 300px;
        height: 10px;
      }
      @media screen and (max-width: 390px) {
        top: 325px;
        height: 10px;
      }
      @media screen and (max-width: 340px) {
        top: 350px;
        height: 10px;
      }
    }
  }
  &__body {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    &__left {
      width: 25%;
      max-width: 280px;
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
    &__content {
      display: flex;
      padding: 0px 32px;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-left: 1px solid $grayEAE;
      width: 75%;
      max-width: calc(100% - 280px);

      @media screen and (max-width: 1400px) {
        max-width: 100%;
      }
      @media screen and (max-width: 1024px) {
        border-left: 0px;
        padding: 0px;
        margin: 0 auto;
        width: 100%;
        > div:last-child {
          width: 100%;
        }
      }

      > div:first-child {
        width: 100%;
      }

      &__basic-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        width: 70%;

        @media screen and (max-width: 1400px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          gap: 16px;
        }

        &__dou {
          display: flex;
          align-items: flex-start;
          gap: 24px;
          align-self: stretch;
          margin: 0 !important;
          width: 100%;

          @media screen and (max-width: 1024px) {
            justify-content: space-between;
          }

          @media screen and (max-width: 767px) {
            flex-direction: column;
            gap: 16px;
          }

          > input {
            min-width: 26px;
          }

          > div {
            width: 50%;
            max-width: 320px;
            @media screen and (max-width: 767px) {
              width: 100%;
              max-width: 100%;
            }
            > div:first-child {
              color: #101828;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }
          }
        }
        &__dou input {
          min-height: 26px;
          max-height: 26px;
        }
        &__item {
          width: 100%;
          max-width: 664px;
          margin-bottom: 0rem;
          position: relative;

          @media screen and (max-width: 1024px) {
            max-width: 100%;
          }
        }
        &__item input {
          min-height: 26px;
        }
        &__err {
          color: #f04438;
          margin-top: 4px;
          font-size: 0.875rem;
          line-height: 20px;
        }
        &__label {
          color: #101828;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
        &__group {
          display: flex;
          align-items: stretch;
        }
        &__selection {
          width: 110px;
          flex-shrink: 0;
          > div:first-child {
            > div {
              border-top-right-radius: 0px !important;
              border-bottom-right-radius: 0px !important;
              > div {
                padding-right: 14px !important;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
              }
            }
          }
        }
        &__inputting {
          flex-grow: 1;
          > div {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            padding: 8px 14px;
          }
        }
        &__option-flag {
          width: 22px;
          height: 22px;
          > span {
            position: relative !important;
            width: 22px !important;
            height: 22px !important;
          }
        }
        &__area-code-option {
          width: auto;
          display: flex;
          align-items: center;
          column-gap: 4px;
          font-weight: normal;
        }
        &__phone {
          color: var(--gray-900, #101828);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
        &__check {
          position: absolute;
          right: 14px;
          bottom: 8px;
          display: flex;
          padding: 4px 10px 4px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 16px;
          background: #eff8ff;
          max-width: 90px;
          color: #175cd3;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          > span {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
      &__basic-bot {
        width: 100%;
        max-width: 664px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;

        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }

        @media screen and (max-width: 476px) {
          flex-direction: column;
          gap: 32px;
          > div:last-child {
            width: 100%;
          }
        }

        &__sign-out {
          display: flex;
          padding: 12px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid #fda29b;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: var(--error-700, #b42318);
          cursor: pointer;
          @media screen and (max-width: 476px) {
            order: 1;
            width: 100%;
            border: 0px;
            box-shadow: none;
          }
        }
        &__save {
          padding: 12px 32px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $green009;
          background: $green009;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: $whiteFFF;
          font-weight: 700;
          line-height: 24px;
          @media screen and (max-width: 476px) {
            width: 100%;
          }
        }
      }
      &__per-top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        width: 70%;

        @media screen and (max-width: 1500px) {
          width: 100%;
        }
        @media screen and (max-width: 767px) {
          gap: 16px;
        }

        &__item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;
          align-self: stretch;
          width: 100%;
          max-width: 664px;

          @media screen and (max-width: 1176px) {
            max-width: 100%;
          }

          > div:first-child {
            color: #101828;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
          }
        }
        &__select {
          width: 100%;
          > div {
            width: 100% !important;
          }
        }
      }
      &__per-bot {
        width: 70%;
        max-width: 664px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: 'flex-end';
        color: var(--White, #fff);
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;

        @media screen and (max-width: 1500px) {
          width: 100%;
        }
        @media screen and (max-width: 1500px) {
          width: 100%;
          max-width: 100%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          margin: 0 auto;
          margin-top: 40px;
        }

        &__btn {
          display: flex;
          padding: 12px 32px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: rgb(0, 167, 173);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.custom-input-profile {
  > div {
    > div:last-child {
      > div {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }
    }
  }
}

.custom-left-mobile-profile {
  width: 100%;
  margin-bottom: 0rem;
  position: relative;
  margin: 0 auto;
  width: 75%;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.custom-select-profile {
  width: 100%;

  > div:first-of-type {
    > div:first-of-type:hover {
      border-color: $green00A;
      box-shadow: none;
    }
    > div {
      max-height: 185px;
    }
    div:first-of-type {
      border-radius: 8px;
      box-shadow: none;
      border-color: $grayD0D;
      max-height: 185px !important;

      > div[class*='ValueContainer']:first-child {
        padding: 8px 14px !important;
        gap: 8px;
        border-radius: 8px;
        color: #344054;
        box-shadow: none;
        border-color: $grayD0D !important;

        @media screen and (max-width: 767px) {
          padding: 8px 10px !important;

          > div:last-of-type {
            display: none;
          }
        }

        > div[class*='multiValue'] {
          max-width: 180px;
          border-radius: 6px;
          padding: 2px 8px 2px 8px !important;
          justify-content: center;
          align-items: center;
          background: #f2f4f7;
          margin: 0px;
          text-align: center;
          font-weight: 600;
          line-height: 20px;

          @media screen and (max-width: 1176px) {
            justify-content: space-between;
            max-width: 150px;
            gap: 2px;
            padding: 2px 6px 2px 6px !important;
          }

          @media screen and (max-width: 767px) {
            max-width: 30%;
            min-width: 80px;
          }
        }
      }
      > div:last-child {
        > div:first-child {
          display: none;
        }
        > span {
          display: none;
        }
      }
    }
  }
}
