.related-article {
  background-color: $whiteFFF;
  padding: 0 16px;
  margin-bottom: 32px;
  .article {
    margin: 12px 0;
  }
  .article-thumb {
    width: 100%;
    height: 229px;
    position: relative;
    overflow: hidden;
    img {
      border-radius: 8px 8px 0 0;
    }
  }
  .article-author {
    margin-top: auto;
  }
  h3 {
    font-size: convert-rem(30);
    line-height: 38px;
  }

  @media screen and (max-width: 992px) {
    .article {
      height: 360px;
      &-detail {
        height: calc(100% - 160px);
      }
    }
    .article-thumb {
      height: 160px;
    }
  }

  @media screen and (max-width: 767px) {
    h3 {
      font-size: convert-rem(24);
    }
    .ra-snippet:nth-child(odd) {
      padding-right: 7.5px;
    }
    .ra-snippet:nth-child(even) {
      padding-left: 7.5px;
    }

    .rec-arrow-left,
    .rec-arrow-right {
      left: 10px;
      z-index: 99;
      height: 32px;
      width: 32px;
      font-size: convert-rem(18);
      line-height: 25px;
    }
    .rec-arrow-right {
      left: auto;
      right: 10px;
      &:before {
        left: 11px;
      }
    }
    .rec-arrow-left:before {
      left: 9px;
    }

    .rec-carousel-item .related-post .favorite {
      width: 30px;
      height: 30px;
      font-size: 15px;
      right: 10px;
      top: 10px;
    }

    .setOdd {
      padding: 0 7.5px;
    }
  }
}

.articles-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 16px;
    margin-top: 16px;
  }
  &__carousel {
    overflow-x: hidden;
    margin: 0 -24px;
    .article-slide {
      display: flex;
      align-items: center;
      column-gap: 32px;
      .article {
        width: 25%;
        &-big {
          width: 50% !important;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    &__carousel {
      .article-slide {
        column-gap: 16px;
        .article {
          width: 33.33%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &__carousel {
      .article-slide {
        column-gap: 16px;
        .article {
          width: 50%;
        }
      }
    }
  }
}

// BANNER CAROUSEL
.banner-slide {
  width: 100%;
  .slide-item {
    position: relative;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, transparent 0, rgba(0, 0, 0, 0.72));

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    &__hero {
      position: relative;
      width: 100%;
      height: 560px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    &__content {
      width: 100%;
      height: 100%;

      position: absolute;
      bottom: 0;
      left: 0;

      padding-bottom: 72px;
      z-index: 2;
      display: flex;
      align-items: flex-end;
      .content-wrapper {
        max-width: 54%;
        width: 100%;
        margin: 0 120px;
        .article-category {
          width: fit-content;
          background: rgba(0, 0, 0, 0.25);

          border-radius: 8px;
          overflow: hidden;

          padding: 8px 16px;
          font-size: convert-rem(18);
          line-height: 24px;
          font-weight: 700;
          color: $whiteFFF;

          margin-bottom: 12px;
          &:hover {
            background: rgba(0, 0, 0, 0.5);
          }
        }
        .article-name {
          font-size: convert-rem(40);
          font-weight: 700;
          line-height: 56px;
          color: $whiteFFF;
          margin-bottom: 0;
        }
        a:hover {
          text-decoration: none;
          color: unset;
        }
      }
    }

    @media screen and (max-width: 991px) {
      &__content {
        .content-wrapper {
          max-width: 100%;
          margin: 0 48px;
          .article-category {
            font-size: convert-rem(16);
          }
          .article-name {
            font-size: convert-rem(36);
            line-height: 44px;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      &__hero {
        height: 320px;
      }
      &__content {
        padding-bottom: 40px;
        .content-wrapper {
          margin: 0 24px;
          .article-category {
            font-size: convert-rem(12);
            padding: 6px 12px;
            margin-bottom: 6px;
          }
          .article-name {
            font-size: convert-rem(18);
            line-height: 28px;
          }
        }
      }
    }
  }
  &__navigation {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 9;

    border: 0;
    border-radius: 50%;
    background-color: $whiteFFF;

    width: 40px;
    height: 40px;
    box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &:hover {
      svg {
        color: $cyan00CB;
      }
    }
    svg {
      color: $black344;
      width: 18px;
      height: 18px;
    }
    &--prev {
      left: 64px;
    }
    &--next {
      right: 64px;
    }

    @media screen and (max-width: 991px) {
      width: 36px;
      height: 36px;
      &--prev {
        left: 24px;
      }
      &--next {
        right: 24px;
      }
    }
    @media screen and (max-width: 767px) {
      width: 32px;
      height: 32px;
      &--prev {
        left: 16px;
      }
      &--next {
        right: 16px;
      }
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-pagination {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    &-bullet {
      background-color: $whiteFFF;
      opacity: 0.5;
      &-active {
        width: 12px;
        height: 12px;
        background-color: $whiteFFF;
        opacity: 1;
      }
    }
  }
}

// DESTINATION CAROUSEL
.desti-snippet-wrapper .rec-item-wrapper {
  display: inline-block;
}

.destinations-block {
  &__carousel {
    overflow: hidden;
    margin: 0 -24px;
    .swiper-carousel {
      padding: 24px;
    }
    .destination-slide {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-bottom: -24px;
      margin-left: -32px;

      .city {
        width: 33.33%;
        padding-left: 32px;
        padding-bottom: 24px;
        flex-shrink: 0;
        z-index: 4;
        &-parent {
          position: relative;
          top: 0;
          transition: all ease 0.3s;
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
          &:before {
            content: "";
            width: 100%;
            border-radius: 8px;
            height: 60%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              180deg,
              transparent 0,
              transparent 19%,
              transparent 20%,
              $black000 99%,
              $black000
            );
            transition: all ease 0.3s;
            display: inline-block;
            z-index: 1;
          }
          &:hover {
            top: -10px;
            box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
            &:before {
              height: 80%;
            }
          }
        }
        &-wrapper {
          border-radius: 8px;
          // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
          position: relative;
          top: 0;
          transition: all ease 0.3s;
        }
        &-thumb {
          width: 100%;
          height: 200px;
          position: relative;
          overflow: hidden;
          border-radius: 8px;
          // &-new {
          //   &-text {
          //     position: absolute;
          //     top: 50%;
          //     left: 32px;
          //     transform: translate(0, -50%);
          //     color: $whiteFFF;
          //   }
          //   > div {
          //     display: block;
          //     background: $whiteFFF;
          //     width: 200px;
          //     box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
          //     -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);

          //     position: relative;
          //   }

          //   /* ----- Ribbon Container ---------------------------------------*/
          //   > div > div {
          //     background: transparent;
          //     height: 200px;
          //     width: 85%;
          //     position: absolute;
          //     top: -4px;
          //     left: 224px;
          //     overflow: hidden;
          //   }

          //   > div > div:before {
          //     content: '';
          //     display: block;
          //     -webkit-border-radius: 10px 10px 0px 0px;
          //     border-radius: 10px 10px 0px 0px;
          //     width: 40px;
          //     height: 5px;
          //     position: absolute;
          //     right: 45px;

          //     background: #7a271a; /* Old browsers */
          //   }

          //   > div > div:after {
          //     content: '';
          //     display: block;
          //     -webkit-border-radius: 0px 10px 10px 0px;
          //     border-radius: 0px 10px 10px 0px;
          //     width: 6px;
          //     height: 50px;
          //     position: absolute;
          //     right: 0px;
          //     top: 35px;

          //     background: #7a271a; /* Old browsers */
          //   }

          //   /* ----- Ribbon ---------------------------------------*/
          //   > div > div > div {
          //     width: 150px;
          //     height: 28px;
          //     position: relative;
          //     top: 30px;
          //     right: -85px;
          //     z-index: 1;
          //     overflow: hidden;
          //     transform: rotate(45deg);
          //     -ms-transform: rotate(45deg); /* IE 9 */
          //     -moz-transform: rotate(45deg); /* Firefox */
          //     -webkit-transform: rotate(45deg); /* Safari and Chrome */
          //     -o-transform: rotate(45deg); /* Opera */

          //     background: #f04438; /* Old browsers */
          //   }

          //   /* ----- Top Glare ---------------------------------------*/
          //   > div > div > div:before {
          //     content: '';
          //     display: block;
          //     height: 72px;
          //     width: 13px;
          //     position: relative;
          //     top: -13px;
          //     left: 27px;
          //     transform: rotate(45deg);
          //     -ms-transform: rotate(45deg);
          //     -moz-transform: rotate(45deg);
          //     -webkit-transform: rotate(45deg);
          //     -o-transform: rotate(45deg);
          //   }

          //   /* ----- Right Clear ---------------------------------------*/
          //   > div > div > div:after {
          //     content: '';
          //     display: block;
          //     height: 72px;
          //     width: 13px;
          //     position: relative;
          //     bottom: 90px;
          //     left: 154px;
          //     transform: rotate(45deg);
          //     -ms-transform: rotate(45deg);
          //     -moz-transform: rotate(45deg);
          //     -webkit-transform: rotate(-45deg);
          //     -o-transform: rotate(45deg);
          //   }
          // }
        }
        &-detail {
          width: 100%;

          position: absolute;
          left: 50%;
          bottom: 16px;
          z-index: 2;
          transform: translate(-50%, 0);
          text-align: center;
          color: $whiteFFF;
        }
        &-name {
          font-size: convert-rem(24);
          font-weight: 700;
          line-height: 36px;
          margin-bottom: 2px;
        }
        &-country {
          font-size: convert-rem(18);
          line-height: 30px;
        }
        &-new-ribbon {
          position: absolute;
          right: -5px;
          top: -5px;
          width: 75px;
          height: 75px;
          margin-left: auto;
          z-index: 9;
        }

        @media screen and (max-width: 992px) {
          padding-left: 24px;
        }
      }

      @media screen and (max-width: 992px) {
        margin-left: -24px;
      }
      @media screen and (max-width: 767px) {
        margin-bottom: -16px;
        margin-left: -16px;
        .city {
          width: 50%;
          padding-left: 16px;
          padding-bottom: 16px;
          &-name {
            font-size: convert-rem(18);
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 2px;
          }
          &-country {
            font-size: convert-rem(16);
            line-height: 24px;
          }
        }
      }
      @media screen and (max-width: 600px) {
        .city-new-ribbon {
          width: 60px;
          height: 60px;
          top: -4px;
          right: -4px;
        }
      }
    }
  }
}

// CITY CAROUSEL
.city-carousel {
  background: $whiteFFF;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);

  ul.city-wrapper {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    li {
      width: 100%;
      .city-name {
        width: fit-content;
        min-width: 100px;
        color: $gray656;
        font-size: convert-rem(18);
        text-align: center;
        line-height: 24px;
        padding: 24px 24px;
        cursor: pointer;

        border-bottom: 3px solid $whiteFFF;
        transition: all 0.15s linear;
        -moz-transition: all 0.15s linear;
        -webkit-transition: all 0.15s linear;

        &:not(.active):hover {
          border-color: $cyan08C;
          color: $cyan0CC;
          text-decoration: none;
        }
        &.active {
          border-color: $cyan08C;
          color: $cyan0CC;
        }
      }
    }
    @media screen and (max-width: 767px) {
      li .city-name {
        padding: 16px;
        font-size: convert-rem(16);
        line-height: 24px;
      }
    }
  }
}

.dest-snippet {
  background-position: 50%;
  height: 200px;
  background-size: cover;
  width: 100%;
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-end;
  flex-flow: column;
  position: relative;
  overflow: hidden;
  padding-bottom: 15px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  top: 0;
}
.dest-snippet span:not(.dest-countryname) {
  z-index: 0;
}

.dest-snippet:hover {
  text-decoration: none;
  top: -10px;
  box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.16);
}

.dest-snippet:before {
  content: "";
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 19%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 19%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 19%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 1) 99%,
    rgba(0, 0, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60%;
  z-index: 1;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

.dest-snippet:hover:before {
  height: 90%;
}

.dest-snippet h3,
.dest-snippet span {
  position: relative;
  z-index: 99;
}

.dest-snippet h3 {
  color: $whiteFFF;
  font-size: 27px;
  margin-top: 5px;
  line-height: 34px;
  margin-bottom: 0;
}

.dest-snippet span {
  color: $whiteFFF;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 767px) {
  .dest-snippet {
    height: 165px;
  }

  .dest-snippet:before {
    height: 80%;
  }

  .dest-snippet h3 {
    font-size: 24px;
  }

  .dest-snippet span {
    font-size: 16px;
  }
}

.article-snippet {
  .rec-carousel-wrapper {
    width: 100%;
    height: 425px;
    .rec-slider-container {
      margin: 0;
    }
    .rec-carousel {
      position: relative;
    }
    .rec-arrow {
      &-right,
      &-left {
        position: absolute;
        left: 0;
        top: calc(50% - 20px);
        z-index: 9;
        height: 40px;
        width: 40px;
        text-align: center;
        background: $whiteFFF;
        border-radius: 50%;
        font-size: convert-rem(20);
        line-height: 38px;
        min-width: unset;
        color: $whiteFFF;
        box-shadow: 0 2px 11px -3px rgb(0 0 0 / 50%);
        -moz-box-shadow: 0 2px 11px -3px rgb(0 0 0 / 50%);
        -webkit-box-shadow: 0 2px 11px -3px rgb(0 0 0 / 50%);

        &:hover {
          background-color: $whiteFFF;
        }
      }
      &-right {
        left: auto;
        right: 16px;
        &:before {
          content: "\f105";
          position: absolute;
          left: 17px;
          top: 0;
          color: $gray656;
        }
      }
      &-left {
        left: 16px;
        &:before {
          content: "\f104";
          position: absolute;
          left: 16px;
          top: 0;
          color: $gray656;
        }
      }
      &:hover:enabled,
      &:focus:enabled {
        background: $whiteFFF !important;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5) !important;
        -webkit-box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5) !important;
        -moz-box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5) !important;
      }
      &:hover:before {
        color: $cyan00CB;
      }

      @media screen and (max-width: 767px) {
        left: 10px;
        z-index: 99;
        height: 32px;
        width: 32px;
        font-size: convert-rem(18);
        line-height: 25px;
        &-right {
          left: auto;
          right: 10px;
          &:before {
            left: 11px;
          }
        }
        &-left:before {
          left: 9px;
        }
      }
    }
    .rec-carousel-item .rec-item-wrapper img {
      height: 425px;
      object-fit: cover;
    }
    @media screen and (max-width: 767px) {
      height: 365px;
    }
  }
}

// Image Carousel
.images-carousel {
  .rec-carousel {
    .arrow-btn {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 0;

      background-color: $whiteFFF;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9;

      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 16px;
        height: 16px;
        color: $black101;
      }
      &.arrow-left-btn {
        left: 12px;
      }
      &.arrow-right-btn {
        right: 12px;
      }
    }
    .rec-slider-container {
      margin: 0;
    }
    .rec-slider {
      height: 320px;
      .image {
        position: relative;
        width: 100%;
        height: 320px;

        img {
          border-radius: 6px;
        }
      }
    }
  }
  .rec-pagination {
    display: none;
  }
}

.swiper-carousel {
  width: 100%;
  height: 100%;
  overflow: unset;
  position: relative;
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .swiper-navigation {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 99;

    width: 26px;
    height: 26px;
    border: 0;
    border-radius: 50%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whiteFFF;
    box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

    svg {
      width: 16px;
      height: 16px;
      color: $black000;
    }
  }
  &__prev {
    left: -12px;
  }
  &__next {
    right: -12px;
  }
}

.elastic-carousel {
  width: 100%;
  .rec-carousel-wrapper {
    .rec-pagination {
      display: none;
    }
    .rec-slider-container {
      margin: 0;
    }
    .rec-swipable {
      align-items: stretch;
    }
    .rec-carousel {
      position: relative;
      .arrow-btn {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;

        border-radius: 50%;
        border: 0;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

        background-color: $whiteFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        svg {
          width: 20px;
          height: 20px;
          color: $black667;
        }
        &.hidden {
          display: none;
        }
        &.show {
          display: flex;
        }
        &.arrow-right-btn {
          right: -24px;
        }
        &.arrow-left-btn {
          left: 12px;
        }

        @media screen and (max-width: 991px) {
          width: 36px;
          height: 36px;
          svg {
            width: 18px;
            height: 18px;
          }
        }

        @media screen and (max-width: 767px) {
          width: 32px;
          height: 32px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
