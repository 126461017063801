.destination-page {
  display: flex;
  flex-direction: column;
  row-gap: 48px;

  color: $black333;
  font-family: 'Nunito Sans', sans-serif;
  padding: 48px 0;

  @media screen and (max-width: 767px) {
    row-gap: 32px;
    padding: 32px 0 48px;
  }

  .destination-overview {
    position: relative;

    display: flex;
    flex-direction: column;
    row-gap: 32px;
    .destination-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      .destination-title {
        flex-grow: 1;
        font-size: convert-rem(48);
        line-height: 60px;
        letter-spacing: -0.96px;
        color: $black101;
        margin-bottom: 0;
        > span.highlight {
          color: $green009;
        }
      }
      .destination-collaboration {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        column-gap: 12px;
        p {
          flex-shrink: 0;
          margin-bottom: 0;
          color: $black667;
          font-size: convert-rem(18);
          font-weight: 700;
          line-height: 28px;
        }
        .collaboration-image {
          position: relative;
          width: calc(250px / 1.5);
          height: calc(150px / 1.5);
        }
      }
    }
    .destination-description p {
      color: $black667;
      font-size: convert-rem(18);
      line-height: 28px;
      margin-bottom: 0;
      display: block;
      min-height: 1em;
    }
    .destination-gallery {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      &__under {
        display: flex;
        justify-content: flex-end;

        .credit-text {
          font-size: convert-rem(16);
          line-height: 24px;
          color: $gray98A;
          font-weight: 700;
        }
      }
    }
    @media screen and (max-width: 1080px) {
      .destination-head {
        .destination-title {
          font-size: convert-rem(40);
          line-height: 56px;
        }
      }
      .destination-gallery {
        row-gap: 12px;
      }
    }
    @media screen and (max-width: 992px) {
      .destination-head {
        flex-direction: column;
        align-items: flex-start;
        .destination-collaboration {
          width: 100%;
          align-items: center;
        }
      }
    }
    @media screen and (max-width: 767px) {
      row-gap: 24px;
      .destination-head {
        .destination-title {
          font-size: convert-rem(30);
          line-height: 38px;
        }
        .destination-collaboration {
          p {
            font-size: convert-rem(16);
            line-height: 24px;
          }
          .collaboration-image {
            width: calc(250px / 2.5);
            height: calc(150px / 2.5);
          }
        }
      }
      .destination-description p {
        font-size: convert-rem(14);
        line-height: 20px;
      }
      .destination-gallery {
        &__under .credit-text {
          font-size: convert-rem(12);
          line-height: 24px;
        }
      }
      .discover-prayer-timings .prayer-timings {
        &__notify {
          font-size: convert-rem(14);
          line-height: 20px;
        }
      }
    }
  }
  .destination-prayer {
    overflow: hidden;
    .prayer-wrap {
      .prayer-timings {
        display: flex;
        flex-direction: column;
        &__location {
          color: $black101;
          font-size: convert-rem(20);
          line-height: 30px;

          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          column-gap: 12px;

          .view-other-prayer {
            font-size: convert-rem(16);
            line-height: 24px;
            font-weight: 700;
            color: $green00A;

            display: flex;
            align-items: center;
            column-gap: 4px;

            .icon {
              width: 32px;
              height: 32px;
              flex-shrink: 0;

              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px;
              svg {
                width: 20px;
                height: 20px;
              }
            }

            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          .text {
            margin-top: 4px;
            .city-country {
              color: $green009;
              font-weight: 700;
            }
          }
        }
        &__list {
          width: 100%;
          padding: 16px 0;
          .prayer-time {
            width: 100%;
            flex-shrink: 0;
            padding: 26px 24px 24px;
            background-color: $whiteFFF;

            border-radius: 16px;
            border: 1px solid $grayEAE;
            position: relative;
            &__name {
              color: $green009;
              font-size: convert-rem(24);
              font-weight: 700;
              line-height: 32px;
              text-transform: capitalize;

              margin-bottom: 8px;
            }
            &__hour {
              color: $black667;
              font-size: convert-rem(18);
              font-weight: 700;
              line-height: 28px;
            }
            &__upcoming {
              position: absolute;
              top: 0;
              right: 0;

              font-size: convert-rem(12);
              font-weight: 600;

              padding: 6px 8px;
              color: $whiteFFF;
              background-color: $green009;

              border-radius: 0 16px 0 16px;
              overflow: hidden;
            }
            &.active {
              background-color: $grayF2F;
              border-color: $grayEAE;
              .prayer-time__hour {
                color: $black667;
              }
            }
          }
        }
        &__notify {
          color: $black101;
          span {
            font-style: italic;
          }
        }

        @media screen and (max-width: 767px) {
          &__location {
            font-size: convert-rem(14);
            line-height: 24px;

            .view-other-prayer {
              > span {
                display: none;
              }
              .icon {
                border-radius: 50%;
                box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
              }
            }
          }
          &__list {
            .prayer-time {
              &__name {
                font-size: convert-rem(14);
                line-height: 20px;
                margin-bottom: 4px;
              }
              &__hour {
                font-size: convert-rem(12);
                line-height: 22px;
              }
              &__upcoming {
                padding: 4px 8px;
                font-size: convert-rem(10);
              }
            }
          }
          &__notify {
            font-size: convert-rem(12);
            line-height: 18px;
          }
        }
      }
    }
  }
  .destination-content {
    display: flex;
    flex-direction: column;
    row-gap: 48px;
  }
  .destination-essential {
    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      overflow: hidden;
    }
    .category-tabs {
      .category-list {
        display: flex;
        align-items: center;
        column-gap: 12px;
        overflow-x: auto;
        overflow-y: hidden;
        border-bottom: 1px solid $grayD0D;
        padding-bottom: 12px;
        &::-webkit-scrollbar {
          display: none;
        }
        .item {
          width: max-content;
          white-space: nowrap;
          min-width: 92px;
          padding: 8px 32px;
          font-weight: 600;
          text-align: center;

          border-radius: 8px;
          cursor: pointer;
          &:not(&-active):hover {
            background-color: $greenDCF;
          }
          &-active {
            background-color: $green009;
            color: $whiteFFF;
          }

          @media screen and (max-width: 767px) {
            font-size: convert-rem(16);
            line-height: 22px;
          }
        }
      }
    }
    .venue-locations {
      height: 620px;
      display: flex;
      align-items: stretch;
      column-gap: 16px;
    }
    .view-all {
      padding: 24px;
      text-align: center;

      .view-all-btn {
        @include secondary-green-button();
        background-color: $whiteFFF;
        border-color: $green00A;
        color: $green00A;
        font-size: convert-rem(14);
        line-height: 20px;
        padding: 8px 32px;
      }
    }
    &__venues {
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      &:after {
        content: '';
        width: calc(100% - 16px);
        height: 84px;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(255, 255, 255, 1) 100%
        );
        display: inline-block;

        position: absolute;
        bottom: -20px;
        left: 0;
      }
      .venues {
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        row-gap: 16px;

        margin-left: -24px;
        padding-right: 12px;
        position: relative;
        .venue {
          padding-left: 24px;
          &-wrap {
            display: flex;
            align-items: stretch;

            border: 1px solid $grayE2E;
            border-radius: 8px;
            overflow: hidden;
            min-height: 186px;

            cursor: pointer;
            &:hover,
            &.active {
              box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
              .img-wrap {
                transform: scale(1.12);
              }
            }
          }
          &-thumb {
            position: relative;
            flex-shrink: 0;
            overflow: hidden;

            .img-wrap {
              width: 279px;
              height: 100%;
              position: relative;
              transition: all ease 0.6s;
            }
            .sponsor-status {
              position: absolute;
              top: 16px;
              left: 16px;

              width: fit-content;
              padding: 8px 14px;
              border-radius: 8px;

              background-color: $greenDCF;
              border: 1px solid $greenDCF;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

              span {
                color: $green009;
                font-size: convert-rem(14);
                line-height: 20px;
                font-weight: 700;
              }
            }
          }
          &-favourite {
            width: 32px;
            height: 32px;

            position: absolute;
            top: 16px;
            right: 16px;

            border-radius: 50%;
            border: 0;
            color: $orangeFD6;

            display: flex;
            align-items: center;
            justify-content: center;

            &:hover .fa-heart-o:before {
              content: '\f004';
            }
            &.favourited .fa-heart-o:before {
              content: '\f004';
            }
          }
          &-detail {
            flex-grow: 1;
            border-left: 0;
            padding: 16px 16px 24px;

            display: flex;
            flex-direction: column;

            &__name {
              font-size: convert-rem(20);
              font-weight: 700;
              line-height: 30px;
              color: $black000;
            }
            &__rating {
              display: flex;
              align-items: center;
              column-gap: 8px;
              margin-top: 8px;

              .rating-stars {
                display: flex;
                align-items: center;
                column-gap: 8px;

                svg {
                  width: 16px;
                  height: 16px;
                }
              }
              .name-address {
                color: #475467;
              }
              .total-reviews {
                font-size: convert-rem(12);
                font-weight: 600;
                line-height: 18px;
                color: $black212;
              }
            }
            &__description {
              font-size: convert-rem(16);
              line-height: 24px;
              color: $black667;
              margin-top: 8px;
            }
          }
        }
      }
      > a {
        margin-top: auto;
      }

      @media screen and (max-width: 1080px) {
        flex-grow: 0;
        &:after {
          width: 32px;
          height: 100%;
          right: 0;
          top: 0;
          left: unset;
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 255, 255, 1) 100%
          );
        }
        .venues {
          height: auto;
          overflow-y: hidden;
          overflow-x: auto;
          flex-direction: row;
          column-gap: 18px;

          margin-bottom: 0;
          margin-left: 0;
          padding-bottom: 0;
          .venue {
            padding: 0 0 12px;
            &-detail {
              padding: 12px 12px 16px;
              &__name {
                font-size: convert-rem(16);
                line-height: 24px;
              }
              &__description {
                font-size: convert-rem(14);
                line-height: 20px;
              }
            }
            &-wrap {
              flex-direction: column;
              width: 274px;
              max-width: 100%;
              height: 100%;
              min-height: 320px;
            }
            &-thumb {
              .img-wrap {
                width: 100%;
                height: 148px;
              }
              .sponsor-status {
                padding: 4px 10px;
                span {
                  font-size: convert-rem(12);
                  line-height: 18px;
                }
              }
            }
          }
        }
        .view-all {
          width: 100%;
          > a {
            width: fit-content;
            min-width: 220px;
            padding: 0;
          }
        }
      }

      @media screen and (max-width: 767px) {
        .view-all {
          > a {
            width: 100%;
            min-width: 220px;
          }
        }
      }
    }
    &__map {
      width: 540px;
      flex-shrink: 0;

      position: relative;
      border-radius: 8px;
      overflow: hidden;
      .gm-style {
        .gmnoprint {
          display: none;
        }
      }
      .gm-style-cc {
        display: none;
      }
    }

    @media screen and (max-width: 1080px) {
      .venue-locations {
        height: 490px;
      }
      &-content {
        flex-direction: column;
        height: auto;
      }
      &__map {
        width: 440px;
      }
    }

    @media screen and (max-width: 1080px) {
      .venue-locations {
        flex-direction: column-reverse;
        row-gap: 24px;
        height: auto;
      }
      &__map {
        width: 100%;
        height: 380px;
      }
    }

    @media screen and (max-width: 767px) {
      &__map {
        height: 260px;
      }
    }
  }
  .destination-handbook {
    &-content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .handbooks {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        column-gap: 24px;
        padding-bottom: 12px;

        &-block {
          width: 100%;
          max-width: 25%;
          min-width: 277px;
          position: relative;
          &__content {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 16px;

            overflow: hidden;
            border-radius: 8px;
            .handbook-icon {
              width: 40px;
              height: 40px;
              flex-shrink: 0;
              margin-bottom: 12px;
            }
            .handbook-title {
              font-weight: bold;
              font-size: convert-rem(18);
              color: $cyan029;
              line-height: 21px;
              margin-bottom: 12px;
            }
            .handbook-description {
              margin-top: auto;
              margin-bottom: 12px;
            }
          }
          &--green {
            background-color: $cyanE6F;
            .handbook-title {
              color: $green026;
            }
          }
          &--yellow {
            background-color: #faf6e6;
            .handbook-title {
              color: $orangeBD7;
            }
          }
          &--pink {
            background-color: #f8e9f5;
            .handbook-title {
              color: $purpleAE0;
            }
          }
          &--blue {
            background-color: #ebf7fd;
            .handbook-title {
              color: $blue017;
            }
          }
        }
      }

      @media screen and (max-width: 1279px) {
        margin: 0 -24px;
        .wrap {
          padding: 0 24px;
        }
        .handbooks {
          flex-wrap: nowrap;
          overflow: auto;
          padding-right: 12px;
        }
      }
    }
  }
  .destination-itineraries {
    &__description {
      max-width: 680px;
      width: 100%;

      font-size: convert-rem(18);
      line-height: 27px;
      margin-bottom: 24px;
    }
    &__articles {
      padding-bottom: 12px;
      overflow-x: hidden;
      margin: 0 -24px;

      @media screen and (max-width: 1023px) {
        margin: 0;
      }

      .swiper-carousel {
        padding: 0 24px;

        @media screen and (max-width: 1023px) {
          padding: 0;
        }

        .swiper-navigation {
          width: 40px;
          height: 40px;
          box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);
        }
        .swiper-carousel__next {
          right: 4px;
        }
        .swiper-carousel__prev {
          left: 4px;
        }
      }
      .inline-article {
        height: 346px;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &__shadow {
          position: absolute;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.5) 0%,
            rgba(0, 0, 0, 0.5) 100%
          );
          z-index: 1;
        }
        &__thumb {
          width: 100%;
          height: 100%;

          position: absolute;
          top: 0;
          left: 0;

          img {
            transition: all ease 0.6s;
            border-radius: 8px;
          }
        }
        &__detail {
          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          position: relative;
          z-index: 9;
          color: $whiteFFF;

          padding: 16px;
          margin-top: auto;
        }
        &__name {
          font-size: convert-rem(20);
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 8px;
        }
        &__description {
          font-size: convert-rem(16);
          line-height: 24px;
          color: $whiteF6F;
          margin-bottom: 24px;
        }
        &__author {
          display: flex;
          align-items: center;
          column-gap: 16px;

          .author-avt {
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            border-radius: 50%;
            overflow: hidden;
            background-color: $whiteFFF;
          }
          .author-info {
            line-height: 24px;
            .author-name {
              font-weight: bold;
              margin-bottom: 4px;
              font-size: convert-rem(16);
            }
          }
          .author-date-posted {
            color: $whiteF6F;
          }
        }
        &:hover {
          .inline-article__thumb img {
            transform: scale(1.25);
          }
        }
      }
      .view-all {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        .view-all-btn {
          max-width: 277px;
          width: 100%;
          margin: 0 auto;
          @include secondary-green-button();
        }
      }
    }

    @media screen and (max-width: 767px) {
      &__description {
        font-size: convert-rem(16);
      }
    }
  }
  .destination-stories {
    .featured-stories {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: flex-start;
      column-gap: 32px;

      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 12px;

      .story {
        max-width: 25%;
        min-width: 252px;
        position: relative;
        cursor: pointer;
        &__image {
          width: 215px;
          height: 215px;

          border-radius: 50%;
          overflow: hidden;

          position: absolute;
          z-index: 9;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          img {
            transition: all ease 600ms;
          }
        }
        &__detail {
          height: calc(100% - 128px);
          background-color: $green026;
          color: $whiteFFF;
          border-radius: 8px;
          overflow: hidden;

          margin-top: 128px;
          padding: 100px 16px 16px;

          display: flex;
          flex-direction: column;

          .story-name {
            font-size: convert-rem(18);
            font-weight: bold;
            line-height: 28px;
            margin-bottom: auto;
          }
          .story-line {
            width: 100%;
            height: 1px;
            background-color: $cyan029;

            margin: 12px 0;
          }
          .story-author {
            display: flex;
            align-items: center;
            column-gap: 12px;

            &__avt {
              width: 40px;
              height: 40px;

              flex-shrink: 0;
              overflow: hidden;
              border-radius: 50%;
              background-color: $whiteFFF;
            }
            &__info {
              line-height: 22px;
              font-size: convert-rem(14);
              .author-name {
                font-size: convert-rem(14);
                font-weight: bold;
                margin-bottom: 2px;
              }
            }
          }
        }
        &:hover {
          .story__image img {
            transform: scale(1.25);
          }
        }

        @media screen and (max-width: 767px) {
          width: 252px;
        }
      }
    }
  }
  .destination-explore {
    .explores {
      display: flex;
      align-items: center;
      gap: 24px;

      overflow-x: auto;
      overflow-y: hidden;
      padding-bottom: 12px;
      .explore-item {
        flex-shrink: 0;
        max-width: 277px;
        width: 100%;
        position: relative;

        overflow: hidden;
        border-radius: 12px;
        cursor: pointer;
        &:hover {
          .explore-item__thumb {
            transform: scale(1.2);
          }
        }
        &__thumb {
          height: 350px;
          width: 100%;

          background-image: url('/images/destination-tokyo.jpg');
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          z-index: 1;

          transition: all ease 600ms;

          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(
              360deg,
              rgba(0, 0, 0, 0.6) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }
        }
        &__info {
          width: 100%;

          color: $whiteFFF;
          text-transform: capitalize;
          position: absolute;
          z-index: 9;
          bottom: 0;
          left: 0;

          padding: 16px;
          .explore-city {
            font-size: convert-rem(24);
            font-weight: bold;
            line-height: 36px;
            margin-bottom: 4px;
          }
          .explore-country {
            font-size: convert-rem(18);
            font-weight: 600;
            line-height: 27px;
            margin-bottom: 0;
          }
        }
      }

      @media screen and (max-width: 1080px) {
        gap: 24px;
      }
    }
  }
  .destination-explores {
    overflow: hidden;
    .title {
      color: $black101;
    }
    .explore {
      width: 100%;
      max-width: 280px;

      position: relative;
      border-radius: 16px;
      border: 1px solid $grayEAE;
      overflow: hidden;

      cursor: pointer;
      &__thumb {
        width: 100%;
        height: 279px;
        position: relative;
        // &:before {
        //   content: "";
        //   width: 100%;

        //   position: absolute;
        //   top: 50%;
        //   left: 50%;
        //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%);
        //   z-index: 1;

        // }
        img {
          transition: all ease 0.6s;
        }
      }
      &__destination {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 2;

        padding: 24px 8px 12px;

        display: flex;
        align-items: center;
        justify-content: center;

        color: $whiteFFF;
        font-size: convert-rem(24);
        font-weight: 800;
        line-height: 32px;
        text-align: center;

        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.6) 0,
          rgba(0, 0, 0, 0.4) calc(100% - 24px),
          rgba(0, 0, 0, 0) 100%
        );
      }
      &:hover .explore__thumb img {
        transform: scale(1.25);
      }
    }
  }
  .destination-block {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    &.destination-explore {
      padding-top: 40px;
    }
    &__title {
      font-size: convert-rem(24);
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 24px;
      color: $black475;
    }

    @media screen and (max-width: 991) {
      &__title {
        font-size: convert-rem(20);
        line-height: 30px;
        margin-bottom: 24px;
      }
    }

    @media screen and (max-width: 767px) {
      &__title {
        font-size: convert-rem(18);
        line-height: 24px;
      }
    }
  }
  .destination-image {
    &__img {
      max-width: 100%;
      width: auto;
      height: auto;
      display: block;
    }
    max-width: 100%;
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Nunito Sans', sans-serif;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  a {
    color: unset;
    &:hover {
      text-decoration: none;
    }
  }
}

.destination-top-header {
  width: 100%;
  background-color: $whiteFFF;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transform: translateX(-100%);

  font-family: 'Nunito Sans', sans-serif;
  font-size: convert-rem(16);
  line-height: 24px;
  &.sticky {
    transform: translateX(0);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
    @media screen and (max-width: 992px) {
      .top-header-list .top-header-item.item-collapsed {
        display: block;
      }
    }
  }

  .top-header-wrapper {
    width: 100%;
    max-width: 1280px;
    padding: 0 24px;
    margin: 0 auto;
  }
  .top-header-list {
    display: flex;
    align-items: center;
    column-gap: 32px;
    .top-header-item {
      &:not(.item-block-list) {
        flex-shrink: 0;
      }
      &.item-block-list {
        flex-grow: 1;
      }
      &.item-collapsed {
        display: none;
      }
      &__logo {
        width: 50px;
        height: 46px;
        position: relative;
      }
      &__destination {
        font-size: convert-rem(20);
        font-weight: 700;
        line-height: 30px;
        color: $black101;
      }
      &__block-list {
        display: flex;
        align-items: stretch;
        justify-content: center;
        column-gap: 60px;
        .block-item {
          color: $gray98A;
          font-size: convert-rem(18);
          font-weight: 600;
          line-height: 28px;
          text-align: center;

          padding: 16px 0;
          position: relative;
          cursor: pointer;
          &.back-to-top {
            display: none;
          }
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;

            width: 0;
            height: 3px;
            background-color: $green00A;
            transition: width 0.6s ease;
          }
          &__active {
            color: $black101;
            &:before {
              width: 100%;
            }
          }
        }
      }
      .btn-back-to-top {
        @include primary-white-button();
        border: 0;
        color: $black667;
        font-size: convert-rem(14);
        line-height: 20px;
        box-shadow: none;
        padding: 12px 0;
      }
    }

    @media screen and (max-width: 1024px) and (min-width: 375px) {
      column-gap: 24px;
      .top-header-item {
        &__logo {
          width: 42px;
          height: 38px;
        }
        &__destination {
          font-size: convert-rem(18);
          line-height: 28px;
        }
        &__block-list {
          column-gap: 24px;
          .block-item {
            font-size: convert-rem(16);
            line-height: 24px;
          }
        }
      }
    }

    @media screen and (max-width: 992px) {
      .top-header-item {
        &.item-block-list .block-list-wrapper {
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;

          opacity: 0;
          background-color: $whiteFFF;
          .block-item {
            pointer-events: none;
          }
          &.block-popover {
            top: 100%;
            opacity: 1;
            box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
            .block-item {
              pointer-events: all;
            }
            .top-header-item__block-list {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 24px;
              .block-item {
                &:before {
                  content: unset;
                }
                &.back-to-top {
                  display: block;
                  margin-top: 24px;
                }
              }
            }
          }
        }
        &__collapsed {
          @include primary-white-button();
          border: 0;
          padding: 14px;
          box-shadow: none;
          margin-right: -14px;
          &.has-icon .icon {
            width: 24px;
            height: 24px;
            color: $black101;
          }
        }
        &__back-to-top {
          display: none !important;
        }
      }
    }
  }

  // Overlay
  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 7%, 0.6);
    z-index: 99;
  }
}
