.single-article-content {
  position: relative;

  padding: 0 0 24px;
  word-wrap: break-word;
  font-size: convert-rem(18);
  line-height: 30px;
  color: $black333;

  h2,
  h3 {
    scroll-margin-top: 96px;

    @include mobile {
      scroll-margin-top: 12px;
    }
  }

  p,
  div.article-tags {
    font-size: convert-rem(18);
    line-height: 30px;
    color: $black333;
    margin-top: 16px;
    display: block;
    a {
      color: $green7AD;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
        color: $cyan00CB;
        outline: none;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }

    em {
      margin-bottom: 10px;
      margin-top: 10px;
      font-style: italic;
      a {
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
      }
    }
  }
  div.article-tags {
    color: $gray666;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 40px;

    a {
      color: $black333;
      outline: none;
      text-decoration: none;
      background: rgba(153, 153, 153, 0.2);
      border-radius: 8px;
      padding: 8px 12px;
      font-size: convert-rem(16);
      line-height: 22px;
      margin-right: 10px;
      font-weight: 600;
      display: inline-block;
      margin-bottom: 8px;

      &:first-child {
        margin-left: 10px;
      }
    }
  }
  .article-join-channel {
    margin: 40px 0 16px;
    padding: 20px 20px 20px 34px;
    border-radius: 12px;
    background-color: $grayF9F;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      padding: 20px;
      align-items: start;
    }

    &__img {
      padding: 0 !important;
      margin: 0 !important;
    }

    &__title {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      color: $black101;
      margin: 0;

      &--desktop {
        display: block;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &--mobile {
        display: none;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }

      @media screen and (max-width: 1024px) {
        margin: 0 0 16px;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        width: 100%;
        flex: 1;
        margin: 0 auto;

        a {
          flex: 1;
        }
      }
    }

    &__btn {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 12px;
      padding: 14px;
      box-shadow: 0px 1.855px 3.71px -1.855px rgba(16, 24, 40, 0.06),
        0px 6px 12px -1.855px rgba(73, 84, 105, 0.12);
      margin-left: 16px;
      transition: all ease 0.25s;

      &-text {
        &--desktop {
          display: block;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        &--mobile {
          display: none;

          @media screen and (max-width: 768px) {
            display: block;
          }
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        opacity: 0.85;
      }

      span {
        font-size: 18px;
        color: $black101;
        margin-left: 8px;

        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
      }
    }
  }
  iframe,
  p iframe {
    display: block;
    border-radius: 8px;
    margin: 10px auto;
    max-width: 85%;
  }
  hr:not([class*='ad_line']) {
    border-top: 1px solid $grayC5C;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  i strong,
  em strong {
    font-style: unset;
  }
  h1 {
    font-size: convert-rem(32);
  }
  h2 {
    font-size: convert-rem(24);
    line-height: 32px;
    margin: 16px 0;
  }
  h3 {
    font-size: convert-rem(20);
    &.article-h3 {
      margin: 16px 0;
    }
  }
  h4 {
    font-size: convert-rem(16);
  }
  h5 {
    font-size: convert-rem(14);
    color: $black333;
    margin: 40px 0px 6px;
    font-weight: bold;
  }
  h6 {
    font-size: convert-rem(12);
    line-height: 18px;
  }
  figure {
    max-width: 728px;
    img {
      margin: 16px 0px 0px;
      max-width: 100%;
      height: auto;
    }
    figcaption {
      display: none;
    }
  }
  a {
    color: $cyan00CB;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $cyan00CB;
    }

    img {
      max-width: 100%;
      height: auto;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  ol,
  ul {
    padding-left: 16px;
    li {
      padding: 5px 0px;
      font-size: convert-rem(18);
      line-height: 30px;
    }
  }
  ol li {
    list-style: decimal;
  }
  ul li {
    list-style: disc;
  }
  div em {
    font-style: italic;
    font-size: convert-rem(16);
    a {
      font-weight: 600;
    }
  }
  > .nc_socialPanel {
    display: none !important;
  }
  img {
    max-width: 100%;
    height: auto;
    margin-bottom: 6px;
    margin-top: 10px;
    border-radius: 5px;
  }
  em {
    font-style: italic;
  }
  .article-section-inner-img > div {
    display: flex !important;
  }
  .article-inside-img {
    position: static !important;
    width: auto !important;
    height: auto !important;
  }
  .article-img-caption {
    margin: 0 !important;
    p {
      margin: 0 !important;
      font-size: convert-rem(16);
      color: $gray818;
    }
  }
  .article-snippet {
    border-radius: 16px;
    overflow: hidden;
    font-family: 'Nunito Sans', sans-serif;

    display: flex;
    flex-direction: column;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);

    margin: 32px auto;
    cursor: pointer;
    display: block;

    color: $black333;
    position: relative;
    &:hover {
      color: unset;
    }
    &__thumbnail {
      flex-shrink: 0;
      position: relative;
      .thumbnail-wrap {
        overflow: auto hidden;
        display: flex;
        flex-flow: row nowrap;
        scroll-snap-type: x mandatory;

        padding-bottom: 2px;
      }
      .img-wrapper {
        display: block;
        width: 100%;
        height: 380px;

        text-align: center;
        scroll-snap-align: center;
        flex: none;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        margin: 0;
        padding: 0;
        transition: all ease 600ms;
        border-radius: 0 !important;
      }
      .img-arrow {
        width: 40px;
        height: 40px;

        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        display: flex;
        align-items: center;
        justify-content: center;

        background: $whiteFFF;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);
        border: 0;
        border-radius: 50%;
        overflow: hidden;

        svg {
          width: 18px;
          height: 18px;
          color: $black101;
        }

        &__left {
          left: 24px;
        }
        &__right {
          right: 24px;
        }

        @media screen and (max-width: 767px) {
          width: 32px;
          height: 32px;
          &__left {
            left: 16px;
          }
          &__right {
            right: 16px;
          }
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    &__info {
      padding: 24px 16px;
      display: block;
      color: unset !important;
      &:hover,
      &:active {
        h2,
        .review,
        .address {
          color: $black333;
        }
      }
      .name {
        margin-top: 0;
        margin-bottom: 8px;
        color: $black212;

        @media screen and (max-width: 767px) {
          font-size: convert-rem(20);
          line-height: 24px;
        }
      }
      .review {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 16px;

        &__stars {
          display: flex;
          align-items: center;
          column-gap: 4px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
        &__count {
          font-weight: 600;
          font-size: convert-rem(14);
          line-height: 22px;
          color: $black212;
        }
      }
      .address {
        font-size: convert-rem(16);
        line-height: 24px;
      }
      .find-out {
        margin-top: 24px;
        .find-more-btn {
          @include primary-green-button();
          width: 100%;
          font-size: convert-rem(14);
          line-height: 20px;
        }
      }
    }
    &__interactive {
      position: absolute;
      top: 24px;
      right: 24px;
      z-index: 9;

      display: flex;
      column-gap: 12px;

      button {
        width: 42px;
        height: 42px;

        border-radius: 50%;
        border: 0;
        overflow: hidden;
        padding: 0;
        position: relative;
        z-index: 9;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &__status {
      position: absolute;
      top: 24px;
      left: 24px;

      background-color: $greenDCF;
      padding: 8px 14px;
      border-radius: 8px;
      border: 1px solid $greenDCF;
      .status {
        color: $green009;
        font-size: convert-rem(14);
        line-height: 20px;
        font-weight: 700;
        text-transform: capitalize;
      }
    }
  }
  .article-brand {
    margin: 24px 0;
    padding: 16px 24px;
    border-radius: 20px;
    overflow: hidden;

    background-color: rgba(153, 153, 153, 0.2);
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    max-width: 730px;
  }

  @media screen and (max-width: 767px) {
    max-width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    font-size: convert-rem(16);
    line-height: 24px;

    p,
    ul li,
    ol li {
      font-size: 16px;
      line-height: 24px;
    }
    h5 {
      font-size: convert-rem(24);
      line-height: 26px;
    }
    p iframe,
    iframe {
      max-width: 100%;
    }

    .article-snippet {
      &__thumbnail .img-wrapper {
        height: 320px;
      }
      &__interactive {
        top: 16px;
        right: 16px;
        button {
          width: 36px;
          height: 36px;
        }
      }
      &__status {
        top: 16px;
        left: 16px;
      }
      &__info {
        .name {
          font-size: convert-rem(18);
          line-height: 27px;
        }
        .address {
          font-size: convert-rem(14);
          line-height: 20px;
        }
      }
    }
  }

  .fucn-sec {
    position: sticky;
    top: 0;
    right: 0;
  }
}

.func-sec {
  margin-top: 24px;
  margin-left: 0px;

  @media screen and (max-width: 1280px) {
    margin-top: 20px;
  }

  @include small-tablet_under {
    display: none;
  }

  .quick-jump {
    &__header {
      margin-bottom: 18px;
      @include flex_center(center, flex-start);
      gap: 8px;

      p {
        font-size: convert-rem(16);
        font-weight: 700;
        color: $black101;
        margin-bottom: 0;
      }
    }

    &__body {
      p {
        margin-bottom: 12px;
        font-size: convert-rem(14);
      }

      .jump-list {
        max-height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $black344;
        }

        a {
          display: block;
          margin-bottom: 12px;
          font-size: convert-rem(14);
          color: $black101;
          text-decoration: none;
          transition: all 0.3s ease;

          @include tablet_under {
            margin-bottom: 8px;
          }

          &:hover {
            transform: translateY(-2px);
            transition: all 0.3s ease;
          }

          &.active {
            color: $cyan00CB;
          }
        }

        a.w-bullet[class*='h3-'] {
          &::before {
            content: '•';
            color: $black101;
            margin-right: 8px;
          }
        }
      }

      p,
      a {
        font-size: convert-rem(14);
        color: $black101;
      }
    }
  }

  .save-share {
    margin: 24px 0;
    padding: 24px 0;
    border-top: 1px solid rgba(208, 213, 221, 0.3);
    border-bottom: 1px solid rgba(208, 213, 221, 0.3);

    &__header {
      color: $black101;
      font-weight: 700;

      p {
        margin-bottom: 8px;
      }
    }
    &__body {
      position: relative;
      display: inline-flex;
      gap: 8px;
    }
    &__btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.028px;
      font-weight: 500;
      background-color: $black475;
      padding: 8px 12px;
      color: #fff;
      border-radius: 100px;
      cursor: pointer;
      border: none;
      outline: none;
      border: 2px solid transparent;
      transition: all ease 0.25s;

      &:active {
        border-color: #98a2b3;
        color: #fff !important;
      }

      &--copy {
        border-radius: 50%;
        padding: 8px;
        width: 44px;
      }

      span {
        margin-left: 8px;
        color: #fff !important;
      }
    }

    &__socials {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      margin-top: 4px;
      background-color: #fff;
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
        0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 12px;
      border: 1px solid #f2f4f7;
      opacity: 0;
      visibility: hidden;
      transform: translateY(8px);
      transition: all ease 0.25s;

      &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      &.above {
        top: auto;
        bottom: 100%;
        margin-top: 0px;
        margin-bottom: 4px;
      }

      ul {
        display: flex;
        flex-direction: column;
        align-items: start;
        padding: 4px 0;

        li {
          display: flex;
          width: 100%;
        }
      }

      &-btn {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 8px 12px !important;
        width: 100%;
        height: 40px !important;
        transition: all ease 0.25s;

        &:hover {
          background-color: #eafbfa !important;

          span {
            font-weight: 500;
            color: #101828;
          }

          .save-share__socials-btn-icon {
            background-color: $black101;
          }
        }

        &-icon {
          width: 24px;
          height: 24px;
          background-color: $black475;
          border-radius: 50%;
          transition: all ease 0.25s;
        }

        span {
          margin-left: 12px;
          font-size: 16px;
          color: #475467;
          transition: all ease 0.25s;
        }
      }
    }
  }
}

.func-sec-mobile {
  display: none;
  @include tablet_under {
    margin: 0 40px;
  }
  @include small-tablet_under {
    position: fixed;
    bottom: 30px;
    background: $cyan00B;
    z-index: 90;
    border-radius: 12px;
    padding: 12px 16px;
    left: 0;
    right: 0;
    margin: 0 auto;
    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    @include flex_center(stretch, center);
    max-width: calc(100% - 30px);

    @media screen and (min-width: 576px) {
      max-width: 540px;
    }

    > div {
      @include flex_center(center, center);
      gap: 8px;
      color: #fff;
      font-size: convert-rem(16);
    }
  }

  .divider {
    display: block;
    background: #fff;
    opacity: 0.5;
    width: 1px;
    margin: 0 32px;
  }
}

hr {
  border-top: 1px solid $grayC5C;
  margin-top: 20px;
  margin-bottom: 20px;
}

.single-article-content-wrapper {
  .container {
    padding-left: 0;
    padding-right: 0;

    @media screen and (min-width: 768px) {
      max-width: 992px;
      padding-left: 15px;
      padding-right: 15px;
    }

    @media screen and (min-width: 992px) {
      max-width: 1200px;
    }

    @media screen and (min-width: 1200px) {
      max-width: 1440px;
    }
  }
  .single-article-content-right {
    &__wrapper {
      position: sticky;
      top: 60px;
      max-height: 90vh;
      display: flex;
      flex-direction: column;
    }
  }
}

.quick-jump-overlay {
  opacity: 0 !important;
}

.quick-jump-drawer {
  box-shadow: none !important;
  border-top: 1px solid #eaecf0 !important;
}
