.basic-infomation {
  display: flex;
  flex-direction: column;
  border: 2px solid #eaecf0;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  .social-media {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // margin-bottom: 30px;
    text-align: center;
    .conten {
      font-size: 18px;
      font-weight: bold;
    }
    .row-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
      .unclaimed {
        border: 1px solid #00a7ad;
        border-radius: 10px;
        padding: 5px 10px;
      }
    }
  }
  .business-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    input[type='number'] {
      -moz-appearance: textfield; /* Remove arrows for Firefox */
      -webkit-appearance: none; /* Remove arrows for Chrome, Safari, Edge */
      appearance: none;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none; /* Remove spinner buttons for WebKit browsers */
      margin: 0; /* Remove margin for consistency */
    }
    .multi-select-container {
      width: 800px;
    }
    &.grid_input {
      display: grid;
      grid-template-columns: 1fr 4fr;
      gap: 5px;
      grid-gap: 50px;
      padding: 20px;
      select,
      textarea {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #eaecf0;
        font-size: 16px;
      }
    }
    .manage-photos {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 800px;
    }
    .line {
      width: 15px;
      height: 1px;
      background-color: #475467;
    }
    .row-price {
      width: 800px;
      padding: 10px 10px 10px 15px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      align-items: center;
      &.no-gap {
        gap: 0;
        justify-content: space-between;
        padding: 10px 0;
      }
      .row {
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        .select-price {
          border: 1px solid #d0d5dd;
          max-width: 70px;
          border-radius: 5px 0;
          min-height: 40px;
          background-color: #f9fafb;
        }
        .input-price {
          border: 1px solid #d0d5dd;
          border-radius: 0px 5px 5px 0px;
          width: 100%;
          min-width: 180px;
          max-width: 250px;
        }
      }
    }
    .input-basic {
      &.premind-url {
        height: 65px;
      }
      position: relative;
      border-radius: 5px;
      width: 800px;
      min-height: 40px;
      border: 1px solid #eaecf0;
      padding: 10px 20px 10px 10px;
      background-color: #fff;
      resize: none;
      overflow: hidden;
      font-size: 16px;
      line-height: 1.5;
      &.phone-code {
        width: 100px !important;
        border-radius: 5px 0px 0px 5px;
      }
      &.phone {
        border-radius: 0px 5px 5px 0px;
      }
      .input-basic-no-boder {
        position: relative;
        border: none;
      }
      .selected-options {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
      .selected-option {
        background-color: #f2f4f7;
        color: black;
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
      }
      .remove-option {
        background: none;
        border: none;
        color: black;
        font-size: 14px;
        cursor: pointer;
        padding: 0;
      }
      .remove-option:hover {
        color: #ff4d4f;
      }
      select.input-basic {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #eaecf0;
        font-size: 14px;
      }
      .row-select-photo {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        min-width: 250px;
        .select-left {
          display: flex;
          flex-direction: row;
          .icon-photo {
            display: flex;
            width: 40px;
            height: 40px;
            background-color: #d0d5dd;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
          }
          .column-photo {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: self-start;
            justify-content: center;
            .text-drag {
              font-weight: bold;
            }
          }
        }
        .select-file {
          border-radius: 10px;
          border: 1px solid #d0d5dd;
        }
        .upgrade {
          border-radius: 10px;
          border: 1px solid #009294;
          color: #009294;
        }
      }
      select.input-basic:focus {
        outline: none;
        border-color: #00a7ad;
      }
      .show-dropdow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .dropdown-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: calc(100% + 5px);
        left: 0;
        right: 0;
        border: 1px solid #eaecf0;
        border-radius: 5px;
        background-color: white;
        z-index: 10;
        max-height: 150px;
        overflow-y: auto;
      }
      .dropdown-options.hidden {
        display: none;
      }
      .dropdown-item {
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }
      .dropdown-item:hover {
        background-color: #f5f5f5;
      }
      .dropdown-item.disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
    .input-basic:focus {
      outline: none;
    }
    .upload-container {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      .file-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        width: 700px;
      }
      .upload-button {
        min-height: 40px;
        width: 100px;
        border: none;
        border-radius: 0px 10px 10px 0px;
      }
    }
    .prayer-facilities-options {
      display: flex;
      justify-self: start;
      gap: 10px;
      width: 800px;
    }
    .prayer-option.active {
      border-color: #00a7ad;
    }
    .prayer-option input {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 2px solid #d0d5dd;
      border-radius: 50%;
      background-color: white;
      position: relative;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    .prayer-option input:checked {
      border-color: #00a7ad;
      background-color: #00a7ad;
    }
    .prayer-option input:checked::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      border-radius: 50%;
    }
    .prayer-option {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
      border: 2px solid #d0d0d0;
      border-radius: 10px;
      padding: 10px 20px;
      transition: border-color 0.3s ease;
    }
    .prayer-option input:checked {
      color: #00a7ad;
      border-color: #00a7ad;
    }
    .prayer-option input:checked ~ .prayer-option {
      border-color: #00a7ad;
    }
    .prayer-option:hover {
      border-color: #d0d5dd;
    }
  }
  .days-selection {
    align-self: end;
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    .day-time-slot {
      align-self: end;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 800px;
      margin-bottom: 10px;
      gap: 20px;
      .row-file {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .image-waiting-upload {
        display: flex;
        flex-direction: row;
        max-width: 20px;
        max-height: 20px;
        .icon {
          display: flex;
          max-width: 20px;
          max-height: 20px;
        }
      }
      // .upload-button {
      //   font-size: 12px;
      // }
      .day-label {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 40px;
        .warning {
          color: #e41919;
        }
      }
      .day-label input {
        transform: scale(1.2);
        margin-right: 10px;
        /* Default styling for the checkbox */
        appearance: none;
        width: 20px;
        height: 20px;
        border: 2px solid #d0d5dd;
        border-radius: 5px;
        background-color: white;
        transition: background-color 0.3s ease, border-color 0.3s ease;
      }
      .day-label input:checked {
        background-color: #00a7ad;
        border-color: #00a7ad;
      }
      .day-label input:checked::after {
        content: '✓'; /* Unicode checkmark */
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: white;
      }
      .file-name {
        font-size: 14px;
      }
      .time-inputs {
        display: flex;
        gap: 20px;
        flex-grow: 1;
        justify-content: flex-end;
        align-items: center;
      }
      .time-inputs div {
        display: flex;
        align-items: center;
      }
      .time-inputs input[type='time'] {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 5px;
        font-size: 14px;
        // width: 100px;
        width: fit-content;
      }
      .time-inputs input[type='time']:disabled {
        background-color: #f5f5f5;
        color: #aaa;
        cursor: not-allowed;
      }
      .time {
        border: 1px solid #d0d5dd;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 0;
        width: 100px;
        .time[type='time'] {
          appearance: none; /* Tắt kiểu mặc định của trình duyệt */
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 150px; /* Độ rộng */
          height: 40px; /* Độ cao */
          padding: 5px 10px; /* Khoảng cách bên trong */
          font-size: 14px; /* Kích thước chữ */
          border: 1px solid #ccc; /* Đường viền */
          border-radius: 5px; /* Bo tròn */
          background-color: #f9f9f9; /* Màu nền */
          color: #333; /* Màu chữ */
          cursor: pointer;
        }
        &::-webkit-datetime-edit {
          padding: 0;
          margin: 0;
        }
        &::-webkit-datetime-edit-fields-wrapper {
          display: flex;
          justify-content: space-between;
        }
        &::-webkit-datetime-edit-hour-field,
        &::-webkit-datetime-edit-minute-field {
          margin: 0;
          padding: 0;
          text-align: center;
        }
        &::-webkit-datetime-edit-text {
          margin: 0;
          padding: 0;
        }
      }
      .text-open {
        font-size: 14px;
        color: #475467;
      }
    }
  }
}
// .business-name.grid_input {
//   display: grid;
//   grid: auto-flow dense / 1fr 2fr;
//   gap: 5px;
//   padding: 20px;
// }
.menu-listing-select {
  width: 800px;
}
@media (max-width: 1080px) {
  .menu-listing-select {
    width: 100%;
  }
  .basic-infomation {
    padding: 15px;
    margin-top: 15px;
    .title {
      font-size: 16px;
      margin-bottom: 25px;
    }
    .business-name {
      .multi-select-container {
        width: auto;
      }
      .row-price,
      .input-basic-no-boder,
      .input-basic,
      .prayer-facilities-options {
        width: 700px;
      }
      .upload-container .file-name {
        width: 570px;
      }
    }
    .days-selection {
      .day-time-slot {
        width: 100%;
        // display: inline;
        flex-direction: row;
        .time-inputs {
          // justify-content: flex-start;
          .time {
            width: 100px;
          }
        }
        .day-label input {
          transform: scale(1.1);
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .basic-infomation {
    padding: 10px;
    margin-top: 10px;
    .title {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .business-name {
      .select-photo-container {
        margin-top: 10px;
        width: 100% !important;
      }
      &.gallery {
        flex-direction: column;
        justify-content: center;
        height: 300px;
        align-items: stretch;
      }
      &.multi-select {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
      &.grid_input {
        display: flex;
        grid-gap: 5px;
        gap: 5px;
        flex-direction: column;
        &.input-start-strech {
          align-items: start;
        }
      }
      .row-price,
      .manage-photos {
        width: 500px;
        padding-right: 15px;
      }
      .input-basic,
      .prayer-facilities-options {
        width: 300px;
      }
      .row-price {
        &.row-direction {
          flex-direction: row;
          justify-content: flex-end;
          > input {
            border: 1px solid #d0d5dd;
            width: 200px;
          }
        }
        flex-direction: column;
        gap: 15px;
        .row {
          flex-direction: column;
          .select-price,
          .input-basic-no-boder {
            max-width: 100%;
          }
          .input-price {
            max-width: 100%;
          }
        }
      }
      .upload-container {
        flex-direction: row;
        .file-name {
          width: 200px;
        }
        .upload-button {
          width: 150px;
        }
      }
    }
    // .prayer-option {
    //   // flex-direction: column;
    //   // align-items: flex-start;
    // }
    .days-selection {
      .day-time-slot {
        // flex-wrap: wrap;
        flex-direction: row;
        .time-inputs {
          // justify-content: flex-start;
          width: 100%;
        }
        .day-label {
          flex-direction: row;
          gap: 5px;
          input {
            transform: scale(1);
          }
        }
        .time {
          width: 100%;
        }
      }
    }
  }
}
.Row-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .border-button {
    margin-top: 20px;
    border: 1px solid #d0d5dd;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #fff;
  }
  .save-edit {
    margin-top: 20px;
    margin-right: 30px;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #fff;
    color: #009294;
    border: 1px solid #009294;
    &:hover {
      background-color: #009294;
      color: #fff;
    }
  }
  .save-change {
    margin-top: 20px;
    border: 1px solid transparent;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #009294;
    color: #fff;
  }
  .loading-save {
    max-width: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}
@media (max-width: 480px) {
  .Row-button {
    flex-direction: column;
    .save-edit {
      margin-right: 0;
    }
    .space-between {
      display: flex;
      justify-content: space-between;
    }
  }
  .basic-infomation {
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    .title {
      font-size: 14px;
    }
    .title,
    .icon {
      margin-bottom: 10px;
      padding-top: 10px;
    }
    .business-name {
      &.input-start-strech {
        align-items: start;
      }
      &.display-column {
        display: flex;
        flex-direction: row;
        & > div:first-child {
          width: fit-content;
        }
      }
      &.grid_input {
        &.step-three {
          padding: 0 5px;
        }
        display: flex;
        flex-direction: column;
      }
      flex-direction: column;
      .manage-photos {
        width: 100%;
      }
      .row-price {
        flex-direction: column;
        align-items: start;
        width: 100%;
        .row {
          flex-direction: row;
          flex-wrap: nowrap;
          .select-price {
            max-width: 60px;
            font-size: 12px;
          }
          .input-price {
            width: 30px;
          }
        }
      }
      .input-basic-no-boder {
        width: 100%;
      }
      .row-direction {
        justify-content: flex-start !important;
        align-items: center;
      }
      .input-basic {
        &.premind-url {
          height: 90px;
        }
        display: flex;
        width: 100%;
        font-size: 12px;
        min-width: 200px;
        width: 350px;
        padding: 8px;
        &.phone-code {
          min-width: 80px;
          width: 100px !important;
          font-size: 18px;
        }
        &.phone {
          min-width: 80px;
          width: calc(350px - 80px - 20px) !important;
        }
      }
      .prayer-facilities-options {
        flex-wrap: wrap;
        gap: 5px;
      }
    }
    .upload-container {
      .file-name {
        width: 300px;
      }
      .upload-button {
        width: 100px;
        font-size: 9px;
      }
    }
    .prayer-option {
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 10px;
      font-size: 12px;
      input {
        width: 15px;
        height: 15px;
      }
    }
    .days-selection {
      .day-time-slot {
        flex-direction: row;
        gap: 10px;
        .day-label {
          flex-direction: row;
          gap: 0;
          align-items: center;
          font-size: 13px;
          input {
            width: 15px;
            height: 15px;
          }
          label {
            font-size: 12px;
          }
        }
        .time-inputs {
          flex-direction: row;
          gap: 30px;
          justify-self: end;
          pa input[type='time'] {
            width: 60px;
            font-size: 12px;
            padding: 5px;
          }
          .text-open {
            display: none;
          }
          .time {
            justify-content: center;
            width: 70px;
          }
        }
      }
    }
  }
}
