.venue-destination .venue {
  &-wrap {
    display: flex;
    align-items: stretch;
    min-height: 186px;

    border: 1px solid $grayE2E;
    border-radius: 8px;
    overflow: hidden;

    cursor: pointer;
    &:hover,
    &.active {
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
      .img-wrap {
        transform: scale(1.12);
      }
    }
  }
  &-thumb {
    position: relative;
    flex-shrink: 0;
    overflow: hidden;

    .img-wrap {
      width: 279px;
      height: 100%;
      position: relative;
      transition: all ease 0.6s;
    }
    .sponsor-status {
      position: absolute;
      top: 16px;
      left: 16px;

      width: fit-content;
      padding: 8px 14px;
      border-radius: 8px;

      background-color: $greenDCF;
      border: 1px solid $greenDCF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      span {
        color: $green009;
        font-size: convert-rem(14);
        line-height: 20px;
        font-weight: 700;
      }
    }
  }
  &-favourite {
    width: 32px;
    height: 32px;

    position: absolute;
    top: 16px;
    right: 16px;

    border-radius: 50%;
    border: 0;
    color: $orangeFD6;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-detail {
    flex-grow: 1;
    border-left: 0;
    padding: 16px 16px 24px;

    display: flex;
    flex-direction: column;

    &__name {
      font-size: convert-rem(20);
      font-weight: 700;
      line-height: 30px;
      color: $black000;
      margin-bottom: 0;
    }
    &__rating {
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin-top: 8px;

      .rating-stars {
        display: flex;
        align-items: center;
        column-gap: 8px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      .total-reviews {
        font-size: convert-rem(12);
        font-weight: 600;
        line-height: 18px;
        color: $black212;
      }
    }
    &__description {
      font-size: convert-rem(14);
      line-height: 20px;
      color: $black667;
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 1080px) {
    &-wrap {
      min-height: 294px;
      flex-direction: column;
    }
    &-thumb .img-wrap {
      width: 100%;
      height: 168px;
    }
  }

  @media screen and (max-width: 992px) {
    &-thumb {
      .img-wrap {
        height: 142px;
      }
      .sponsor-status {
        padding: 4px 10px;
        span {
          font-size: convert-rem(12);
          line-height: 18px;
        }
      }
    }
    &-detail {
      padding: 12px 12px 16px;
      &__name {
        font-size: convert-rem(16);
        line-height: 28px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &-thumb .img-wrap {
      height: 128px;
    }
  }
}
.venus-info__tabs .top-border-line {
  width: 100%;
  height: 2px; /* Adjust thickness */
  background-color: #ccc; /* Adjust color */
  margin-bottom: 16px; /* Space below the line */
}
.venue-destination-company .venue {
  display: flex;
  flex-wrap: wrap;
  align-items: self-start;
  justify-content: center;
  flex-direction: row;
  &-wrap {
    display: flex;
    align-items: stretch;
    min-height: 60px;
    // max-width: 500px;
    // min-width: 250px;
    width: 510px;

    // border: 1px solid $grayE2E;
    border-radius: 8px;
    overflow: hidden;

    cursor: pointer;
    &:hover,
    &.active {
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15);
      .img-wrap {
        transform: scale(1.12);
      }
    }
  }

  &-thumb {
    position: relative;
    flex-shrink: 0;
    overflow: hidden;

    .img-wrap {
      width: 150px;
      height: 100%;
      position: relative;
      transition: all ease 0.6s;
    }
    .sponsor-status {
      position: absolute;
      top: 16px;
      left: 16px;

      width: fit-content;
      padding: 8px 14px;
      border-radius: 8px;

      background-color: $greenDCF;
      border: 1px solid $greenDCF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

      span {
        color: $green009;
        font-size: convert-rem(14);
        line-height: 20px;
        font-weight: 700;
      }
    }
  }
  &-favourite {
    width: 32px;
    height: 32px;

    position: absolute;
    top: 16px;
    right: 16px;

    border-radius: 50%;
    border: 0;
    color: $orangeFD6;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-detail {
    flex-grow: 1;
    border-left: 0;
    padding: 16px 16px 24px;

    display: flex;
    flex-direction: column;

    &__name {
      font-size: convert-rem(20);
      font-weight: 700;
      line-height: 30px;
      color: $black000;
      margin-bottom: 0;
    }
    &__rating {
      display: flex;
      align-items: self-start;
      column-gap: 8px;
      margin-top: 8px;

      .rating-stars {
        display: flex;
        align-items: center;
        column-gap: 8px;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      .total-reviews {
        font-size: convert-rem(12);
        font-weight: 600;
        line-height: 18px;
        color: $black212;
      }
    }
    &__description {
      font-size: convert-rem(14);
      line-height: 20px;
      color: $black667;
      margin-top: 8px;
    }
  }

  @media screen and (max-width: 1080px) {
    &-wrap {
      min-height: 294px;
      max-width: 250px;
      flex-direction: column;
    }
    &-thumb .img-wrap {
      width: 100%;
      height: 168px;
    }
  }

  @media screen and (max-width: 992px) {
    &-thumb {
      .img-wrap {
        height: 142px;
      }
      .sponsor-status {
        padding: 4px 10px;
        span {
          font-size: convert-rem(12);
          line-height: 18px;
        }
      }
    }
    &-detail {
      padding: 12px 12px 16px;
      &__name {
        font-size: convert-rem(16);
        line-height: 28px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &-thumb .img-wrap {
      height: 128px;
    }
  }
}
.venue-destination-container {
  display: flex;
  align-items: center;
  gap: 12px;

  .venue-detail__status {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .venue-detail-last-update {
    text-align: center;
  }

  div {
    &.claimed {
      display: flex;
      flex-direction: row;
      padding: 10px;
      width: fit-content;
      gap: 10px;
      color: #000;
      background-color: #fff;
      border: 1px solid #c3c1c1;
      border-radius: 20px;
      font-weight: 600;
      text-align: center;
      .icon-check {
        color: #000;
      }
    }

    .profile__info__user__edit,
    .profile__info__user__more {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 5px 10px;
      border-radius: 8px;
      font-size: 14px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .profile__info__user__more {
      justify-content: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}
.listing-name-width {
  min-width: 520px;
}
.listing-review-width {
  min-width: 145px;
  max-width: 145px;
}
.listing-cat-width {
  min-width: 145px;
  max-width: 145px;
}
.listing-last-update-width {
  min-width: 170px;
  max-width: 170px;
}
.listing-action-width {
  min-width: 150px;
  max-width: 150px;
}
.no-gap {
  gap: 0;
}

.listing-container {
  overflow-x: auto;
}

.row-listing {
  color: #000;
  background-color: #f2f4f7;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
  &.fullwidth {
    width: fit-content;
  }
  .column-title {
    flex: 1;
    text-align: center;
    padding: 0 10px;

    &.listing-detail {
      text-align: left;
    }

    &.status {
      // padding-left: 270px;
      text-align: center;
    }

    &.category {
      // padding-right: 20px;
      text-align: center;
    }
    &.last-update {
      // padding-right: 0px;
      text-align: center;
    }
    &.action {
      // padding-right: 20px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .listing-name-width {
    min-width: 250px;
    max-width: 300px;
    padding: 0;
  }
  // .listing-review-width {
  //   min-width: 100px;
  //   max-width: 100px;
  // }
  .row-listing {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    .column-title {
      text-align: left;
      padding: 5px 0;

      // &.listing-detail {
      //   text-align: left;
      // }
      // &.status {
      //   padding-left: 130px;
      // }
      // &.action {
      //   padding-top: 5px;
      // }
    }
  }
}
@media screen and (max-width: 1080px) {
  .row-listing {
    flex-direction: row;
    align-items: flex-start;
    padding: 10px;

    .column-title {
      text-align: left;
      padding: 5px 0;

      // &.listing-detail {
      //   text-align: left;
      // }
      // &.status {
      //   padding-left: 150px;
      // }
      // &.action {
      //   padding-top: 5px;
      // }
    }
  }
}
