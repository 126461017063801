$cyan00C: #00cccc;
$cyan00CB: #00cccb;
$cyan0CC: #0cc;
$cyan08C: #08cccb;
$cyan5CC: #5cc9cb;
$cyan24D: #24d3d3;
$cyanB1E: #b1e1e2;
$cyanE6F: #e6f2f2;
$cyanBFA: #f0fbfa;
$redED5: #ed5e6f;
$redC33: #c33333;
$redFF1: #ff1f47;
$whiteFFF: #ffffff;
$whiteF2F: #f2f2f2;
$green2B4: #2b4f58;
$green7AD: #00a7ad;
$black333: #333333;
$black000: #000000;
$black555: #555555;
$gray666: #666666;
$gray999: #999999;
$grayD8D: #d8d8d8;
$grayC5C: #c5c2c2;
$gray8F8: #8f8e94;
$gray8C8: #8c8c8c;
$gray6D7: #6d7272;
$gray656: #656565;
$gray818: #818181;
$gray556: #556767;
$gray919: #919191;
$gray757: #757575;
$grayC7C: #c7c7c7;
$blue428: #4285f4;
$blue3B5: #3b5998;
$pinkF10: #f1007b;
$pinkFF0: #ff008a;
$pinkF20: #f2007b;
$black343: #343434;

// for Destination page
$cyan03C: #03cccb;
$black131: #131620;
$black0A0: #0a0a0a;
$black563: #4b5563;
$grayE2E: #e2e4e9;
$grayE0E: #e0e0e0;
$redE60: #e60112;
$redFDE: #fde6e8;
$orangeBD7: #bd7100;
$cyan029: #029998;
$purpleAE0: #ae0092;
$blue017: #0170f1;
$cyanE6F: #e6fafa;
$green026: #026666;
$grayF5F: #f5f5f5;
$orangeFD6: #fd632d;
$cyanCDF: #cdf5f5;
$black2D3: #2d334d;

$green00A: #00a7ad;
$green009: #009294;
$black101: #101828;
$black1D2: #1d2939;
$black344: #344054;
$black475: #475467;
$black212: #212124;
$black667: #667085;
$gray98A: #98a2b3;
$grayD0D: #d0d5dd;
$grayF9F: #f9fafb;
$greenDCF: #dcf7f5;
$cyan6DD: #6ddbd9;
$cyan00B: #00b6be;
$whiteF6F: #f6f6f6;
$grayF4E: #f4ebff;

// Dashboard
$grayF2F: #f2f4f7;
$grayEAE: #eaecf0;
$redF04: #f04438;
$green12B: #12b76a;
