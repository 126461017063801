.breadcrumb {
  background-color: unset;
  margin: 0;
  padding: 0;
  .bread-list {
    width: 100%;
    display: flex;
    align-items: center;
    list-style-type: none;
    padding: 0;

    .bread-item {
      font-size: convert-rem(16);
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      color: $black667;
      &:not(&__last):hover {
        color: $green00A;
      }
      &__icon {
        width: 18px;
        color: $gray999;
        margin: 0 8px;
      }
      &__last {
        color: $green009;
        cursor: initial;
      }

      @media screen and (max-width: 767px) {
        font-size: convert-rem(16);
        line-height: 24px;
      }
    }
  }

  &--landing {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 20px 24px;
  }
}

.breadcrumb-w-selection {
  background-color: $grayF9F;
  .breadcrumb {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 12px;

    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 24px;
    .item {
      position: relative;
      display: flex;
      align-items: center;
      column-gap: 8px;
      cursor: pointer;

      padding: 20px 0;
      &-last {
        cursor: pointer;
        .item-name {
          color: $green009;
        }
      }
      &-icon {
        width: 18px;
        height: 18px;

        display: flex;
        align-items: center;
        justify-content: center;
        > svg {
          width: 100%;
          height: 100%;
          color: $gray999;
        }
      }
      &-name {
        color: $black667;
        font-weight: 500;
        &:not(&-last):hover {
          color: $green00A;
        }
      }
      &-list {
        width: 100%;
        min-width: 120px;
        position: absolute;
        top: calc(100% - 8px);
        left: 0;
        z-index: 99;

        border-radius: 8px;
        overflow: hidden;

        background-color: $whiteFFF;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        transition: max-height 0.6s ease-in-out;
        &__closed {
          max-height: 0;
        }
        &__opened {
          max-height: 90vh;
        }
        &-child {
          padding: 8px 14px;
          font-size: convert-rem(14);
          line-height: 20px;
          color: $black667;
          &:hover {
            background-color: $greenDCF;
          }
        }
        &-content {
          max-height: 20vh;
          overflow-y: auto;
          overflow-x: hidden;
          overscroll-behavior: none;
        }
      }
    }

    @media screen and (max-width: 767px) {
      column-gap: 6px;
      .item {
        column-gap: 4px;
        padding: 16px 0;
        &-name {
          font-size: convert-rem(12);
          line-height: 18px;
        }
        &-icon svg {
          width: 14px;
          height: 14px;
        }
        &-list {
          &-child {
            padding: 4px 8px;
            font-size: convert-rem(12);
            line-height: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
}
