// .homepage {
//   position: relative;
//   background-color: $whit$whiteFFF;
//   overflow: hidden;
//   padding-bottom: 32px;
//   .destinations-section {
//     background: #f0fbfa;
//     display: flex; /* Sử dụng flexbox để căn giữa theo chiều ngang và chiều dọc */
//     justify-content: center; /* Căn giữa theo chiều ngang */
//     align-items: center;
//     max-width: 100%;
//     overflow: hidden !important;
//     @media screen and (max-width: 767px) {
//       padding: 0 24px !important;
//     }
//   }
//   .articles-section {
//     width: 100%;
//     max-width: 1280px;
//     margin: 0 auto;
//     overflow: hidden;
//     padding: 0 24px !important;
//   }
//   .destinations-block {
//     padding: 48px 24px;
//     @media screen and (max-width: 767px) {
//       padding: 48px 0px;
//     }
//   }
//   .city-parent{
//     border-radius: 8px;
//   }
//   .articles-block,
//   .destinations-block {
//     max-width: 1280px;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     margin-top: 32px;
//     &__title {
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       column-gap: 16px;
//       .title {
//         color: $black333;
//         font-size: convert-rem(30);
//         font-weight: 700;
//         line-height: 38px;
//       }
//       .view-all {
//         font-size: convert-rem(18);
//         line-height: 28px;
//         font-weight: 700;

//         display: flex;
//         align-items: center;
//         column-gap: 2px;
//         color: $cyan0CC;

//         padding: 8px 0;
//         border-radius: 8px;
//         transition: all 0.3s ease;
//         &:hover {
//           background-color: $cyan0CC;
//           color: $whit$whiteFFF;
//           padding: 8px 12px;
//         }
//         svg {
//           width: 18px;
//           height: 18px;
//         }
//       }

//       @media screen and (max-width: 991px) {
//         .title {
//           font-size: convert-rem(24);
//           line-height: 36px;
//           margin-bottom: 0;
//         }
//         .view-all {
//           font-size: convert-rem(16);
//           line-height: 24px;
//         }
//       }
//     }

//     .swiper-carousel {
//       padding: 24px 24px;
//       .swiper-navigation {
//         width: 40px;
//         height: 40px;
//         box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);
//         svg {
//           color: $black344;
//           width: 18px;
//           height: 18px;
//         }
//         &:hover svg {
//           color: $cyan0CC;
//         }
//         @media screen and (max-width: 991px) {
//           width: 36px;
//           height: 36px;
//         }
//         @media screen and (max-width: 767px) {
//           width: 32px;
//           height: 32px;
//         }
//       }
//       &__prev {
//         left: 4px;
//         @media screen and (max-width: 991px) {
//           left: 6px;
//         }
//         @media screen and (max-width: 767px) {
//           left: 8px;
//         }
//       }
//       &__next {
//         right: 4px;
//         @media screen and (max-width: 991px) {
//           right: 6px;
//         }
//         @media screen and (max-width: 767px) {
//           right: 8px;
//         }
//       }
//     }
//   }
// }

.homepage {
  position: relative;
  background-color: $whiteFFF;
  overflow: hidden;
  padding-bottom: 32px;
  .destinations-section {
    background: #f0fbfa;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: hidden !important;
    @media screen and (max-width: 767px) {
      padding: 0 24px !important;
    }
  }
  .articles-section {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 24px !important;
  }
  .destinations-block {
    padding: 48px 24px;
    @media screen and (max-width: 767px) {
      padding: 48px 0px;
    }
  }
  .city-parent {
    border-radius: 8px;
  }
  .articles-block,
  .destinations-block {
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 16px;
      .title {
        color: $black333;
        font-size: convert-rem(30);
        font-weight: 700;
        line-height: 38px;
      }
      .view-all {
        font-size: convert-rem(18);
        line-height: 28px;
        font-weight: 700;

        display: flex;
        align-items: center;
        column-gap: 2px;
        color: $cyan0CC;

        padding: 8px 0;
        border-radius: 8px;
        transition: all 0.3s ease;
        &:hover {
          background-color: $cyan0CC;
          color: $whiteFFF;
          padding: 8px 12px;
        }
        svg {
          width: 18px;
          height: 18px;
        }
      }

      @media screen and (max-width: 991px) {
        .title {
          font-size: convert-rem(24);
          line-height: 36px;
          margin-bottom: 0;
        }
        .view-all {
          font-size: convert-rem(16);
          line-height: 24px;
        }
      }
    }

    .swiper-carousel {
      padding: 24px 24px;
      .swiper-navigation {
        width: 40px;
        height: 40px;
        box-shadow: 0 2px 11px -3px rgba(0, 0, 0, 0.5);
        svg {
          color: $black344;
          width: 18px;
          height: 18px;
        }
        &:hover svg {
          color: $cyan0CC;
        }
        @media screen and (max-width: 991px) {
          width: 36px;
          height: 36px;
        }
        @media screen and (max-width: 767px) {
          width: 32px;
          height: 32px;
        }
      }
      &__prev {
        left: 4px;
        @media screen and (max-width: 991px) {
          left: 6px;
        }
        @media screen and (max-width: 767px) {
          left: 8px;
        }
      }
      &__next {
        right: 4px;
        @media screen and (max-width: 991px) {
          right: 6px;
        }
        @media screen and (max-width: 767px) {
          right: 8px;
        }
      }
    }
  }
}

.pop-up-login {
  padding: 40px;
  border-radius: 16px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  max-width: 520px;

  &__top {
    padding: 0px !important;
    align-items: center;
  }
  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #101828;
    > span:last-child {
      color: $green00A;
      font-weight: 700;
    }
  }
  &__title {
    color: #101828 !important;
    text-align: center;
    font-size: 30px !important;
    font-weight: 800;
    line-height: 38px;
  }
  &__close-btn {
    padding-top: 0px !important;
    padding-right: 0px !important;
  }
  &__auth-form {
    width: 100%;
  }
  &__mid {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #667085;
    gap: 24px;
    flex-direction: column;
    width: 100%;

    &__action {
      display: flex;
      align-items: flex-start;
      padding-top: 32px !important;
      width: 100%;
      gap: 0 !important;
      @media screen and (max-width: 768px) {
        padding-top: 12px !important;
        margin-top: 12px !important;
      }
    }
    &__item {
      color: #667085;
      display: flex;
      justify-content: center;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
      width: 50%;
      border-bottom: 1px solid $grayD0D;
      padding: 0px 20px 12px 20px !important;
    }
    &__value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;

      > div:first-child {
        color: $black344;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
      &__selection {
        width: 110px;
        flex-shrink: 0;
        > div:first-child {
          > div {
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            > div {
              padding-right: 15px !important;
            }
          }
        }
      }
      &__inputting {
        flex-shrink: 1;
        width: 100%;
        > div:first-child {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          padding: 9px 14px;
        }
      }
      &__group {
        width: 100%;
        display: flex;
        align-items: stretch;
      }
      &__form-item {
        width: 100%;
      }
      &__login-btn {
        border: 1px solid $green00A;
        border-radius: 8px;
        overflow: hidden;
        background-color: $green00A;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 10px 18px;
        text-align: center;
        color: $whiteFFF;
        font-weight: 700;
        line-height: 24px;
        width: 100%;
        margin-top: 20px;
      }
      &__area-code-option {
        width: auto;
        display: flex;
        align-items: center;
        -moz-column-gap: 4px;
        column-gap: 4px;
        font-weight: normal;
      }
      &__option-flag {
        > span {
          position: relative !important;
          width: 22px !important;
          height: 22px !important;
        }
      }
      &__auth-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        -moz-column-gap: 8px;
        column-gap: 8px;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        background-color: $whiteFFF;
        border: 1px solid $grayD0D;

        .icon {
          width: 24px;
          height: 24px;

          > span {
            position: relative !important;
            width: 24px !important;
            height: 24px !important;
          }
        }
      }
      &__auth-or {
        width: 100%;
        text-align: center;
        color: $gray98A;
        position: relative;
        &:before,
        &:after {
          content: "";
          width: calc(50% - 72px);
          height: 0.5px;
          background-color: $grayD0D;
          position: absolute;
          top: 50%;
          transform: translate(0, -50%);
        }
        &:before {
          left: 0px;
          margin-right: 16px;
        }
        &:after {
          right: 0px;
          margin-left: 16px;
        }
      }
      &__auth-social {
        display: flex;
        align-items: center;
        column-gap: 12px;
        width: 100%;

        > span:first-of-type {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 8px;

          border-radius: 8px;
          background-color: $whiteFFF;
          opacity: 1;

          > button {
            padding-left: 18px;
            padding-right: 18px;
          }
        }
      }
    }
  }
  &__bot {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    color: #667085;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    &__span {
      font-weight: 700;
      color: $green00A;
      cursor: pointer;
    }
    &__business {
      cursor: pointer;
      width: 100%;
      display: flex;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid $grayD0D;
      background: $whiteFFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: var(--gray-700, #344054);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      > span {
        position: relative !important;
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 24px;
    width: 100%;
    position: absolute;
  }
  &__auth-check-inbox {
    margin-top: 40px;
    display: flex;
    width: 420px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-shrink: 0;

    &__title {
      color: #101828;
      font-size: 1.875rem;
      font-weight: 800;
      line-height: 38px;
      margin-bottom: 8px;
      font-family: "Nunito Sans", sans-serif;
    }
    &__sub {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1rem;
      line-height: 24px;
      color: #667085;
    }
  }
}
.pop-up-new {
  z-index: 9999;
  &__mobile {
    @media screen and (max-width: 768px) {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0px auto !important;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0px !important;
  }
}

.pop-up-login-phone {
  display: none;

  &__auth-verify {
    &__label {
      color: $black344;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &__field {
      &__invalid input {
        border-color: $redF04;
      }
      > div {
        width: 100%;
        column-gap: 12px;
      }
      input {
        width: 60px !important;
        height: 68px;
        border-radius: 8px;
        border: 1px solid $grayD0D;

        color: $black344;
        text-align: center;
        font-size: convert-rem(30);
        font-weight: 600;
        line-height: 38px;

        padding: 16px 20px;
        outline: none;
        &:focus {
          border-color: $green00A;
          box-shadow: none;
        }
        &::placeholder {
          color: $grayD0D;
          font-weight: normal;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type="number"] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
      }
    }
  }
}
