.destination-properties {
  position: relative;
  font-family: 'Nunito Sans', sans-serif;
  font-size: convert-rem(16);
  line-height: 24px;

  .wrapper {
    max-width: 1280px;
    min-height: 80vh;

    padding: 0 24px 120px;
    margin: 0 auto;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      padding-bottom: 60px;

      .content {
        padding: 48px 0;
        &__head-title {
          font-size: convert-rem(24);
          line-height: 32px;
          margin-bottom: 32px;
        }
        &__list {
          row-gap: 32px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding-bottom: 48px;
      .content {
        padding: 32px 0;
        &__head-title {
          font-size: convert-rem(20);
          line-height: 30px;
        }
      }
    }
  }
  .breadcrumb-wrapper {
    background-color: $grayF9F;
    font-size: convert-rem(18);
    line-height: 28px;

    .breadcrumb {
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      padding: 20px 24px;
    }
  }
  .content {
    padding: 60px 0;
    &__head-title {
      font-size: convert-rem(36);
      font-weight: 700;
      line-height: 44px;
      letter-spacing: -0.72px;
      color: $black101;
      margin-bottom: 40px;
    }
    &__list {
      display: flex;
      flex-direction: column;
      row-gap: 40px;

      .control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 24px;

        &__page-toggle {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          column-gap: 16px;
          .btn-toggle {
            @include primary-white-button();
            &__active {
              @include secondary-green-button();
              &:hover {
                opacity: 1 !important;
                cursor: initial;
              }
            }
          }
        }
        &__filter {
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          column-gap: 24px;

          .filter-title {
            flex-shrink: 0;
            color: $black101;
            font-size: convert-rem(20);
            font-weight: 700;
            line-height: 30px;
          }
          .filter-selection {
            display: flex;
            align-items: center;
            column-gap: 16px;
            .select {
              width: 180px;
            }
          }
        }

        @media screen and (max-width: 992px) {
          &__page-toggle {
            column-gap: 12px;
            .btn-toggle {
              font-size: convert-rem(14);
              line-height: 20px;
              padding: 8px 16px;
            }
          }
          &__filter {
            column-gap: 16px;
            .filter-title {
              font-size: convert-rem(18);
              line-height: 28px;
            }
          }
        }

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          row-gap: 24px;
          &__page-toggle {
            width: 100%;
          }
          &__filter {
            width: 100%;
            column-gap: 16px;
            .filter-title {
              font-size: convert-rem(16);
              line-height: 24px;
            }
            .filter-selection {
              flex-grow: 1;
              column-gap: 12px;
              .select {
                width: 50%;
              }
            }
          }
        }
      }
      .list {
        display: grid;
        gap: 32px;
        &-listings {
          grid-template-columns: repeat(2, minmax(calc(50% - 32px), 1fr));
          gap: 24px;

          @media screen and (min-width: 768px) and (max-width: 1080px) {
            grid-template-columns: repeat(3, minmax(calc(33.33% - 24px), 1fr));
          }

          @media screen and (min-width: 475px) and (max-width: 767px) {
            gap: 24px 16px;
            grid-template-columns: repeat(2, minmax(calc(50% - 24px), 1fr));
          }

          @media screen and (max-width: 474px) {
            grid-template-columns: repeat(1, minmax(calc(100% - 24px), 1fr));
          }
        }

        &-articles {
          grid-template-columns: repeat(4, minmax(calc(25% - 32px), 1fr));
          @media screen and (max-width: 992px) {
            grid-template-columns: repeat(3, minmax(calc(33.33% - 24px), 1fr));
            gap: 32px 24px;
          }

          @media screen and (max-width: 767px) {
            gap: 24px 16px;
            grid-template-columns: repeat(2, minmax(calc(50% - 24px), 1fr));
          }

          @media screen and (max-width: 474px) {
            grid-template-columns: repeat(1, minmax(calc(100% - 24px), 1fr));
          }
        }
      }
    }
  }
}
.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // Chiều cao toàn màn hình
  padding: 20px;

  img {
    max-width: 100%;
    width: 200px;
    height: auto;
    margin-bottom: 20px;
  }

  .empty-list__message {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }

  .empty-list__sub-message {
    font-size: 16px;
    color: #666;
  }
}
@media (max-width: 768px) and (max-height: 1024px) {
  .empty-list {
    height: auto;
    padding: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    img {
      width: 150px;
      margin-bottom: 15px;
    }

    .empty-list__message {
      font-size: 16px;
    }

    .empty-list__sub-message {
      font-size: 14px;
    }
  }
}
.row-your-listing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    color: #333;
  }

  .row-flitter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .search-listing {
      display: flex;
      align-items: center;
      padding-right: 20px;
      .input-group {
        width: 400px;
        display: flex;
        align-items: center;

        .input-group-prepend {
          display: flex;
          align-items: center;
          background-color: #f5f5f5;
          border-right: 1px solid #ccc;
        }

        .input-group-text img {
          width: 20px;
          height: auto;
        }

        .form-control {
          flex-grow: 1;
          border: none;
          outline: none;
          padding: 8px 12px;
          font-size: 16px;
        }

        .form-control::placeholder {
          color: #aaa;
        }
      }
    }

    .menu-select {
      width: 200px;
      height: 50px;
    }
  }
}

@media (max-height: 1024px) {
  .row-your-listing .row-flitter .search-listing .input-group {
    width: 300px;
  }

  .row-your-listing .row-flitter .menu-select {
    width: 150px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .row-your-listing {
    flex-direction: column;
    align-items: stretch;
  }

  .row-flitter {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;

    .search-listing .input-group {
      width: 100%;
    }

    .menu-select {
      width: 100%;
      height: 40px;
    }
  }
}
.font-input-search {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #dbd9d9;
  border-radius: 5px;
  .form-control {
    border: none;
    background-color: transparent;
  }
}
